import { IndicatorNavLink } from "./indicator-nav-link";
import React from "react";
import clsx from "clsx";
import { useMatch } from "react-router-dom";

export type UserPageTabProps<PageAdt> = React.PropsWithChildren<{
  currentPage?: undefined;
  setCurrentPage?: undefined;
  thisPage?: undefined;
  route: string;
  hideIndicator?: boolean;
  style?: React.CSSProperties;
}>;

export function UserPageTab<PageAdt>({
  children,
  thisPage,
  currentPage,
  setCurrentPage,
  route,
  style,
  hideIndicator,
}: UserPageTabProps<PageAdt>): React.JSX.Element {
  const spanClass = "whitespace-nowrap text-ellipsis min-w-0";
  const isActive = useMatch(route);
  return (
    <IndicatorNavLink
      end
      style={style ? () => style : undefined}
      hideIndicator={hideIndicator}
      baseClassName={clsx(
        "text-sm px-4 py-2 rounded-xl transition-all flex items-center justify-center flex-1 text-center",
        isActive ? "bg-[#FF424D] text-white" : "bg-[#696969]/40 text-white"
      )}
      to={route}
    >
      <span className={spanClass}>{children}</span>
    </IndicatorNavLink>
  );
}

export function UserPageTabLabel({
  children,
  className,
}: {
  children: React.ReactNode | string;
  className?: React.HTMLAttributes<HTMLSpanElement>["className"];
}): React.JSX.Element {
  return (
    <span className={clsx("whitespace-nowrap text-ellipsis ml-1 text-sm", className)}>
      {children}
    </span>
  );
}
