import { UserPageTab, UserPageTabLabel } from "../../../widgets/tabs";
import { faCheckCircle, faGrid, faHeart, faLink } from "@fortawesome/pro-solid-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Outlet } from "react-router-dom";
import React from "react";
import { allRoutes } from "../../../util/routes";
import { classNames } from "shared/dist/util";
import { useMyId } from "shared/dist/auth-data";
import { L_News_Feed_Type, useMyVouchesQuery } from "shared/dist/__generated__/components";
import clsx from "clsx";
import { Container } from "../../../widgets/container";

export function DiscoveryRoot(): React.JSX.Element {
  const my_id = useMyId();
  const { data, error } = useMyVouchesQuery({
    variables: {
      my_id: my_id!,
    },
    skip: !my_id,
  });
  if (error) {
    console.log("Error fetching vouches:", error);
  }
  const vouchCount = data?.vouches?.length ?? 0;
  return (
    <div
      className={classNames("h-full flex flex-col justify-stretch items-stretch bg-[#2A2C38] px-6")}
    >
      <div className={clsx("lg:mx-2 flex-0 fit-content mt-1 z-40-nav-main")}>
        <div className={clsx("flex flex-row justify-between gap-4 ")}>
          <UserPageTab hideIndicator route={allRoutes.DISCOVERY.buildPath({})}>
            <UserPageTabLabel>Discovery</UserPageTabLabel>
          </UserPageTab>
          <UserPageTab route={allRoutes.DISCOVERY.MY_INCOMING_LIKES.buildPath({})}>
            <UserPageTabLabel>Requests</UserPageTabLabel>
          </UserPageTab>
          <UserPageTab route={allRoutes.DISCOVERY.MATCHES.buildPath({})}>
            <UserPageTabLabel>Connections</UserPageTabLabel>
          </UserPageTab>
          {/* {vouchCount > 0 && (
            <UserPageTab route={allRoutes.DISCOVERY.VOUCHES.buildPath({})}>
              <UserPageTabLabel>Vouches</UserPageTabLabel>
            </UserPageTab>
          )} */}
        </div>
      </div>

      <div style={{}} className={classNames("flex-1 bg-[#2A2C38] text-white")}>
        <Outlet />
      </div>
    </div>
  );
}
