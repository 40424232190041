import React from "react";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import clsx from "clsx";
import loading1 from "../assets/benefit-party.jpg";
import loading2 from "../assets/discovery_zero_image_2.jpg";
import loading3 from "../assets/benefit-4.jpg";
import loading4 from "../assets/benefit-1.jpg";
import loading5 from "../assets/discovery_zero_image_4.jpg";
import loading6 from "../assets/discovery_zero_image_11.jpg";
import neonLogo from "../assets/neon-logos/simmr-logo-red.png";
import { randomElement } from "shared/dist/util/dev";

export function NeonSpinner() {
  const img = React.useMemo(
    () => randomElement([loading1, loading2, loading3, loading4, loading5, loading6]),
    []
  );
  return (
    <div
      className="flex-col-center-center h-screen w-screen gap-2 bg-cover bg-center bg-no-repeat relative"
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className={clsx("absolute inset-0 bg-primary bg-opacity-80")}></div>
      <div className="flex-col-center-center fixed inset-0 gap-2">
        <img src={neonLogo} className="w-64 max-w-[75vw] " />
        {/* <div className="text-white">
          <Spinner size="3x" />
        </div> */}
        <div></div>
      </div>
    </div>
  );
}
