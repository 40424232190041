import React from "react";
import { classNames } from "shared/dist/util";
import { useSelect } from "downshift";
import clsx from "clsx";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";
import type { UseSelectSelectedItemChange } from "downshift";

type InputProps = React.DetailedHTMLProps<
  React.InputHTMLAttributes<HTMLInputElement>,
  HTMLInputElement
>;

const inputBaseClass = classNames(
  "block",
  "w-full",
  "appearance-none",
  "border",
  "border-gray-300",
  "px-3",
  "py-2",
  "placeholder-gray-400",
  "shadow-sm",
  "focus:border-indigo-500",
  "focus:outline-none",
  "focus:ring-indigo-500",
  "sm:text-sm"
);

export const Input = React.forwardRef(
  ({ ...props }: InputProps, ref): React.JSX.Element => (
    <input
      ref={ref as any}
      className={classNames(inputBaseClass, "rounded-md", props.disabled ? "opacity-50" : null)}
      {...props}
    />
  )
);

type SelectEntry = {
  type: string;
  description: string;
};

type SelectProps = {
  options: Array<SelectEntry>;
  onChange: (v: string) => void;
  labelText: string;
  value: string;
};

export const Select: React.FC<SelectProps> = ({ options, labelText, value, onChange }) => {
  const {
    isOpen,
    selectedItem,
    getToggleButtonProps,
    getLabelProps,
    getMenuProps,
    highlightedIndex,
    getItemProps,
    selectItem,
  } = useSelect({
    items: options,
    itemToString: (it) => (it ? it.type : ""),
    onSelectedItemChange: (v) => onChange(v.selectedItem.type),
  });

  // This ensures that we can control the value from react-hook-form via the value prop
  React.useEffect(() => {
    selectItem(options.find((v) => v.type === value) || selectedItem);
  }, [value]);

  return (
    <div>
      <div className="flex flex-col gap-1">
        <label className="label" {...getLabelProps()}>
          <span className="label-text text-white">{labelText}</span>
        </label>
        <div
          className="text-white p-2 rounded-md flex justify-between cursor-pointer bg-[#464752]"
          {...getToggleButtonProps()}
        >
          <span>{selectedItem ? selectedItem.type : "Select"}</span>
          <span className="px-2">
            <FontAwesomeIcon
              className={clsx("transition-transform", isOpen && "rotate-90")}
              icon={faChevronRight}
            />
          </span>
        </div>
      </div>
      <ul
        className={`text-white flex flex-col gap-1 mt-1 shadow-md max-h-80 overflow-scroll p-0 z-10 ${
          !isOpen && "hidden"
        }`}
        {...getMenuProps()}
      >
        {isOpen &&
          options.map((item, index) => (
            <li
              className={clsx(
                highlightedIndex === index ? "bg-[#383942]" : "bg-[#464752]",
                selectedItem === item && "font-bold",
                "rounded-md py-2 px-3 shadow-sm flex flex-col"
              )}
              key={item.type}
              {...getItemProps({ item, index })}
            >
              <span>
                {item.type} {item.description}
              </span>
            </li>
          ))}
      </ul>
    </div>
  );
};

export const InputLeftAddon = React.forwardRef(
  ({ children, ...props }: InputProps & { children: React.ReactNode }, ref) => (
    <div className="flex max-w-lg rounded-md shadow-sm">
      <span className="inline-flex items-center rounded-l-md border border-r-0 border-gray-300 bg-gray-50 px-3 text-gray-500 sm:text-sm">
        {children}
      </span>
      <input ref={ref as any} {...props} className={classNames(inputBaseClass, "rounded-r-md")} />
    </div>
  )
);
