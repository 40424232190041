import React from "react";
import clsx from "clsx";
import { Text } from "../../components/atoms/Text";
import { useUpdateUserTaglineMutation } from "shared/dist/__generated__/components";
import { classNames } from "shared/dist/util";
import { useMyId } from "shared/dist/auth-data";
import { useDebounce } from "use-debounce";

type ObScreenProps = React.PropsWithChildren<{
  title: string;
  subtitle?: string;
}>;

export function ObScreen({ children, title, subtitle }: ObScreenProps): React.JSX.Element {
  return (
    <div className="flex flex-col h-full">
      <div className="flex-0 font-sf">
        <Text as="h1" size="md" weight="semibold" className="mt-10">
          {title}
        </Text>
        {subtitle && (
          <Text as="p" size="xxs" weight="regular" className={clsx("my-4 w-3/4")}>
            {subtitle}
          </Text>
        )}
      </div>

      {/* Content Section */}
      <div className="flex-1 flex relative">{children}</div>
    </div>
  );
}

export type ObPageProps = {
  setDisableButton: (b: boolean) => void;
};

export type ObPagePropsWithNext = ObPageProps & {
  onNext: () => Promise<unknown>;
};
export function ObTagline({
  onNext,
  setDisableButton,
}: {
  onNext: () => void;
  setDisableButton?: (b: boolean) => void;
}): React.JSX.Element {
  const [tagline, setTagline] = React.useState<string | null>(null);
  const [taglineMutation, taglineResults] = useUpdateUserTaglineMutation();
  const [taglineFormLoading, setTaglineFormLoading] = React.useState(false);
  const id = useMyId()!;
  const [debouncedTagline] = useDebounce(tagline, 1000);

  const handleUpdate = async (value: string) => {
    setTaglineFormLoading(true);
    setDisableButton?.(true);
    await taglineMutation({
      variables: { tagline: value, id },
    });
    setTaglineFormLoading(false);
    setDisableButton?.(false);
  };

  React.useEffect(() => {
    if (!tagline || tagline.length < 3) {
      setDisableButton?.(true);
    } else {
      setDisableButton?.(false);
    }
  }, [tagline, setDisableButton]);

  React.useEffect(() => {
    if (debouncedTagline) {
      handleUpdate(debouncedTagline);
    }
  }, [debouncedTagline]);

  return (
    <ObScreen
      title="What's your tagline?"
      subtitle={`Give people an idea of what you're most looking for...`}
    >
      <div
        className={classNames(
          "flex flex-col gap-0 max-h-full min-h-0 h-full w-full overflow-hidden items-stretch"
        )}
      >
        <input
          type="text"
          className="border-b border-gray-400 bg-transparent pl-1 rounded-none w-full text-white focus:outline-none"
          placeholder='Fun couple excited about making new friends!'
          value={tagline ?? ""}
          onChange={(e) => setTagline(e.target.value)}
        />
        {taglineFormLoading && <div className="text-white">Saving...</div>}
        <div className="flex-1" />
      </div>
    </ObScreen>
  );
}
