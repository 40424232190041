import { CancelButton, SaveButton } from "../spinner-button";
import React, { HTMLAttributes, InputHTMLAttributes } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { L_Bio_Field } from "shared/dist/__generated__/components";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { match } from "ts-pattern";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useDebouncedCallback } from "use-debounce";
import { useMustBeLoggedIn } from "../../util/routes";
import { useMyId } from "shared/dist/auth-data";
import { useValidateFunction } from "shared/dist/util/forms";
import { z } from "zod";

type ValidationOptions =
  | {
      // validationFunction?: undefined;
      validationField?: undefined;
      validationMinLength?: undefined;
    }
  | {
      // validationFunction?: undefined;
      validationField: L_Bio_Field;
      validationMinLength: number;
    };
// | {
//     validationFunction: (
//       opts: Omit<DebouncedValidationOpts, "field" | "minLength">
//     ) => Promise<string | null>;
//     validationField?: undefined;
//     validationMinLength?: undefined;
//   };

export type ValidatedInputProps = {
  autoSave?: boolean;
  bottomLabel?: string;
  defaultValueLoading?: boolean;
  defaultValueTreatAsValid?: boolean;
  defaultValue?: string | null;
  fieldSchema: z.Schema<string>;
  hasBadge?: boolean;
  hideCopyButton?: boolean;
  hideHost?: boolean;
  hideReset?: boolean;
  inputOpts?: InputHTMLAttributes<HTMLInputElement>;
  inputPrefix?: string; // For things like the '@' symbol in URLs
  inputPrefixLg?: string; // For things like the '@' symbol in URLs

  labelOverride?: string;
  onLoading?: (b: boolean) => void;
  onChange?: (s: string) => void;
  onValid?: (s: string) => void;
  placeholder?: string;
  saveComponentOverride?: React.JSX.Element;
  submitHandler: (s: string) => Promise<string | void>;
  suppressToast?: boolean;
  hideSubmitInstructions?: boolean; /// If the field autosaves, hide the "hit enter.." text
} & ValidationOptions;

/**
 *
 * @param inputPrefix for the '@' symbol on the URL field or similar
 * @param inputPrefixLg will override `inputPrefix` on large screens
 * @param submitHandler should return a string on some kind of error. null on success
 * @param defaultValueTreatAsValid run the onValid handler on a valid value. useful when other components need to know the DB Value
 * @returns
 */
export function ValidatedInput({
  autoSave = false,
  bottomLabel,
  defaultValue,
  defaultValueLoading,
  defaultValueTreatAsValid,
  fieldSchema,
  hasBadge,
  // hideHost,
  hideReset,
  hideSubmitInstructions,
  inputOpts,
  inputPrefix,
  inputPrefixLg,
  labelOverride,
  onChange,
  onLoading,
  onValid,
  placeholder,
  saveComponentOverride,
  submitHandler,
  suppressToast,
  validationField,
  // validationFunction,
  validationMinLength,
}: ValidatedInputProps): React.JSX.Element {
  useMustBeLoggedIn();
  const user_id = useMyId();
  const [value, setValue] = React.useState(defaultValue ?? "");
  React.useEffect(() => {
    if (value?.length === 0 && defaultValue?.length && value !== defaultValue)
      setValue(defaultValue);
  }, [defaultValue]);

  const [validationErr, setValidationErr] = React.useState<string | null>(null);
  const [asyncValidationErr, setAsyncValidationErr] = React.useState<string | null>(null);
  const [defaultValueOverride, setDefaultValueOverride] = React.useState(defaultValue);
  const [loading, setLoading] = React.useState(false);
  const [remoteValidationLoading, setRemoteValidationLoading] = React.useState(false);
  const addToast = useAddToast();
  const dbValue = defaultValueOverride?.length ? defaultValueOverride : defaultValue;
  const submit = React.useCallback(
    async (newValue: string) => {
      if (!user_id || validationErr || loading || newValue === dbValue) return;
      try {
        setLoading(true);
        await submitHandler(newValue);
        setDefaultValueOverride(newValue);
        if (!suppressToast) addToast({ content: "Saved!", id: "validated_input_toast" });
      } finally {
        setLoading(false);
      }
    },
    [submitHandler, loading, dbValue, setLoading, validationErr, user_id]
  );
  React.useEffect(() => {
    onLoading?.(remoteValidationLoading || loading);
  }, [loading, onLoading, remoteValidationLoading]);
  React.useEffect(() => {
    onChange?.(value);
  }, [value]);
  const validateImmediately = useValidateFunction();
  const debouncedValidate = useDebouncedCallback(validateImmediately, 500);
  const anyError = validationErr ?? asyncValidationErr;
  const { className: inputClassName, ...inputOptsWithoutClassName } = inputOpts
    ? inputOpts
    : ({} as HTMLAttributes<HTMLInputElement>);
  const anyLoading = loading || remoteValidationLoading || defaultValueLoading;
  const onValueChange = async (newValue: string, isFormSubmission?: boolean) => {
    setValue(newValue);
    onChange?.(newValue);
    if (!validationField) return;
    console.log(
      "🚀 - file: validated-input.tsx:187 - onValueChange - newValue:",
      newValue,
      fieldSchema
    );

    return match(fieldSchema.safeParse(newValue))
      .with({ success: true }, ({ data: parsedString }) => {
        setValidationErr(null);
        setRemoteValidationLoading(true);
        isFormSubmission
          ? validateImmediately({
              value: newValue,
              dbValue,
              onValid() {
                console.log(
                  "🚀 - file: validated-input.tsx:152 - onValid - parsedString:",
                  parsedString
                );
                submit(parsedString);
                onValid?.(parsedString);
              },
              minLength: validationMinLength,
              setErr: (e) => setAsyncValidationErr(e ?? null),
              setRemoteValidationLoading,
              field: validationField,
            })
          : debouncedValidate({
              value: newValue,
              dbValue,
              onValid() {
                if (autoSave) submit(parsedString);
                onValid?.(parsedString);
              },
              minLength: validationMinLength,
              setErr: (e) => setAsyncValidationErr(e ?? null),
              setRemoteValidationLoading,
              field: validationField,
            });
      })
      .with({ success: false }, ({ error }) => {
        console.log("🚀 - file: validated-input.tsx:184 - .with - error:", error);
        setValidationErr(error.errors?.[0].message ?? "Invalid Input");
      })
      .exhaustive();
  };
  const handleSubmit = (e: React.MouseEvent | React.FormEvent) => {
    onValueChange(value, true);
    e?.preventDefault?.();
    e?.stopPropagation?.();
  };

  return (
    <div className="flex w-full">
      <form onSubmit={handleSubmit} className="form-control flex-1">
        {labelOverride && (
          <label className={classNames("capitalize label px-0 ", hasBadge && "indicator")}>
            {hasBadge && (
              <span className="indicator-item top-2 badge rounded-full bg-red-500 badge-sm max-lg:badge-xs" />
            )}
            <span className="label-text">{labelOverride}</span>
          </label>
        )}
        <label className="join input-bordered w-full min-w-0 overflow-hidden">
          <div className="flex flex-row items-center flex-nowrap w-full join-item">
            {inputPrefix && (
              <div
                className={clsx(
                  "flex flex-row items-center",
                  "overflow-hidden text-ellipsis whitespace-nowrap input input-bordered ",
                  "!border-r-transparent rounded-r-none text-candid-gray-600 flex-0"
                )}
              >
                <div className="lg:hidden">{inputPrefix}</div>
                <div className="max-w-[24ch] bg-white max-lg:hidden">
                  {inputPrefixLg ?? inputPrefix}
                </div>
              </div>
            )}
            <input
              placeholder={placeholder}
              type="text"
              value={value}
              onChange={(e) => onValueChange(e.target.value)}
              {...inputOptsWithoutClassName}
              className={clsx(
                inputPrefix && "-ml-4 pl-0 flex-1 !border-l-transparent !rounded-none",
                "focus:outline-none",
                inputClassName
                  ? inputClassName
                  : "input input-bordered w-full focus:outline-none min-w-0 !rounded-r-none"
              )}
            />
          </div>
          {!autoSave && (
            <SaveButton
              className="join-item"
              saveComponentOverride={saveComponentOverride}
              disabled={anyLoading || !!asyncValidationErr || !!validationErr || value === dbValue}
              loading={anyLoading}
              onClick={async (e) => handleSubmit(e)}
            />
          )}
          {!hideReset && (
            <CancelButton
              className="join-item"
              disabled={anyLoading || value === dbValue}
              onClick={() => {
                // dbValue ?
                setValue(dbValue ?? "");
                // : reset();
                setValidationErr(null);
              }}
            />
          )}
        </label>
        {bottomLabel && (
          <label className="lg:hidden label ">
            <span className="label-text-alt">{bottomLabel}</span>
          </label>
        )}
        <label className="label">
          <span className={classNames("label-text-alt text-white", anyError && "text-error")}>
            {anyError ? (
              anyError
            ) : anyLoading ? (
              "Validating..."
            ) : value !== dbValue && !hideSubmitInstructions ? (
              <span>
                Hit enter or click <FontAwesomeIcon icon={faCheckCircle} /> to save.
              </span>
            ) : (
              "\u00A0"
            )}
          </span>
        </label>
      </form>
    </div>
  );
}
