import clsx from "clsx";
import React from "react";
import { createPortal } from "react-dom";

export type BigModalProps = React.PropsWithChildren<{ onDismiss?: () => void; className?: string }>;
export function BigModal({ onDismiss, children, className }: BigModalProps): React.JSX.Element {
  return createPortal(
    <div className="fixed">
      <div
        onClick={onDismiss}
        className={`fixed top-0 bottom-0 left-0 right-0 flex justify-center items-center  bg-[#464752] bg-opacity-50`}
      >
        <div className="p-2 lg:p-8 relative">
          <div className="card shadow-xl">{children}</div>
        </div>
      </div>
    </div>,
    document.body
  );
}
