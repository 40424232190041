import { Text } from "../atoms/Text";

const Chip = ({ label }: { label: String }) => {
  return (
    <div className="py-1 px-3 rounded-full bg-[#E1E1E126] bg-opacity-50 leading-relaxed">
      <Text as="p" size="xxs" weight="regular" className="leading-relaxed">
        {label}
      </Text>
    </div>
  );
};

export default Chip;
