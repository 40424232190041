import { clsx } from "clsx";
import { DaisySize, DaisyVariant } from "../common/types";

export type CustomButtonProps = {
  variant?: DaisyVariant;
  size?: DaisySize;
  disabled?: boolean;
};

export type ButtonProps = React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
> &
  CustomButtonProps;

export const variantClasses: Record<DaisyVariant, string> = {
  primary:
    "bg-gradient-to-r from-red-500 to-orange-400 hover:from-red-600 hover:to-orange-500 text-white",
  outline: "text-white border border-white",
  "outline-square": "text-white border border-white hover:bg-gray-700 hover:text-white",
  standard: "bg-[#FF625F] text-white hover:bg-red-500",
  secondary: "bg-gray-500 text-white hover:bg-gray-600",
  accent: "bg-green-500 text-white hover:bg-green-600",
  ghost: "text-white border border-none hover:bg-gray-700 hover:text-white",
  neutral: "bg-transparent text-white border border-white hover:bg-gray-700 hover:text-white",
  disabled: "bg-gray-300 text-gray-500 cursor-not-allowed",
  unselected: "bg-white text-gray-600 border border-gray-300 hover:bg-gray-200",
  pink: "bg-[#FFC9C9] text-[#FF625F] hover:bg-pink-300",
};

const sizeClasses: Record<DaisySize, string> = {
  xs: "text-xs px-2 py-1",
  sm: "text-sm px-3 py-2",
  md: "text-base px-4 py-3",
  lg: "text-base px-5 py-3",
  xl: "text-base px-6 py-3",
};

const baseButtonClasses =
  "w-full rounded-full font-bold text-center flex items-center justify-center gap-2 transition-all duration-200";

export const Button = ({
  className,
  variant = "primary",
  size = "md",
  disabled = false,
  children,
  ...props
}: ButtonProps) => {
  const isSquare = variant === "outline-square";

  return (
    <button
      className={clsx(
        baseButtonClasses,
        isSquare ? "rounded-md" : "rounded-full",
        disabled ? variantClasses["disabled"] : variantClasses[variant],
        sizeClasses[size],
        className
      )}
      disabled={disabled}
      {...props}
    >
      {children}
    </button>
  );
};
