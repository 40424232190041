import {
  ProfileList,
  ProfileListItem,
  ProfileListProps,
  profileListItemFromSummary,
} from "../../../widgets/profile-list";
import {
  Relationship_Types_Enum,
  useInboundRelationshipRequestsForUserV410Query,
} from "shared/dist/__generated__/components";

import { DateTime } from "luxon";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { SimmerZeroScreen, ZeroScreen } from "../../../widgets/zero-screens";
import { allRoutes } from "../../../util/routes";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useIndicators } from "../../../widgets/indicators";
import { useMyId } from "shared/dist/auth-data";
import { useRelationshipFunctions } from "shared/dist/connections/relationships";
import { useSecureStorage } from "../../../widgets/use-secure-storage";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import Modal from "../../../components/molecules/Modal";
import { Text } from "../../../components/atoms/Text";
import { Button } from "shared-web-react/dist/widgets/Button";

type FriendsRequestListProps = {};

export function InboundFriendsRequestList({}: FriendsRequestListProps): React.JSX.Element {
  const my_id = useMyId() ?? "";
  const addToast = useAddToast();
  const { slug } = useTypedParams(allRoutes.PROFILE.INVITES);
  const [relBtnsLoading, setRelBtnsLoading] = React.useState(false);
  const [initialLastChecked, setInitialLastChecked] = React.useState<string | null>(null);
  const [isLoading, setIsLoading] = React.useState(true);
  const { getSecureValue, setSecureValue } = useSecureStorage("friends_last_checked");
  const { clearInboundRequestsIndicator } = useIndicators();
  const [modalVisible, setModalVisible] = React.useState(false);
  const [modalContent, setModalContent] = React.useState<{
    title: string;
    content: string;
    action?: () => Promise<void>;
  } | null>(null);

  React.useEffect(() => {
    const fetchInitialDate = async () => {
      const value = await getSecureValue();
      setInitialLastChecked(value);
      setIsLoading(false);
      clearInboundRequestsIndicator();
    };
    fetchInitialDate();
  }, [getSecureValue]);

  const { acceptRequest, disconnect } = useRelationshipFunctions({
    onError: (content) => addToast({ content, color: "error" }),
    setLoading: setRelBtnsLoading,
  });
  const { data, loading, refetch } = useInboundRelationshipRequestsForUserV410Query({
    variables: { types: ["friend"], my_id },
  });
  const {
    data: likeData,
    loading: likeLoading,
    refetch: refetchLikes,
  } = useInboundRelationshipRequestsForUserV410Query({
    variables: { types: ["like"], my_id },
  });

  React.useEffect(() => {
    if (!isLoading && !loading && !likeLoading) {
      const newLastChecked = new Date().toISOString();
      setSecureValue(newLastChecked);
    }
  }, [isLoading, loading, likeLoading, setSecureValue]);

  const profiles = React.useMemo(() => {
    const friendProfiles =
      data?.relationships_union.map((r) => {
        const createdAt = r.created_at ? DateTime.fromISO(r.created_at) : null;
        const initialLastCheckedDate = initialLastChecked
          ? DateTime.fromISO(initialLastChecked)
          : null;
        const showNewBadge =
          createdAt && initialLastCheckedDate ? createdAt > initialLastCheckedDate : false;

        const profileItem = profileListItemFromSummary(r.snd_user_summary);
        if (profileItem) {
          return { ...profileItem, showNewBadge };
        }
        return null;
      }) ?? [];

    const likeProfiles =
      likeData?.relationships_union.map((r) => {
        const createdAt = r.created_at ? DateTime.fromISO(r.created_at) : null;
        const initialLastCheckedDate = initialLastChecked
          ? DateTime.fromISO(initialLastChecked)
          : null;
        const showNewBadge =
          createdAt && initialLastCheckedDate ? createdAt > initialLastCheckedDate : false;

        const profileItem = profileListItemFromSummary(r.snd_user_summary);
        if (profileItem) {
          return { ...profileItem, showNewBadge };
        }
        return null;
      }) ?? [];

    return [...friendProfiles, ...likeProfiles];
  }, [data, likeData, my_id]);

  const mkActions: ProfileListProps["mkActions"] = React.useCallback(
    ({ slug, screenName, ...other }: ProfileListItem) => {
      return slug
        ? [
            {
              component: (
                <div
                  className="btn btn-sm text-[#FF625F] border-none bg-[#FFC9C9]"
                  onClick={() => {
                    setModalContent({
                      title: `Do you want to accept this request from ${screenName}?`,
                      content: `Accepting this request will add ${screenName} as a connection.`,
                      action: async () => {
                        const result = await acceptRequest(slug, Relationship_Types_Enum.Friend);
                        if (result) {
                          // addToast({
                          //   content: `Added ${slug} as a Friend!`,
                          //   color: "success",
                          // });
                          await refetch();
                          await refetchLikes();
                        } else {
                          addToast({
                            content: `Unable to complete`,
                            color: "error",
                          });
                          await refetch();
                          await refetchLikes();
                        }
                      },
                    });
                    setModalVisible(true);
                  }}
                >
                  Pending
                </div>
              ),
            },
          ]
        : [];
    },
    [my_id, acceptRequest, addToast, refetch, refetchLikes]
  );

  const hasBadge = React.useCallback((profile: ProfileListItem) => {
    return profile.showNewBadge || false;
  }, []);

  if (loading || likeLoading || relBtnsLoading || isLoading) {
    return <SpinnerCentered />;
  }

  return (
    <div className="InboundFriendsRequestList py-2">
      {profiles.length === 0 ? (
        <SimmerZeroScreen
          text="This is where you'll see incoming friend requests. Start connecting and see who wants to be friends with you!"
          btnText="Back to Connections"
          header="No Results Found"
          clickHandler={() => {}}
        />
      ) : (
        <ProfileList {...{ profiles, mkActions }} hasBadge={hasBadge} className="text-white" />
      )}
      {modalVisible && (
        <Modal isOpen={modalVisible} onDismiss={() => setModalVisible(false)}>
          <div className="flex flex-col gap-2 text-center">
            <Text as="h3" size="sm" weight="semibold">
              {modalContent?.title}
            </Text>
            <Text as="p" size="xxs" className="py-2">
              {modalContent?.content}
            </Text>
            <Button
              className="!bg-[#FF625F]"
              variant="standard"
              onClick={async () => {
                if (modalContent?.action) {
                  await modalContent.action();
                }

                setModalVisible(false);
              }}
            >
              Confirm
            </Button>
            <Button
              onClick={async () => {
                if (slug) {
                  await disconnect(slug, Relationship_Types_Enum.Friend);
                }
                setModalVisible(false);
              }}
              variant="outline"
            >
              Decline
            </Button>
          </div>
        </Modal>
      )}
    </div>
  );
}
