import { P, match } from "ts-pattern";
import { classNames, uniqWith } from "shared/dist/util";

import { Pill } from "../pill";
import React from "react";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import clsx from "clsx";
import { useMustBeLoggedIn } from "../../util/routes";
import { useMyId } from "shared/dist/auth-data";
import { ProfileSettingBubbleChecked } from "../../screens/user-page/settings/dating/dating-main";
import { Text } from "../../components/atoms/Text";

type Key = string;
export type SelectPillsChoice = { value: Key; label?: string | null };
export type SelectPillsChoices = Array<SelectPillsChoice>;

export type SelectPillsProps = {
  bottomLabel?: string;
  choices: Array<SelectPillsChoice>;
  defaultValueLoading?: boolean;
  defaultValueTreatAsValid?: boolean;
  disabled?: boolean;
  selectedChoices?: SelectPillsChoices;
  maxChoices?: number;
  defaultValue?: Array<Key> | null;
  size?: "lg" | "full";
  topLabel?: string | React.ReactNode;
  className?: string;
  isForBio?: boolean;
  onChange?: (
    c: SelectPillsChoices,
    changedChoice: SelectPillsChoice,
    newState: boolean
  ) => Promise<void>;
};

/**
 * This is for when a user can select 1 or more options
 * @param inputPrefix for the '@' symbol on the URL field or similar
 * @param inputPrefixLg will override `inputPrefix` on large screens
 * @param submitHandler should return a string on some kind of error. null on success
 * @param defaultValueTreatAsValid run the onValid handler on a valid value. useful when other components need to know the DB Value
 * @returns
 */
export function SelectPills({
  bottomLabel,
  choices: choicesFromProp,
  className,
  isForBio,
  maxChoices,
  defaultValue,
  size,
  disabled,
  topLabel,
  onChange,
}: SelectPillsProps): React.JSX.Element {
  useMustBeLoggedIn();
  const user_id = useMyId();
  const choices = uniqWith(choicesFromProp, (a, b) => a.value === b.value);
  const [activeChoices, setActiveChoicesInner] = React.useState<Array<Key>>(defaultValue ?? []);
  React.useEffect(() => {
    defaultValue && setActiveChoicesInner(defaultValue);
    // console.log("🚀 ~ file: select-pills.tsx:52 ~ React.useEffect ~ defaultValue:", defaultValue);
  }, [defaultValue]);
  const [loading, setLoading] = React.useState(false);
  const toggleChoice = React.useCallback(
    async (selectedChoice: SelectPillsChoice) => {
      if (disabled) { return } //prettier-ignore
      const newState = !activeChoices?.includes(selectedChoice?.value);
      const newActiveChoices = newState
        ? [selectedChoice.value, ...activeChoices]
        : activeChoices?.filter((c) => c !== selectedChoice?.value);
      // console.log(
      // "🚀 ~ file: select-pills.tsx:53 ~ selectedChoice:",
      // selectedChoice,
      // newState,
      // activeChoices
      // );
      onChange?.(
        choices.filter((c) => newActiveChoices.includes(c.value)),
        selectedChoice,
        newState
      ).finally(() => setLoading(false));
      setActiveChoicesInner(newActiveChoices);
    },
    [setActiveChoicesInner, activeChoices, setLoading, onChange]
  );
  // React.useEffect(() => {
  //   if (!onChange) {
  //     return;
  //   }
  //   setLoading(true);
  //   onChange(activeChoices).finally(() => setLoading(false));
  // }, [activeChoices]);
  return (
    <>
      {topLabel && <div className="flex-0">{topLabel}</div>}
      <Text as="h3" size="xs" weight="semibold" className="pt-2">
        Gender Preferences
      </Text>
      {choices
        // .filter((c) => activeChoices.includes(c.value))
        .map((choice) => (
          // <Pill
          //   value={choice.value}
          //   label={choice.label ?? choice.value}
          //   key={choice.value}
          //   disabled={disabled}
          //   active
          //   onClick={() => {
          //     toggleChoice(choice);
          //   }}
          //   className={isForBio ? "rounded-full" : "rounded-md"}
          // />
          <ProfileSettingBubbleChecked
            key={choice.value}
            text={choice.label ?? choice.value}
            handleClick={() => {
              toggleChoice(choice);
            }}
            prefs={{
              just_show_everyone: activeChoices.includes(choice.value),
            }}
          />
        ))}

      {loading && (
        <div className="fixed top-2 right-2">
          <Spinner />
        </div>
      )}
      <div className="flex-0 -mt-3 text-secondary">
        <span className="italic text-sm">{bottomLabel}</span>
      </div>
    </>
  );
}
