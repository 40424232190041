import React, { JSX } from "react";
import {
  faChain,
  faCircle1,
  faCircle2,
  faCircle3,
  faComments,
  faUserPlus,
  faUsers,
} from "@fortawesome/pro-solid-svg-icons";
import simmrHeart from "../assets/icon-logos/simmr-heart.png";
import { Container } from "./container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import { allRoutes } from "../util/routes";
import discoveryConnectionsZeroScreen from "../assets/zero-screen-connections.png";
import discoveryLikesZeroScreen from "../assets/zero-screen-discovery.png";
import { match } from "ts-pattern";
import myFriendsIcon from "../assets/zero-screens-friend-icons.png";
import myInboundFriendsZeroScreen from "../assets/zero-screens-friends-inbound-request.png";
import myOutboundFriendsZeroScreen from "../assets/zero-screens-friends-outbound-request.png";
import { useMySlug } from "../screens/bio/bio-settings";
import { Button } from "../components/atoms/Button";
import { Text } from "../components/atoms/Text";
import mixpanel from "mixpanel-browser";

export type ZeroScreenProps = React.PropsWithChildren<{
  className?: string;
  style?: React.CSSProperties;
  screenType: ScreenType;
  text?: string | null;
}>;

type ScreenType =
  | "friends-list"
  | "friends-inbound-request"
  | "friends-outbound-request"
  | "discovery-request"
  | "discovery-connections"
  | "notifications";

export function ZeroScreen({
  className,
  screenType,
  children,
}: ZeroScreenProps): React.JSX.Element {
  const { background, copyText } = getScreenDetails(screenType);
  const { slug, loading } = useMySlug() ?? {};
  if (loading) return <Spinner />;
  return (
    <Container
      size="sm"
      className={`flex-1 flex flex-col items-center h-full w-full bg-center bg-cover bg-no-repeat ${className}`}
      style={{
        backgroundImage: background ? `url(${background})` : "none",
      }}
    >
      <div className={`absolute bottom-12 px-10 md:mx-auto`}>
        {copyText && (
          <p className="text-primary-content text-2xl md:text-3xl text-left max-w-md">{copyText}</p>
        )}

        {match(screenType)
          .with("friends-inbound-request", () => (
            <div
              className="btn btn-primary my-5 mx-auto "
              onClick={() => {
                mixpanel.track("Profile Shared");
                if (navigator.share) {
                  navigator.share?.({
                    url: window.location.href,
                    title: `Share my profile on Candid`,
                  });
                } else {
                  navigator.clipboard?.writeText(window.location.href);
                  alert("Copied to Clipboard!");
                }
              }}
            >
              Share Profile
            </div>
          ))
          .with(
            "friends-outbound-request",
            () =>
              slug && (
                <Link
                  className="btn btn-primary my-5 mx-auto "
                  to={allRoutes.PROFILE.INVITES.buildPath({ slug })}
                >
                  Invite Connections
                </Link>
              )
          )
          .with("discovery-request", "discovery-connections", () => (
            <Link className="btn btn-primary my-5 mx-auto" to={allRoutes.DISCOVERY.buildPath({})}>
              Explore Discovery
            </Link>
          ))
          .with("friends-list", () => null)
          .with("notifications", () => null)
          .exhaustive()}
      </div>
      {children}
    </Container>
  );
}

function getScreenDetails(screenType: ScreenType) {
  return match(screenType)
    .with("friends-list", () => ({
      background: null,
      copyText: null,
    }))
    .with("friends-inbound-request", () => ({
      background: myInboundFriendsZeroScreen,
      copyText:
        "This is where you'll see incoming friend requests. Start connecting and see who wants to be friends with you!",
    }))
    .with("friends-outbound-request", () => ({
      background: myOutboundFriendsZeroScreen,
      copyText:
        "This is where your sent friend requests will appear. Once they accept, they'll move to your connections list!",
    }))
    .with("discovery-request", () => ({
      background: discoveryLikesZeroScreen,
      copyText:
        "This is where the list of people who liked you will live. Give it some time... likes are on the way.",
    }))
    .with("discovery-connections", () => ({
      background: discoveryConnectionsZeroScreen,
      copyText:
        "Home of your future connections. Keep sending likes. We'll let you know when you have a new match!",
    }))
    .with("notifications", () => ({
      background: null,
      copyText: null,
    }))
    .exhaustive();
}

export function ZeroScreenActiveFriendsList(): React.JSX.Element {
  return (
    <Container className="py-6 px-2 flex flex-col justify-around h-full">
      <img src={myFriendsIcon} alt="" className="w-full h-auto" />
      <h1 className="text-[3vh] md:text-[4vh] text-white font-bold">
        Welcome to your friends list.
      </h1>
      <p className="text-white text-[2vh] md:text-2xl max-w-md sm:max-w-full ">
        Friends are important on Simmr. They can vouch for you and introduce you to others. Having
        friends also helps you stand out on Discovery.
      </p>
      <hr className="mb-4 border-t-1 border-[secondary] my-3" />
      <h2 className="text-[2vh] text-white font-bold">
        Here’s a few ways to make your first friends.
      </h2>
      <div className="flex justify-center items-center sm:justify-start">
        <FontAwesomeIcon
          style={{ color: "#FF625F", fontSize: "2em" }}
          icon={faCircle1}
          fixedWidth
        />
        <p className="text-white text-[2vh] px-4 max-w-md sm:max-w-full ">
          <span
            className="text-white underline"
            style={{ color: "#FF625F" }}
            onClick={() => {
              if (navigator.share) {
                navigator.share?.({
                  url: window.location.href,
                  title: `Share your Simmr profile`,
                });
              } else {
                navigator.clipboard?.writeText(window.location.href);
                alert("Copied to Clipboard!");
              }
            }}
          >
            Share your profile
          </span>{" "}
          with Simmr members you know.
        </p>
      </div>
      <div className="flex justify-center items-center sm:justify-start">
        <FontAwesomeIcon
          style={{ color: "#FF625F", fontSize: "2em" }}
          icon={faCircle2}
          fixedWidth
        />
        <p className="text-white text-[2vh] px-4 max-w-md sm:max-w-full">
          Look out for open events,{" "}
          <Link
            to={allRoutes.EVENT_FROM_SLUG.buildPath({ slug: "aster-takeover" })}
            className="text-white underline"
            style={{ color: "#FF625F" }}
          >
            like this one
          </Link>
          , and connect with other guests.
        </p>
      </div>
      <div className="flex justify-center items-center sm:justify-start">
        <FontAwesomeIcon
          style={{ color: "#FF625F", fontSize: "2em" }}
          icon={faCircle3}
          fixedWidth
        />
        <p className="text-white text-[2vh] px-4 max-w-md sm:max-w-full ">
          Add the{" "}
          <Link
            to={allRoutes.PROFILE.buildPath({ slug: "simmrteam" })}
            className="text-white underline"
            style={{ color: "#FF625F" }}
          >
            Simmr team
          </Link>{" "}
          as a friend and connect with our friends list.
        </p>
      </div>
    </Container>
  );
}

export function ZeroScreenMutualFriendsList({
  slug,
}: {
  slug: string | undefined;
}): React.JSX.Element {
  return (
    <Container className="px-6 flex flex-col justify-around h-full">
      <span className="flex items-center">
        <FontAwesomeIcon icon={faChain} className="text-white text-3xl md:text-4xl font-bold" />
        <h1 className="ml-1 text-2xl md:text-[4vh] font-bold text-white">
          Your Mutual Connections.
        </h1>
      </span>

      <p className="text-white text-[2vh] md:text-2xl max-w-md sm:max-w-full ">
        Mutual connections bridge networks and open doors to new circles on Candid. Explore who you
        both know and broaden your network.
      </p>
      <hr className="mb-4 border-t-1 border-[secondary] my-3" />
      <h2 className="text-[2vh] text-white font-bold">Here’s the power of mutual connections.</h2>
      <div className="flex flex-col sm:flex-row justify-between">
        <div className="flex items-center sm:items-start">
          <FontAwesomeIcon
            style={{ color: "#FF625F", fontSize: "2em" }}
            icon={faUsers}
            fixedWidth
          />
          <p className="text-white pl-5 text-[2vh] my-2">
            Find shared interests and common ground with new contacts.
          </p>
        </div>
        <div className="flex items-center sm:items-start">
          <FontAwesomeIcon
            style={{ color: "#FF625F", fontSize: "2em" }}
            icon={faUserPlus}
            fixedWidth
          />
          <p className="text-white pl-5 text-[2vh] my-2">
            Forge new connections through the people you trust.
          </p>
        </div>
        <div className="flex items-center sm:items-start">
          <FontAwesomeIcon
            style={{ color: "#FF625F", fontSize: "2em" }}
            icon={faComments}
            fixedWidth
          />
          <p className="text-white pl-5 text-[2vh] my-2">
            Join events and group chats where mutual connections are already interacting.
          </p>
        </div>
        {slug && (
          <div className="mt-4 flex items-center justify-between space-x-2 w-full">
            <p className="text-md md:text-lg font-semibold text-white">
              Have some new connections in mind?
            </p>
            <Link
              to={allRoutes.PROFILE.INVITES.buildPath({ slug })}
              className="btn btn-[#FF625F] my-2 py-2 px-4"
              style={{ color: "#FF625F", textDecoration: "underline" }}
            >
              Invite Connections
            </Link>
          </div>
        )}
      </div>
    </Container>
  );
}

export function SimmerZeroScreen({
  header,
  btnText,
  text,
  clickHandler,
}: {
  header: string | undefined;
  text: string | undefined;
  btnText: string;
  clickHandler: () => void;
}): React.JSX.Element {
  return (
    <Container className="flex flex-col my-10 justify-center items-center">
      <div className="px-2 flex flex-col items-center">
        <Text as="h2" size="sm" weight="medium" className="text-center mb-2">
          {header}
        </Text>
        <img src={simmrHeart} alt="Simmr Heart" className="w-20 h-18 text-center" />
      </div>
      <Text as="p" size="xs" className="text-center my-4">
        {text}
      </Text>
      <Button onClick={clickHandler} variant="standard">
        {btnText}
      </Button>
    </Container>
  );
}
