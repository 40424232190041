import {
  IconDefinition,
  faComments,
  faFire,
  faHome,
  faMessages,
  faPlusCircle,
  faThoughtBubble,
  faUser,
} from "@fortawesome/pro-light-svg-icons";
import { IconName, IconProp } from "@fortawesome/fontawesome-svg-core";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import React, { useContext } from "react";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { allRoutes } from "../../util/routes";
import { classNames } from "shared/dist/util";
import { useBioSummaryAbbreviatedLazyQuery } from "shared/dist/__generated__/components";
import { useMyId } from "shared/dist/auth-data";
import { Button } from "../../components/atoms/Button";

const ShowLargeTrigger = React.createContext<[boolean, (b: boolean) => void]>([false, () => {}]);

export function SignUpTriggerStateProvider({
  children,
}: React.PropsWithChildren<{}>): React.JSX.Element {
  const showLarge = React.useState(false);
  return <ShowLargeTrigger.Provider value={showLarge}>{children}</ShowLargeTrigger.Provider>;
}

type SignUpTriggerProps = {
  //   setBottom: (n: number) => void;
  //   setLeft: (n: number) => void;
  //   hideMenuLabelsInDesktop?: boolean;
};

export function SignUpTriggerLarge(): React.JSX.Element {
  const [, setShowLarge] = React.useContext(ShowLargeTrigger);
  React.useEffect(() => {
    setShowLarge(true);
    return () => setShowLarge(false);
  }, [setShowLarge]);
  return <div></div>;
}

export function SignUpTrigger({}: SignUpTriggerProps): React.JSX.Element {
  const location = useLocation();
  const { slug } = useParams();
  const [showLarge] = useContext(ShowLargeTrigger);
  const [query, { data, loading }] = useBioSummaryAbbreviatedLazyQuery();
  const navigate = useNavigate();
  React.useEffect(() => {
    slug && query({ variables: { slug } });
  }, [slug]);
  const my_id = useMyId();
  if (my_id) {
    return <></>;
  }
  const screen_name = data?.user_summaries?.[0]?.screen_name;
  return (
    <div
      className={classNames(
        "fixed bottom-0 bg-[#2A2C38] bg-opacity-70 backdrop-blur-md max-lg:rounded-t-lg text-success-content flex justify-center right-0 left-0 text-white"
      )}
    >
      <div
        className={classNames(
          "max-w-sm mx-auto flex items-center justify-center px-2",
          showLarge ? "text-center min-h-[50vh] flex-col py-6 gap-4" : "gap-3 py-4 transition-all"
        )}
      >
        {slug && (
          <div className="flex-0">
            <Avatar slug={slug ?? "dummy"} tailwindSize={showLarge ? "32" : "20"} />
          </div>
        )}
        <div className="flex-1 flex flex-col justify-center lg:gap-4">
          <p className={classNames("text-xl font-bold", showLarge ? "lg:text-4xl" : "")}>
            Login or sign up to see more {screen_name ? `from ${screen_name}` : "on Simmr"}!
          </p>
          {showLarge && (
            <p className={classNames(showLarge && "lg:text-xl")}>
              See more photos {screen_name ? ` from ${screen_name} ` : " on Candid "}
              and connect with {screen_name ? ` them ` : " people "} in new ways.
            </p>
          )}
        </div>
        <div className="flex-0 gap-1 flex flex-col w-full mb-10">
          {/* <Link
            to={allRoutes.login.buildPath({})}
            className={classNames("btn btn-primary", showLarge ? "lg:btn-lg w-32" : "btn-sm")}
          >
            Signup
          </Link> */}
          <Button
            variant="primary"
            className={classNames(showLarge ? "lg:btn-lg w-32" : "btn-sm")}
            onClick={() => navigate(allRoutes.login.buildPath({}))}
          >
            Signup
          </Button>
          <Button
            variant="outline"
            className={classNames(showLarge ? "lg:btn-lg w-32" : "btn-sm")}
            onClick={() => navigate(allRoutes.login.buildPath({}))}
          >
            Signup
          </Button>
        </div>
      </div>
    </div>
  );
}
