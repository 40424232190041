import "linkify-plugin-mention";

import * as linkify from "linkifyjs";
import { AddReaction, ReactionPills } from "../../widgets/reactions";
import { Avatar, AvatarFromUrl } from "shared-web-react/dist/widgets/avatar";
import {
  L_Og_Tags_For_Url,
  useDeleteThreadMessageMutation,
  useThreadMessageMedia340Query,
  useThreadOgTagsLazyQuery,
} from "shared/dist/__generated__/components";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { classNames, uniqWith } from "shared/dist/util";

import { DateTime } from "luxon";
import { EllipsisActions } from "../../widgets/ellipsis-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";
import Linkify from "linkify-react";
import { Mention } from "../../widgets/mention";
import React from "react";
import { THREAD_MESSAGES_VIEW_ID } from "./thread-common";
import { VerifiedIdentityIcon } from "../user-page/settings/identity";
import { allRoutes } from "../../util/routes";
import clsx from "clsx";
import { faFire } from "@fortawesome/pro-solid-svg-icons";
import { showNavStackHeaderState } from "shared-web-react/dist/widgets/nav-stack-view";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useEnv } from "shared/dist/util/env";
import { userReportConfirm } from "../user-page/settings/safety";
import { Text } from "../../components/atoms/Text";

type ThreadMessageMediaProps = {
  url: string;
  isFirst: boolean;
  width?: number | null;
  height?: number | null;
  nsfwDescription?: null | string;
};
function ThreadMessageMedia({
  url,
  isFirst,
  nsfwDescription,
}: ThreadMessageMediaProps): React.JSX.Element {
  const [imgLoaded, setImgLoaded] = React.useState(false);
  const [blur, setBlur] = React.useState(!!nsfwDescription);
  const imgRef = React.useRef<HTMLImageElement>(null);
  React.useEffect(() => {
    const elementHeight = imgRef?.current?.offsetHeight ?? 0;
    const messagesView = document.getElementById(THREAD_MESSAGES_VIEW_ID);
    if (!messagesView) { return } //prettier-ignore
    messagesView.scrollTop = messagesView.scrollTop + elementHeight;
  }, [imgLoaded, imgRef]);
  return (
    <>
      {!imgLoaded && (
        <div
          className={clsx(
            imgLoaded ? "hidden" : "flex h-10 w-full justify-center items-center p-1"
          )}
        >
          <Spinner />
        </div>
      )}
      <img
        ref={imgRef}
        src={url}
        onLoad={() => {
          setImgLoaded(true);
        }}
        className={classNames(
          "max-w-full m-0 max-h-[50vh]",
          isFirst ? "rounded-t-2xl" : "rounded-t-none",
          imgLoaded ? "" : "hidden"
        )}
      />
      {blur && imgLoaded && (
        <div
          onClick={(e) => {
            setBlur(false);
            setTimeout(() => setBlur(true), 10000);
            e.stopPropagation();
          }}
          className={clsx(
            isFirst ? "rounded-2xl" : "rounded-t-none",
            "bg-black bg-opacity-25 text-center text-white absolute inset-1 backdrop-blur-lg flex-col-center-center"
          )}
        >
          <div className={clsx("")}>
            <FontAwesomeIcon icon={faFire} fixedWidth size="2x" />
            <p>NSFW</p>
            <p>May contain: {nsfwDescription}</p>
            <p>click to view</p>
          </div>
        </div>
      )}
    </>
  );
}

export function OgTagPreview({
  tags,
  isFirst,
}: {
  isFirst?: boolean;
  tags: L_Og_Tags_For_Url;
}): React.JSX.Element {
  // console.log("🚀 ~ file: thread-message.tsx:22 ~ tags:", tags);
  const description = tags.tags?.filter((t) => t.tag.toLowerCase() === "ogdescription")?.[0]?.value;
  const title = tags.tags?.filter((t) => t.tag.toLowerCase() === "ogtitle")?.[0]?.value;
  const imgUrl = tags.tags?.filter((t) =>
    ["ogimage", "twitterimage"].includes(t.tag.toLowerCase())
  )?.[0]?.value;
  // console.log("🚀 ~ file: thread-message.tsx:26 ~ imgUrl:", imgUrl);
  const host = useEnv("REACT_APP_BASE_URI").toLowerCase();
  const [imgLoaded, setImgLoaded] = React.useState(false);
  const ref = React.useRef<HTMLAnchorElement>(null);
  const imgRef = React.useRef<HTMLImageElement>(null);
  React.useEffect(() => {
    const elementHeight = imgRef?.current?.offsetHeight ?? 0;
    const messagesView = document.getElementById(THREAD_MESSAGES_VIEW_ID);
    if (!messagesView) { return } //prettier-ignore
    messagesView.scrollTop = messagesView.scrollTop + elementHeight;
  }, [location, imgLoaded, imgRef]);
  React.useEffect(() => {
    const elementHeight = ref?.current?.offsetHeight ?? 0;
    const messagesView = document.getElementById(THREAD_MESSAGES_VIEW_ID);
    if (!messagesView) { return } //prettier-ignore
    messagesView.scrollTop = messagesView.scrollTop + elementHeight;
  }, [location, ref]);
  const isCandidBio =
    tags.url.toLowerCase().startsWith(host) ||
    tags.url.toLowerCase().startsWith("https://candid.bio");
  return (
    <a href={tags.url} ref={ref} target="_blank" className="">
      {!isCandidBio && imgUrl && (
        // <div
        //   className="max-h-32 max-w-32 m-0 rounded-t-2xl"
        //   style={{ backgroundImage: `url(${imgUrl})` }}
        // />
        <img
          ref={imgRef}
          src={imgUrl}
          onLoad={() => {
            setImgLoaded(true);
          }}
          className={classNames(
            "w-full m-0",
            isFirst ? "rounded-t-2xl" : "rounded-t-none",
            imgLoaded ? "" : "hidden"
          )}
        />
      )}
      <div
        className={classNames(
          "flex flex-row gap-2 py-1 justify-start items-top ",
          !isCandidBio && imgLoaded ? "px-2" : "px-4 "
        )}
      >
        {isCandidBio && imgUrl && (
          <div className="flex-0 my-2">
            <AvatarFromUrl imgUrl={imgUrl} tailwindSize="14" />
          </div>
        )}
        <div className="flex-1 flex flex-col justify-stretch items-start">
          {title && <p className={classNames("text-sm font-bold mt-2 mb-1")}>{title}</p>}
          {description && (
            <p className={classNames("text-xs  mt-1 line-clamp-2")}>
              {description.slice(0, 200) + "..."}
            </p>
          )}
        </div>
      </div>
      <div
        className={classNames(
          "w-[calc(100%+32px)] bg-candid-gray-300 h-1 -mx-4",
          (title || description) && "mt-2"
        )}
      />
    </a>
  );
}

export type ThreadMessageBubbleProps = {
  id: string;
  isMe: boolean;
  content: string;
  screenName: string;
  blocked?: null | "user_blocked_me" | "blocked_by_me";
  created_at: DateTime;
  slug: string;
  verified: boolean;
  hasMedia: boolean;
  scrollIntoView?: boolean;
  isEvent?: boolean;
};
export function ThreadMessageBubble({
  isMe,
  content,
  id,
  slug,
  screenName,
  verified,
  blocked,
  created_at,
  hasMedia,
  scrollIntoView,
  isEvent,
}: ThreadMessageBubbleProps): React.JSX.Element {
  //   const [fetchedOgTags, setFetchedOgTags] = React.useState<Array<any>>([]);
  const [fetchOgTagsQuery, { data }] = useThreadOgTagsLazyQuery();

  const deleteMessage = useDeleteMessage(id, false);
  const mediaQuery = useThreadMessageMedia340Query({
    variables: { thread_message_id: id },
    skip: !hasMedia,
  });
  const baseUri = useEnv("REACT_APP_BASE_URI").replace(/\/$/, "");
  React.useEffect(() => {
    const embeddedLinks = linkify.find(content, "url");
    const embeddedMentions = linkify.find(content, "mention");
    const urls = uniqWith(
      [
        ...embeddedLinks.map((l) => l.href),
        ...embeddedMentions.map(
          (m) => baseUri + allRoutes.PROFILE.buildPath({ slug: m.value.replace(/^@/, "") })
        ),
      ].filter((u) => u.toLowerCase().startsWith("https")),
      (a, b) => a.toLowerCase() === b.toLowerCase()
    ).slice(0, 3);
    if (urls.length > 0) {
      fetchOgTagsQuery({
        errorPolicy: "ignore",
        variables: { urls },
        fetchPolicy: "cache-first",
      });
    }
  }, [content, fetchOgTagsQuery]);
  // const reportThreadConfirm = userReportConfirm({ showAdditionalUserActions: true });
  const ref = React.useRef<HTMLDivElement>(null);
  React.useEffect(() => {
    if (scrollIntoView && ref.current)
      // Known issue with typescript and scrollIntoView https://github.com/Microsoft/TypeScript/issues/28755
      ref.current.scrollIntoView({ behavior: "instant" } as any);
  }, [scrollIntoView]);
  const sent = React.useMemo(() => {
    const dt = created_at;
    const now = DateTime.now();
    return dt.toLocaleString(now.hasSame(dt, "day") ? DateTime.TIME_SIMPLE : DateTime.DATE_MED);
  }, [created_at]);

  return (
    <>
      <div
        className={`overflow-hidden chat ${isMe ? "chat-end ml-auto" : "chat-start"}`}
        id={id}
        ref={ref}
        style={{ maxWidth: "min(100%, 65ch)" }}
      >
        <Link to={allRoutes.PROFILE.buildPath({ slug })} className="chat-image avatar">
          <Avatar slug={slug} tailwindSize="10" />
          {/* <div className="w-10 rounded-full">
            {imgUrl ? <img src={imgUrl} /> : null}
          </div> */}
        </Link>
        {/* <div
          className={clsx("chat-header flex justify-between items-center w-full", isMe && "hidden")}
        >
          <Link to={allRoutes.PROFILE.buildPath({ slug })} className="space-x-1">
            <ThreadMessageSenderDisplay {...{ screenName, slug, verified }} />
          </Link>
        </div> */}
        {isEvent && (
          <Text as="p" size="xxs" className="text-[#FFFFFF] text-opacity-40 py-2">
            {screenName}
          </Text>
        )}
        <div className="flex flex-col min-w-[60px]">
          {" "}
          {/* Added min-width to prevent collapse */}
          <div
            onClick={() => isMe && deleteMessage()}
            className={classNames(
              "chat-bubble p-0",
              isMe ? "bg-[#55598B] text-white" : "bg-[#D9D9D9] text-gray-900",
              isMe && "cursor-pointer"
            )}
            style={{
              maxWidth: "min(100%, 65ch)",
              wordBreak: "break-word",
              overflowWrap: "break-word",
            }}
          >
            <EllipsisActions
              slug={slug}
              skip={isMe}
              reportData={{ reportType: "message", reportItemId: id }}
            >
              {data?.l_fetch_og_tags?.sites?.map((ogTagGroup, idx) => (
                <OgTagPreview key={idx} isFirst={idx === 0} tags={ogTagGroup} />
              ))}
              {mediaQuery.data?.thread_messages_by_pk?.message_media_uploads?.flatMap?.(
                (mu, idx) =>
                  mu.media_upload_assets?.full_1280_presigned_url?.presigned_url ? (
                    <ThreadMessageMedia
                      key={idx}
                      width={mu.media_upload_assets.width}
                      height={mu.media_upload_assets.height}
                      nsfwDescription={
                        mu.media_upload_assets.nsfw_description
                          ? mu.media_upload_assets.nsfw_description
                          : mu.media_upload_assets.is_nsfw
                            ? "unknown content"
                            : null
                      }
                      isFirst={idx === 0}
                      url={mu.media_upload_assets?.full_1280_presigned_url?.presigned_url}
                    />
                  ) : null
              )}

              <div className="px-4 py-2 relative" onClick={(e) => e.stopPropagation()}>
                {/* Unfortunately, the Linkify types dont include a className which we need here to render
            chat bubbles properly. Hence the ts-ignore */}
                <Linkify
                  // @ts-ignore
                  className="max-w-full break-words whitespace-pre-wrap overflow-hidden"
                  options={{
                    target: "_blank",
                    className: "link font-semibold",
                    render: {
                      mention: ({ content }) => {
                        return <Mention slug={content} />;
                      },
                    },
                  }}
                  as="div"
                >
                  {blocked === "blocked_by_me" ? (
                    <span className={classNames("italic opacity-75")}>
                      content hidden because you blocked this user
                    </span>
                  ) : blocked === "user_blocked_me" ? (
                    <span className={classNames("italic opacity-75")}>
                      content hidden because you are blocked
                    </span>
                  ) : (
                    content
                  )}
                </Linkify>
              </div>
            </EllipsisActions>
          </div>
          <div className="chat-footer w-full flex-row">
            {/* <div
            className={clsx(
              isMe ? "flex-row-reverse" : "flex-row",
              "flex w-full justify-between items-center text-white"
            )}
          >
            <time className="text-xs opacity-50">{sent}</time>
          </div> */}
            <div
              className={classNames(
                "flex justify-start gap-2 items-center text-white",
                isMe ? "flex-row-reverse" : "flex-row"
              )}
            >
              <AddReaction reverse={isMe} objectId={id} />
              <ReactionPills objectId={id} />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

function ThreadMessageSenderDisplay({
  screenName,
  verified,
  slug,
}: Pick<ThreadMessageBubbleProps, "screenName" | "slug" | "verified">): React.JSX.Element {
  return (
    <div className="max-w-[75vw] lg:max-w-[50vw]  flex flex-row justify-start space-x-1 text-white">
      <span className="text-ellipsis whitespace-nowrap overflow-hidden">{screenName}</span>
      {/* <span className="italic flex-0">{"@" + slug}</span> */}
    </div>
  );
}

// https://hips.hearstapps.com/hmg-prod/images/actor-michael-b-jordan-poses-on-may-12-2018-during-a-news-photo-1605707554.

function useDeleteMessage(threadMessageId: string, skip: boolean) {
  const [mutate] = useDeleteThreadMessageMutation();
  const confirm = useConfirm();
  const onClick = React.useCallback(() => {
    confirm({
      title: "Delete Message",
      onOk: async () => {
        if (skip) return;
        await mutate({
          variables: { thread_message_id: threadMessageId },
          update: (cache) => {
            const messageCacheId = cache.identify({
              __typename: "thread_messages",
              id: threadMessageId,
            });
            console.log(
              "🚀 - file: thread-message.tsx:368 - onOk: - messageCacheId:",
              messageCacheId
            );
            cache.evict({ broadcast: true, id: messageCacheId });
          },
        });
      },
    });
  }, [confirm, mutate, threadMessageId]);
  // const slugUserId = getIdQuery?.data?.user_summaries?.[0]?.id;
  // const ref = React.createRef<HTMLDialogElement>();
  // ref?.current?.showModal;
  // const block = useBlockUserById(slug, slugUserId);
  // React.useEffect(
  //   () => (showDialog ? ref?.current?.showModal?.() : ref?.current?.close?.()),
  //   [showDialog]
  // );
  return onClick;
}
