import React from "react";
import DatePicker from "tailwind-datepicker-react";
import { DateTime } from "luxon";
import clsx from "clsx";

type DatePickerProps = {
  onChange: (selectedDate: Date) => void;
  value: string;
  className?: string;
};

const SimmrDatePicker: React.FC<DatePickerProps> = ({ onChange, className, value }) => {
  const [showing, setShowing] = React.useState(false);
  return (
    <div className={clsx("relative", className)}>
      <DatePicker
        options={{
          clearBtn: false,
          theme: {
            background: "dark:bg-[#464752] bg-[#464752]",
            todayBtn: "dark:bg-[#FF625F] bg-[#FF625F]",
            clearBtn: "",
            icons: "",
            text: "",
            disabledText: "",
            input:
              "text-white flex-grow dark:bg-[#464752] bg-[#464752] p-2 rounded-md cursor-pointer focus:outline-none",
            inputIcon: "hidden",
            selected: "dark:bg-[#FF625F]",
          },
        }}
        value={DateTime.fromISO(value).toJSDate()}
        onChange={onChange}
        show={showing}
        setShow={setShowing}
      />
    </div>
  );
};

export default SimmrDatePicker;
