import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import {
  StoredThreadMessage,
  useSetThreadRead,
  useThreadMessagesStore,
} from "./thread-messages-store";
import { ThreadDetailsQuery, useThreadDetails300Query } from "shared/dist/__generated__/components";
import { ThreadMessageBubble, ThreadMessageBubbleProps } from "./thread-single-message-bubble";
import { classNames, filterNulls, sortBy } from "shared/dist/util";

import { DateTime } from "luxon";
import React from "react";
import { THREAD_MESSAGES_VIEW_ID } from "./thread-common";
import { allRoutes } from "../../util/routes";
import clsx from "clsx";
import { useDebounce } from "use-debounce";
import { useIsDev } from "shared/dist/util/env";
import { useMyId } from "shared/dist/auth-data";
import { useOnScreenLogger } from "shared-web-react/dist/util/on-screen-logger";
import { Text } from "../../components/atoms/Text";
import { Avatar, AvatarWithScreenName } from "shared-web-react/dist/widgets/avatar";
import { Link } from "react-router-dom";

type ThreadUserSummary = NonNullable<
  ThreadDetailsQuery["threads_by_pk"]
>["members"][0]["user_public"];
export function ThreadMessages({
  thread_id,
  isEvent,
}: {
  thread_id: string;
  isEvent: boolean;
}): React.JSX.Element {
  const myId = useMyId()!;
  const { data: threadDetailsData, loading: detailsLoading } = useThreadDetails300Query({
    skip: !myId || !thread_id,
    fetchPolicy: "network-only",
    variables: { thread_id, my_id: myId },
  });
  const membersLookup = React.useMemo(() => {
    const filtered = filterNulls([
      ...(threadDetailsData?.threads_by_pk?.members?.map((u) => u.user_public) ?? []),
      ...(threadDetailsData?.threads_by_pk?.member_lookup?.map((u) => u.sender_summary) ?? []),
    ]);
    type MemberSummary = Omit<NonNullable<ThreadUserSummary>, "__typename">;
    const me: MemberSummary = {
      id: threadDetailsData?.me?.id,
      screen_name: threadDetailsData?.me?.screen_name,
      slug: threadDetailsData?.me?.url_slugs?.[0]?.slug ?? "",
      this_user_blocked_by_users: [],
      users_blocked_by_this_user: [],
    };

    const dict: Record<string, ThreadUserSummary> = filtered.reduce(
      (acc, current) => ({ ...acc, [current.id ?? ""]: current }),
      { [myId ?? ""]: me } as Record<string, MemberSummary>
    );
    return dict;
  }, [threadDetailsData]);
  const { data, messageOfInterest, loading, fetchOlder } = useThreadMessagesStore(thread_id);
  const updateViewed = useSetThreadRead(thread_id);
  const otherUsers = React.useMemo(() => {
    const other = Object.values(membersLookup).filter(
      (u): u is ThreadUserSummary => u != null && u.id !== myId
    );
    return other;
  }, [membersLookup, myId]);
  React.useEffect(() => {
    updateViewed();
  }, [updateViewed, data?.thread_messages?.length, messageOfInterest?.id]);
  const topRef = React.useRef<HTMLDivElement>(null); // TODO why does this work but not with useRef which is recommended
  const parentElementRef = React.useRef<HTMLDivElement>(null); // TODO why does this work but not with useRef which is recommended
  const isDev = useIsDev();
  const [isAtTop, setIsAtTop] = React.useState(false);
  React.useEffect(() => {
    if (!topRef.current) return;
    console.log(
      "🚀 - file: thread-view.tsx:114 - React.useEffect - topRef:",
      topRef.current.getBoundingClientRect()
    );
    const observer = new IntersectionObserver(([entry]) => {
      setIsAtTop(entry.isIntersecting);
    });
    observer.observe(topRef.current);
    return () => observer.disconnect();
  }, [topRef, setIsAtTop]);
  const [debouncedIsTop, { isPending }] = useDebounce(isAtTop, 1000);
  const { log } = useOnScreenLogger();
  React.useEffect(() => {
    log({
      id: "tm-debug",
      content: JSON.stringify(
        {
          detailsLoading,
          pending: isPending(),
          msgLen: data?.thread_messages?.length,
          loading,
          moo: messageOfInterest?.id,
          mod: messageOfInterest?.created_at_dt?.toLocaleString(DateTime.DATETIME_MED),
        },
        null,
        "  "
      ),
    });
  }, [messageOfInterest?.id, isPending(), detailsLoading, data?.thread_messages?.length, loading]);
  React.useEffect(() => {
    if (parentElementRef.current?.scrollTop ?? 0 > 0) return;
    console.log(
      "🚀 - file: thread-view.tsx:161 -  debouncedIsTop:",
      debouncedIsTop,
      parentElementRef.current?.scrollTop
    );
    if (debouncedIsTop) {
      fetchOlder();
    }
  }, [debouncedIsTop, parentElementRef?.current?.scrollTop]);
  const messages: Array<Omit<ThreadMessageBubbleProps, "blocked">> = React.useMemo(() => {
    const extractMessageData = (msg: StoredThreadMessage) => ({
      screenName: membersLookup[msg.sender_id]?.screen_name ?? "...",
      verified: membersLookup[msg.sender_id]?.identity_verification_success ?? false,
      slug: membersLookup[msg.sender_id]?.slug ?? "missing",
      id: msg.id,
      created_at: msg.created_at_dt,
      created_at_millis: msg.created_at_dt.toMillis(),
      hasMedia: (msg.message_media_uploads_aggregate?.aggregate?.count ?? 0) > 0,
      isMe: msg.sender_id === myId,
    });
    const sorted = sortBy(
      [
        ...(data?.thread_messages ?? []).map((msg) => ({
          content: msg.content ?? "",
          blocked: msg.sender_summary?.this_user_blocked_by_users.length
            ? "blocked_by_me"
            : msg.sender_summary?.users_blocked_by_this_user?.length
              ? "user_blocked_me"
              : null,
          ...extractMessageData(msg),
        })),
      ],
      (m) => m.created_at_millis
    );
    return sorted;
  }, [data, threadDetailsData]);
  const noThreads = !loading && !data?.thread_messages?.length;
  if (loading && !data?.thread_messages) return <SpinnerCentered />;
  return (
    <div
      id={THREAD_MESSAGES_VIEW_ID}
      ref={parentElementRef}
      className={classNames(
        "inset-0 max-h-full h-full w-full bg-[#2A2C38] flex-1 overflow-y-auto px-6 pb-2"
      )}
    >
      {otherUsers[0]?.slug && (
        <Link
          to={allRoutes.PROFILE.buildPath({ slug: otherUsers[0]?.slug! })}
          className="flex flex-col items-center text-center pt-2 justify-center space-y-2 text-white"
        >
          <Text as="h1" size="xs">
            You connected with {otherUsers[0]?.screen_name}
          </Text>
          <Avatar slug={otherUsers[0]?.slug} />
        </Link>
      )}
      {noThreads && otherUsers[0]?.slug && otherUsers[0]?.screen_name && (
        <Text as="p" size="xxs" className="text-center py-2">
          Start a chat now
        </Text>
      )}
      <div ref={topRef} />
      {messages.reduce((acc, m, idx) => {
        const messageDate = m.created_at.toLocaleString(DateTime.DATE_MED);
        const prevMessageDate =
          idx > 0 ? messages[idx - 1].created_at.toLocaleString(DateTime.DATE_MED) : null;
        if (messageDate !== prevMessageDate) {
          acc.push(
            <div key={`divider-${m.id}`} className="text-center text-gray-500 my-2">
              {messageDate}
            </div>
          );
        }
        acc.push(
          <ThreadMessageBubble
            key={m.id}
            {...m}
            scrollIntoView={m.id === messageOfInterest?.id}
            isEvent={isEvent}
          />
        );
        return acc;
      }, [] as React.ReactNode[])}
      {(detailsLoading || isPending() || loading) && (
        <div className={clsx("absolute top-2 right-2")}>
          <Spinner />
        </div>
      )}
      {isDev && (
        <div className={clsx("opacity-75 absolute bottom-2 right-2 bg-black text-white")}>
          <pre>{}</pre>
        </div>
      )}
    </div>
  );
}
