import {
  L_Bio_Field,
  useGetMyEmailQuery,
  useSetEmailMutation,
} from "shared/dist/__generated__/components";
import { ValidatedInput, ValidatedInputProps } from "../inputs/validated-input";

import React from "react";
import mixpanel from "mixpanel-browser";
import { useMustBeLoggedIn } from "../../util/routes";
import { useMyId } from "shared/dist/auth-data";
import { z } from "zod";

export function useNeedsEmailOrVerification() {
  const id = useMyId()!;
  const { data, loading } = useGetMyEmailQuery({ skip: !id, variables: { id } });
  const showBadge =
    !loading && (!data?.users_by_pk?.email_is_verified || !data?.users_by_pk?.email);
  return showBadge;
}

export type EmailEditorFormProps = {
  showBadgeOnEmpty?: boolean;
  dontLoadFromDb?: boolean;
  className?: string;
  onChange?: (email: string) => void;
} & Pick<
  ValidatedInputProps,
  | "defaultValueTreatAsValid"
  | "hideReset"
  | "hideSubmitInstructions"
  | "labelOverride"
  | "onChange"
  | "onLoading"
  | "onValid"
  | "saveComponentOverride"
  | "suppressToast"
  | "validationField"
>;

export function EmailEditor({
  showBadgeOnEmpty,
  dontLoadFromDb,
  defaultValueTreatAsValid,
  className,
  // onChange,
  ...validatedInputProps
}: EmailEditorFormProps): React.JSX.Element {
  const user_id = useMustBeLoggedIn();
  const [setEmailMutation] = useSetEmailMutation();
  const { data, loading } = useGetMyEmailQuery({
    variables: { id: user_id! },
    skip: dontLoadFromDb || !user_id,
  });
  const submitHandler = React.useCallback(
    async (value: string) => {
      if (!user_id) return;

      try {
        const result = (
          await setEmailMutation({
            variables: {
              id: user_id,
              email: value,
            },
          })
        )?.data?.update_users_by_pk?.email;
        if (!result) {
          return "Unable to save email";
        } else {
        }
        mixpanel.track("User Changed Email");
      } finally {
      }
    },
    [setEmailMutation]
  );
  return (
    <ValidatedInput
      {...{
        inputOpts: {
          className: className && className,
          inputMode: "email",
        },
        autoSave: true,
        hasBadge: showBadgeOnEmpty && !data?.users_by_pk?.email,
        defaultValue: data?.users_by_pk?.email,
        defaultValueLoading: loading,
        defaultValueTreatAsValid,
        submitHandler,
        validationField: L_Bio_Field.Email,
        validationMinLength: 5,
        fieldSchema: z
          .string()
          .min(3, { message: "Screen name must be at least 3 characters." })
          .max(256, { message: "Email must be less than 256 letters" })
          .email({ message: "Please use a proper email address." }),
        ...validatedInputProps,
      }}
    />
  );
}
