import {
  GqlOps,
  useNewMessage300Mutation,
  useNewMessageWithMedia300Mutation,
  useThreadDetailsQuery,
} from "shared/dist/__generated__/components";
import React, { FormEvent } from "react";
import { classNames, filterNullsDefaultWithEmpty } from "shared/dist/util";
import { faSend } from "@fortawesome/pro-solid-svg-icons";
import { faImages } from "@fortawesome/pro-regular-svg-icons";
import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ImageUploader } from "../../widgets/images";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import clsx from "clsx";
import { useMyId } from "shared/dist/auth-data";
import { useStringLimit } from "shared-web-react/dist/util/strings";
import { send } from "vite";
import mixpanel from "mixpanel-browser";

export type ThreadInputProps = {
  threadId: string;
  setLoadingMessage: (msg: null | string) => void;
};

export function ThreadInput({ threadId, setLoadingMessage }: ThreadInputProps): React.JSX.Element {
  const [newMessageMutation, newMessageResults] = useNewMessage300Mutation();
  const [newMediaMessageMutation, newMediaMessageResults] = useNewMessageWithMedia300Mutation();
  const [message, setMessage] = React.useState("");
  const [mediaUploadLoading, setMediaUploadLoading] = React.useState(false);
  const [selectionStart, setSelectionStart] = React.useState(0);
  const [nextSelectionStart, setNextSelectionStart] = React.useState<null | number>(null);
  const ref = React.createRef<HTMLTextAreaElement>();
  const myId = useMyId();
  // For user Lookups
  const { data, loading, refetch } = useThreadDetailsQuery({
    variables: { thread_id: threadId, my_id: myId! },
    fetchPolicy: "cache-first",
  });
  const members: Array<[string, string]> = filterNullsDefaultWithEmpty(
    data?.threads_by_pk?.members.map((m) =>
      m.user_public?.screen_name && m.user_public?.slug
        ? [m.user_public.screen_name, m.user_public.slug]
        : null
    )
  );
  const [candidateMentions, setCandidateMentions] = React.useState<null | typeof members>(null);
  const setMessageWrapper = useStringLimit(2000, setMessage);
  const anyLoading =
    newMessageResults.loading || newMediaMessageResults.loading || mediaUploadLoading;
  const sendMediaMessage = React.useCallback(
    async (mediaUploadIds: Array<string>) => {
      if (anyLoading) {
        return;
      }
      setLoadingMessage(message);
      const results = await newMediaMessageMutation({
        variables: {
          content: message,
          thread_id: threadId,
          media_uploads: mediaUploadIds.map((m) => ({ media_upload_id: m })),
        },
        refetchQueries: [
          GqlOps.Query.ThreadMessages,
          GqlOps.Query.ThreadMessages340,
          GqlOps.Query.UserThreads,
          GqlOps.Query.UserThreads540,
        ],
      });
      setMessage("");
      setLoadingMessage(null);
    },
    [message, setLoadingMessage, setMessage, newMediaMessageMutation]
  );
  const sendMessage = React.useCallback(
    async (event: FormEvent) => {
      event.preventDefault();
      event.stopPropagation();
      if (message.length < 1) {
        return;
      }
      if (anyLoading) {
        return;
      }
      setLoadingMessage(message);

      mixpanel.track("New Message Sent", {
        threadId,
      });
      await newMessageMutation({
        variables: { content: message, thread_id: threadId },
        refetchQueries: [
          GqlOps.Query.ThreadMessages,
          GqlOps.Query.ThreadMessages340,
          GqlOps.Query.UserThreads,
          GqlOps.Query.UserThreads540,
        ],
      });

      setMessage(""); // Clear message
      setLoadingMessage(null);

      // Reset the textarea height
      if (ref.current) {
        ref.current.style.height = "auto"; // Reset height
      }

      ref?.current?.focus?.(); // Focus back on the textarea
    },
    [message, setLoadingMessage, setMessage, newMessageMutation, anyLoading]
  );

  React.useEffect(() => {
    // Check if the current message, up to the selection point, contains a mention and if so, add selected text with the mention's slug
    const start = ref.current?.selectionStart ?? 0;
    const end = ref.current?.selectionEnd ?? 0;
    const messageUpToCursor = message.slice(0, start);
    const lastMessageTokenBeforeCursor = messageUpToCursor.split(/\s/).splice(-1, 1)?.[0];
    const mentionRegex = /@([-a-zA-Z0-9_]*)/;
    const prefix = lastMessageTokenBeforeCursor?.match(mentionRegex)?.[1];
    if (
      start !== end ||
      messageUpToCursor.endsWith(" ") ||
      !lastMessageTokenBeforeCursor ||
      !prefix
    ) {
      setCandidateMentions(null);
      return;
    }
    setCandidateMentions(
      members
        .filter(
          ([name, slug]) =>
            name.toLowerCase().startsWith(prefix) || slug.toLowerCase().startsWith(prefix)
        )
        .slice(0, 3)
    );
    // regex to see messageUpToCursor ends with an @mention
  }, [message, selectionStart]);
  const addMention = React.useCallback(
    (slug: string) => {
      const start = ref.current?.selectionStart ?? 0;
      const end = ref.current?.selectionEnd ?? 0;
      console.log("🚀 ~ file: thread-view.tsx:123 ~ slug:", slug);
      const messageUpToCursor = message.slice(0, start);
      const lastMessageTokenBeforeCursor = messageUpToCursor.split(/\s/).splice(-1, 1)?.[0];
      const mentionRegex = /@([-a-zA-Z0-9_]*)/;
      const prefix = lastMessageTokenBeforeCursor?.match(mentionRegex)?.[1];
      console.log("🚀 ~ file: thread-view.tsx:129 ~ prefix:", prefix);
      if (
        start !== end ||
        messageUpToCursor.endsWith(" ") ||
        !lastMessageTokenBeforeCursor ||
        !prefix
      ) {
        return;
      }
      const newMessage =
        message.slice(0, start - prefix.length) + slug + " " + message.slice(start);
      const newSelectionLoc = start - prefix.length + slug.length + 1;
      console.log("🚀 ~ file: thread-view.tsx:153 ~ newSelectionLoc:", newSelectionLoc);
      setMessage(newMessage);
      console.log("🚀 ~ file: thread-view.tsx:149 ~ newMessage:", newMessage);
      setCandidateMentions(null);
      ref.current?.focus();
      setNextSelectionStart(newSelectionLoc);
    },
    [ref, message, setMessage]
  );
  React.useEffect(() => {
    if (ref.current && nextSelectionStart !== null) {
      ref.current.selectionStart = nextSelectionStart;
      ref.current.selectionEnd = nextSelectionStart;
      setNextSelectionStart(null);
    }
  }, [ref, setNextSelectionStart, nextSelectionStart, message]);
  const disabled = newMessageResults.loading || message.length < 1;
  return (
    <div
      className={clsx("flex flex-row bg-[#2A2C38] items-center gap-2 justify-stretch px-6 w-full")}
    >
      <form
        className={classNames(
          "w-full space-x-2 flex-1 flex items-start justify-start",
          candidateMentions && "dropdown dropdown-top dropdown-open"
        )}
        action="#"
        onSubmit={sendMessage}
      >
        <div className="flex-1 bg-[#464752] rounded-lg px-4 flex items-center gap-2">
          {/* Text Area */}
          <textarea
            ref={ref}
            className={classNames(
              "w-full resize-none overflow-hidden outline-none text-white bg-transparent",
              "placeholder-gray-400"
            )}
            rows={1}
            placeholder="Write a message..."
            onInput={(e) => {
              const textarea = e.target as HTMLTextAreaElement;
              textarea.style.height = "auto"; // Reset height
              textarea.style.height = `${textarea.scrollHeight}px`; // Adjust height dynamically
            }}
            onSelect={() => setSelectionStart(ref?.current?.selectionStart ?? 0)}
            onChange={(e) => setMessageWrapper(e.target.value)}
            value={message}
          />

          <ImageUploader
            iconOverride={faImages} // Ensure correct FontAwesome icon is passed
            btnContent={""}
            btnClassName={classNames(
              "relative flex items-center border-none justify-center w-8 h-8 rounded-full bg-transparent hover:bg-gray-700 text-gray-300 transition-all"
            )}
            iconClassName="text-lg text-gray-300 hover:text-white" // Adjust icon size and hover color
            onError={() => {
              console.error("Error in uploading");
            }}
            onComplete={(mediaUploadId) => {
              mixpanel.track("Message media uploaded", {
                threadId,
              });
              console.log("Media upload complete", mediaUploadId);
              sendMediaMessage([mediaUploadId]);
              setMediaUploadLoading(false);
            }}
            onStart={() => {
              console.log("Media upload started");
              setMediaUploadLoading(true);
            }}
            mediaOptions={{
              exclude_from_bio: true,
              exclude_from_feed: true,
            }}
            showPreviewConfirmation
          />

          {/* Send Button */}
          {/* <SpinnerButton
            disabled={newMessageResults.loading || message.length < 1}
            loading={newMessageResults.loading}
            onClick={sendMessage}
            className={classNames(
              "flex-0 max-w-2 max-h-2 py-3",
              newMessageResults.loading || (message.length < 1 && "bg-[#464752]")
            )}
            variant={"outline"}
          >
          </SpinnerButton>
           */}
          <button
            className={`
              relative flex items-center justify-center
              w-12 h-12 rounded-full
              transition-all duration-200
              
              text-white
            `}
            disabled={disabled || loading}
            onClick={sendMessage}
          >
            {/* Inner circle container */}
            <div
              className={`
                  flex items-center justify-center
                  w-8 h-8 rounded-full
                  ${disabled ? "bg-[#61606C]" : "bg-transparent border-[#FF625F] border"}
                `}
            >
              {/* Spinner */}
              {loading && (
                <div className="absolute inset-0 flex items-center justify-center">
                  <div className="w-4 h-4 border-2 border-white border-t-transparent rounded-full animate-spin" />
                </div>
              )}

              {/* Icon */}
              {!loading && (
                <FontAwesomeIcon
                  icon={faSend}
                  fixedWidth
                  className={`w-4 h-4 ${disabled ? "text-[#464752]" : "text-[#FF625F]"}`}
                />
              )}
            </div>
          </button>
        </div>

        {/* Mentions Dropdown */}
        {candidateMentions && candidateMentions.length > 0 && (
          <ul className="dropdown-content bg-primary-content menu rounded-box my-2 cursor-pointer px-4 py-2">
            {candidateMentions.map(([name, slug]) => (
              <li
                key={slug}
                onClick={() => addMention(slug)}
                className="flex flex-row flex-nowrap items-center"
              >
                <Avatar
                  slug={slug}
                  className="inline-block mr-1 my-2 mask mask-circle"
                  tailwindSize="6"
                />
                {name} / @{slug}
              </li>
            ))}
          </ul>
        )}
      </form>

      {/* <SpinnerButton
        disabled={newMessageResults.loading || message.length < 1}
        loading={newMessageResults.loading}
        onClick={sendMessage}
        className={classNames("btn btn-sm btn-primary rounded-full btn-circle  flex-0")}
      >
        <FontAwesomeIcon icon={faSend} fixedWidth />
      </SpinnerButton> */}
    </div>
  );
}
