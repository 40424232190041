import { useAuthIsLoading, useMyId } from "shared/dist/auth-data";

import { Link, useNavigate } from "react-router-dom";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import { allRoutes } from "../../util/routes";
import logoPurple from "../../assets/neon-logos/candid-neon-purple.png";
import logoSimmrRed from "../../assets/simmr-logo-red.png";
import { Button } from "../../components/atoms/Button";
export function TermsNavButtons(): React.JSX.Element {
  const id = useMyId();
  const loading = useAuthIsLoading();
  const navigate = useNavigate();
  return (
    <>
      <div className="absolute flex items-center justify-between flex-row top-0 left-0 right-0 p-4 space-x-2">
        <div className="flex-1 ">
          <img src={logoSimmrRed} className="w-32 ml-4" />
        </div>

        <div className="flex flex-row items-end">
          {loading ? (
            <div className="btn btn-accent capitalize">
              <div className={"opacity-0"}>Logout</div>
              <div className={`absolute mx-auto`}>
                <Spinner />
              </div>
            </div>
          ) : (
            <Button
              size="sm"
              onClick={() => {
                navigate(id ? allRoutes.me.buildPath({}) : allRoutes.ROOT.buildPath({}));
              }}
            >
              Home{" "}
            </Button>
          )}
        </div>
      </div>
    </>
  );
}
