import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import React from "react";
import { ThreadInput } from "./thread-input";
import { ThreadMessages } from "./thread-messages";
import { allRoutes } from "../../util/routes";
import { classNames } from "shared/dist/util";
import { useMyId } from "shared/dist/auth-data";
import { useSetPageTitle } from "shared-web-react/dist/widgets/nav-stack-view";
import { useSetThreadRead } from "./thread-messages-store";
import { useThreadDetails300Query } from "shared/dist/__generated__/components";
import { useTypedParams } from "react-router-typesafe-routes/dom";

export function ThreadView(): React.JSX.Element {
  const myId = useMyId();
  const { thread_id } = useTypedParams(allRoutes.MESSAGING.LIST.THREAD);
  if (!thread_id) throw new Error("Unexpected null thread id.");
  const { data } = useThreadDetails300Query({
    skip: !thread_id || !myId,
    variables: { thread_id, my_id: myId! },
  });
  const updateViewed = useSetThreadRead(thread_id);
  React.useEffect(() => {
    updateViewed();
  }, [updateViewed]);
  const title =
    data?.threads_by_pk?.event_public_summary?.name ??
    data?.threads_by_pk?.members?.map((m) => m.user_public?.screen_name).join(", ") ??
    "";
  const isEvent = !!data?.threads_by_pk?.event_public_summary;
  useSetPageTitle(title);
  return (
    <div
      className={classNames(`inset-0`, `h-full flex flex-col justify-stretch items-stretch`)}
      style={{
        paddingBottom: "calc(env(safe-area-inset-bottom) + 75px)",
      }}
    >
      {thread_id ? (
        <>
          <ThreadMessages key={thread_id} thread_id={thread_id!} isEvent={isEvent} />
          <ThreadInput threadId={thread_id} setLoadingMessage={() => {}} />
        </>
      ) : (
        <ErrorComp caption="Missing thread id" />
      )}
    </div>
  );
}
