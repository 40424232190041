import { ObBirthdayPicker } from "../../widgets/pickers/birthday-picker";
import { ObGenderPicker } from "shared-web-react/dist/widgets/gender-picker";
import { ObOrientationPicker } from "../../widgets/bio/orientation-picker";
import {
  Relationship_Statuses_Enum,
  Relationship_Types_Enum,
  useDeleteRelationShipPartner554Mutation,
  useGetUserTypeQuery,
  useInsertRelationshipPartner554Mutation,
  useUpdateRelationshipPartner554Mutation,
  useUpdateUserTypeMutation,
  useUserGhostRelationShips554Query,
} from "shared/dist/__generated__/components";
import { ObScreen } from "./widgets";
import React, { useEffect } from "react";
import { classNames } from "shared/dist/util";
import { useMyId } from "shared/dist/auth-data";
import { Text } from "../../components/atoms/Text";
import { Button } from "../../components/atoms/Button";
import { ObScreenProps } from "../../widgets/bio/bio-types";
import { ScreenNameEditor } from "../../widgets/pickers/screen-name-editor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-solid-svg-icons";
import { match } from "ts-pattern";
import { useDebounce } from "use-debounce";
import { set } from "ts-pattern/dist/patterns";

export function ObBirthday({
  setDisableButton,
}: {
  setDisableButton: (b: boolean) => void;
}): React.JSX.Element {
  const [_, setFakeLoading] = React.useState(true);
  React.useEffect(() => {
    const tm = setTimeout(() => {
      setFakeLoading(false);
    }, 2500);
    return () => tm && clearTimeout(tm);
  });
  return (
    <ObScreen title="When were you born?">
      <div className={classNames("flex flex-col h-full w-full gap-2 pb-4 items-stretch")}>
        <ObBirthdayPicker setDisableButton={setDisableButton} />
      </div>
    </ObScreen>
  );
}

export function ObGender({
  setDisableButton,
}: {
  setLoading: (state: boolean) => void;
  setDisableButton: (state: boolean) => void;
}): React.JSX.Element {
  const myId = useMyId();

  return (
    <ObScreen title="What's your gender?">
      <div className="flex flex-col min-h-0 w-full">
        <div className="flex-grow overflow-y-auto">
          {" "}
          <ObGenderPicker
            forUserId={myId}
            defaultGenderId="gender_man"
            // buttonClassName="!pl-2"
            // suppressToast
            setDisabled={setDisableButton}
          />
        </div>
      </div>
    </ObScreen>
  );
}

export function ObOrientation({
  setDisableButton,
}: {
  setDisableButton: (b: boolean) => void;
}): React.JSX.Element {
  return (
    <ObScreen title="What's your sexual orientation?">
      <div className="flex flex-col min-h-0 w-full">
        <div className="flex-grow overflow-y-auto">
          <ObOrientationPicker setDisableButton={setDisableButton} />
        </div>
      </div>
    </ObScreen>
  );
}

export function ObAccountType({
  setDisableButton,
}: {
  setDisableButton: (state: boolean) => void;
}): React.JSX.Element {
  const myId = useMyId();
  const [mutation] = useUpdateUserTypeMutation();
  const { data } = useGetUserTypeQuery({
    variables: { id: myId! },
  });

  const [deletePartnerName] = useDeleteRelationShipPartner554Mutation();
  const [relationshipId, setRelationshipId] = React.useState<string | null>(null);
  const accountType = data?.users_by_pk?.account_type; // This comes from the query
  const [description, setDescription] = React.useState<string | null>(
    "Create a couple's account with one sign-in, or sign up individually and link profiles to appear together as a couple."
  );
  const [selectedAccountType, setSelectedAccountType] = React.useState<string | null>(
    accountType ?? null
  );

  const accountTypes = [
    {
      type: "Individual",
      description: "Create an individual account.",
    },
    {
      type: "Joint",
      description:
        "Create a couple's account with one sign-in, or sign up individually and link profiles to appear together as a couple.",
    },
  ];

  // Update description and selectedAccountType when accountType changes
  React.useEffect(() => {
    if (accountType) {
      const selectedType = accountTypes.find((type) => type.type === accountType);
      if (selectedType) {
        setDescription(selectedType.description);
        setSelectedAccountType(selectedType.type);
      }
    }
  }, [accountType]);

  const handleAccountTypeClick = async ({
    type: optionType,
    description,
  }: {
    type: string;
    description: string;
  }) => {
    setDisableButton(optionType === "Joint"); // Initially disable the button for Joint account type
    await mutation({
      variables: {
        id: myId!,
        account_type: optionType,
      },
    });

    // Update the local state after mutation
    setDescription(description);
    setSelectedAccountType(optionType);

    // Use optionType directly to handle button state
    if (optionType === "Individual") {
      relationshipId &&
        (await deletePartnerName({
          variables: {
            id: relationshipId!,
          },
        }));
      setDisableButton(false);
    } else {
      setDisableButton(true);
    }
  };

  return (
    <ObScreen title="What kind of account would you like?">
      <div className="flex flex-col min-h-0 w-full">
        <div className="flex-grow overflow-y-auto">
          {match(selectedAccountType)
            .with("Individual", () => (
              <div className="flex flex-col items-center my-2 max-h-80">
                {accountTypes.map((option) => (
                  <Button
                    key={option.type}
                    variant={selectedAccountType === option.type ? "primary" : "unselected"}
                    onClick={() => handleAccountTypeClick(option)}
                    className="mb-3 w-full font-bold py-2 px-4 rounded-full"
                  >
                    {option.type}
                  </Button>
                ))}
              </div>
            ))
            .with("Joint", () => (
              <>
                <div className="flex flex-col items-center my-2 max-h-80">
                  {accountTypes.map((option) => (
                    <Button
                      key={option.type}
                      variant={selectedAccountType === option.type ? "primary" : "unselected"}
                      onClick={() => handleAccountTypeClick(option)}
                      className="mb-3 w-full font-bold py-2 px-4 rounded-full"
                    >
                      {option.type}
                    </Button>
                  ))}
                </div>
                <ObPartnerInfo
                  setLoading={setDisableButton}
                  setDisableButton={setDisableButton}
                  setRelationshipId={setRelationshipId}
                />
              </>
            ))
            .otherwise(() => (
              <div className="flex flex-col items-center my-2 max-h-80">
                {accountTypes.map((option) => (
                  <Button
                    key={option.type}
                    variant={selectedAccountType === option.type ? "primary" : "unselected"}
                    onClick={() => handleAccountTypeClick(option)}
                    className="mb-3 w-full font-bold py-2 px-4 rounded-full"
                  >
                    {option.type}
                  </Button>
                ))}
              </div>
            ))}
          <Text as="p" size="xxs" weight="regular" className="text-white mt-4">
            {description}
          </Text>
        </div>
      </div>
    </ObScreen>
  );
}

export function ObPartnerInfo({
  setLoading,
  setDisableButton,
  setRelationshipId,
}: {
  setLoading: (state: boolean) => void;
  setDisableButton: (state: boolean) => void;
  setRelationshipId: (id: string) => void;
}): React.JSX.Element {
  const [name, setNameInner] = React.useState("");
  const [error, setError] = React.useState<string | null>(null);
  const { data, loading } = useUserGhostRelationShips554Query({
    variables: {
      user_id: useMyId()!,
    },
    fetchPolicy: "network-only",
  });
  const [debouncedName] = useDebounce(name, 500);
  const my_id = useMyId()!;

  // Just use one mutation
  const [updatePartnerName, { loading: mutationLoading }] =
    useUpdateRelationshipPartner554Mutation();
  const [insertPartnerName] = useInsertRelationshipPartner554Mutation();
  React.useEffect(() => {
    if (data && data.relationships && data.relationships.length > 0) {
      setRelationshipId(data.relationships[0]?.id || "");
      setNameInner(data.relationships[0]?.partner_name || "");
    }
  }, [data]);

  const validateAndSetUrl = React.useCallback(
    async (newScreenName: string) => {
      // Don't run validation if name is empty or too short
      if (!newScreenName || newScreenName.length < 3) {
        setError("Name must be at least 3 characters long");
        setDisableButton?.(true);
        return;
      }

      setError(null);
      setDisableButton?.(true);

      if (!my_id) {
        setError("User ID is not available");
        return;
      }

      try {
        const existingRelationship = data?.relationships[0]?.id;

        if (existingRelationship) {
          await updatePartnerName({
            variables: {
              id: existingRelationship,
              partner_name: newScreenName,
            },
            refetchQueries: ["UserGhostRelationShips554"],
          });
        } else {
          await insertPartnerName({
            variables: {
              relationship: {
                from_user_id: my_id,
                partner_name: newScreenName,
                status: Relationship_Statuses_Enum.Accepted,
                type: Relationship_Types_Enum.Ghost,
              },
            },
            refetchQueries: ["UserGhostRelationShips554"],
          });
        }
      } catch (error) {
        console.error("Operation failed:", error);
        setError("Failed to update partner name. Please try again.");
      } finally {
        setDisableButton?.(false);
        setLoading?.(false);
      }
    },
    [updatePartnerName, my_id, data, setDisableButton, setLoading]
  );

  React.useEffect(() => {
    validateAndSetUrl(debouncedName);
  }, [debouncedName, validateAndSetUrl]);

  React.useEffect(() => {
    if (loading || mutationLoading) {
      setDisableButton?.(true);
    } else {
      setDisableButton?.(false);
    }
  }, [loading, mutationLoading, setDisableButton]);

  return (
    <>
      <Text as="p" size="xs" weight="regular" className="text-white my-4">
        {name ? `Your partner's name is ${name}` : "What's your partner's name?"}
      </Text>
      {error && (
        <Text as="p" size="xs" weight="regular" className="text-red-500 mb-2">
          {error}
        </Text>
      )}
      <input
        type="text"
        value={name}
        onChange={(e) => setNameInner(e.target.value)}
        className="border-b border-gray-400 bg-transparent pl-1 rounded-none w-full text-white focus:outline-none"
        placeholder="DreamyDancer"
      />
    </>
  );
}
