import {
  GqlOps,
  Notification_Settings_Keys_453Fragment,
  useMyNotificationSettings453Query,
  useSetupDefaultNotificationsMutation,
  useUpdateMyNotificationSettings453Mutation,
} from "shared/dist/__generated__/components";

import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { H5 } from "shared-web-react/dist/widgets/text";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { SpinnerCheckbox } from "shared-web-react/dist/widgets/spinner-checkbox";
import { useMyId } from "shared/dist/auth-data";
import { Text } from "../../../components/atoms/Text";
import { Switch } from "@headlessui/react";
import clsx from "clsx";

type SingleNotificationSettingProps = {
  label: string;
  settings: Notification_Settings_Keys_453Fragment | undefined | null;
  settingKey: keyof Notification_Settings_Keys_453Fragment;
};

export function SingleNotificationSetting({
  label,
  settings,
  settingKey,
}: SingleNotificationSettingProps): React.JSX.Element {
  const my_id = useMyId()!;
  const [mutate, mutationResult] = useUpdateMyNotificationSettings453Mutation();

  // Default to `false` if settings or the specific key is `null` or `undefined`
  const value = settings?.[settingKey] ?? false;

  const toggleValue = React.useCallback(
    async (newValue: boolean) => {
      console.log("🚀 ~ file: notifications.tsx:52 ~ newValue:", settingKey, newValue);
      await mutate({
        variables: { my_id, updates: { [settingKey]: newValue } },
        refetchQueries: [GqlOps.Query.MyNotificationSettings453],
      });
    },
    [mutate, settingKey, my_id]
  );

  const settingsMap = mutationResult.data?.update_user_notification_settings_by_pk;
  const mergedValue = !!(settingsMap?.[settingKey] ?? value);
  console.log(mutationResult);
  return (
    <div className="flex justify-between items-center py-2 my-1 bg-[#464752] rounded-lg px-4">
      {/* Label */}
      <Text as="p" size="xxs" className="text-white font-medium">
        {label}
      </Text>

      {/* Toggle Switch */}
      <Switch
        checked={mergedValue}
        onChange={toggleValue}
        className={clsx(
          "relative inline-flex h-6 w-11 items-center rounded-full transition-colors duration-200",
          mergedValue ? "bg-[#FF625F]" : "bg-gray-600"
        )}
      >
        <span
          className={clsx(
            "inline-block h-4 w-4 transform rounded-full bg-white transition duration-200",
            mergedValue ? "translate-x-6" : "translate-x-1"
          )}
        />
      </Switch>
    </div>
  );
}

export function NotificationSettings(): React.JSX.Element {
  const my_id = useMyId();
  if (!my_id) {
    throw new Error("missing id in notifications settings");
  }
  const [setupDefaultMutations] = useSetupDefaultNotificationsMutation();
  React.useEffect(() => {
    my_id &&
      setupDefaultMutations({
        variables: { my_id },
        refetchQueries: [GqlOps.Query.MyNotificationSettings],
      });
  }, [my_id]);
  const { data, loading, error } = useMyNotificationSettings453Query({
    variables: { my_id },
    fetchPolicy: "cache-and-network",
  });
  console.log("🚀 ~ file: notifications.tsx:55 ~ NotificationSettings ~ data:", data);
  if (error) {
    return <ErrorComp caption={error.clientErrors.toString()} />;
  }
  if (loading) {
    return <SpinnerCentered />;
  }

  const settings = data?.user_notification_settings_by_pk;
  // const { __typename, user_id, ...settings } = settingsOuter ?? {};

  return (
    <div className="flex flex-row text-white">
      <div className="flex flex-1 flex-col justify-start gap-2">
        <H5 className="pb-2">Push Notifications</H5>
        <SingleNotificationSetting
          label="Push Notification on Status Reactions"
          settings={settings}
          settingKey={"push_on_status_reaction"}
        />
        <SingleNotificationSetting
          label="Push Notification on Message Reactions"
          settings={settings}
          settingKey={"push_on_thread_message_reaction"}
        />
        <SingleNotificationSetting
          label="Push Notification on Messages"
          settings={settings}
          settingKey={"push_on_new_thread_message"}
        />
        <SingleNotificationSetting
          label="Push Notification on Friend Requests"
          settings={settings}
          settingKey={"push_on_new_friend_request"}
        />
        <SingleNotificationSetting
          label="Push Notification on Likes"
          settings={settings}
          settingKey={"push_on_new_like_request"}
        />
        <SingleNotificationSetting
          label="Push Notification on New Friends"
          settings={settings}
          settingKey={"push_on_new_friend"}
        />
        <SingleNotificationSetting
          label="Push Notification on New Partners"
          settings={settings}
          settingKey={"push_on_new_partner"}
        />
        <SingleNotificationSetting
          label="Push Notification on Accepted Likes"
          settings={settings}
          settingKey={"push_on_new_like"}
        />
        <SingleNotificationSetting
          label="Push Notification on new Vouches"
          settings={settings}
          settingKey={"push_on_new_vouch"}
        />
        <H5 className="pb-2">Email Notifications</H5>
        {/* <div>
      <div className="flex flex-row"> */}
        <div className="flex flex-1 flex-col justify-start gap-2">
          <SingleNotificationSetting
            label="Email me when I receive a new message"
            settings={settings}
            settingKey={"email_on_new_message_thread"}
          />
          <SingleNotificationSetting
            label="Email on Friend Requests"
            settings={settings}
            settingKey={"email_on_new_friend_request"}
          />
          <SingleNotificationSetting
            label="Email me when I have a new match"
            settings={settings}
            settingKey={"email_on_new_like_request"}
          />
          <SingleNotificationSetting
            label="Email me when I have a new vouch"
            settings={settings}
            settingKey={"email_on_new_vouch"}
          />
          {/* <SingleNotificationSetting
            label="Send me weekly summary emails"
            value={
              data?.user_notification_settings_by_pk
                ?.email_weekly_summary ?? false
            }
            settingKey={"email_weekly_summary"}
          /> */}
          <H5 className="pb-2">SMS Notifications</H5>
          <SingleNotificationSetting
            label="Text me when I have a new vouch"
            settings={settings}
            settingKey={"sms_on_new_vouch"}
          />
        </div>
      </div>
    </div>
  );
}

function NotificationSettingsSection({
  settings,
  settingKeys,
}: {
  settings: Notification_Settings_Keys_453Fragment | undefined | null;
  settingKeys: { label: string; key: keyof Notification_Settings_Keys_453Fragment }[];
}): React.JSX.Element {
  return (
    <div className="flex flex-1 flex-col justify-start gap-2">
      {settingKeys.map(({ label, key }) => (
        <SingleNotificationSetting key={key} label={label} settings={settings} settingKey={key} />
      ))}
      <hr className="border-gray-600 my-" />
    </div>
  );
}

export function EmailNotificationSettings(): React.JSX.Element {
  const my_id = useMyId();
  if (!my_id) {
    throw new Error("missing id in email notifications settings");
  }
  const { data, loading, error } = useMyNotificationSettings453Query({
    variables: { my_id },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    return <ErrorComp caption={error.clientErrors.toString()} />;
  }
  if (loading) {
    return <SpinnerCentered />;
  }

  const settings = data?.user_notification_settings_by_pk;
  const emailSettingKeys: { label: string; key: keyof Notification_Settings_Keys_453Fragment }[] = [
    { label: "New Message", key: "email_on_new_message_thread" },
    { label: "Friend Requests", key: "email_on_new_friend_request" },
    { label: "New Match", key: "email_on_new_like_request" },
    { label: "New Vouch", key: "email_on_new_vouch" },
  ];

  return <NotificationSettingsSection settings={settings} settingKeys={emailSettingKeys} />;
}

export function SmsNotificationSettings(): React.JSX.Element {
  const my_id = useMyId();
  if (!my_id) {
    throw new Error("missing id in SMS notifications settings");
  }
  const { data, loading, error } = useMyNotificationSettings453Query({
    variables: { my_id },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    return <ErrorComp caption={error.clientErrors.toString()} />;
  }
  if (loading) {
    return <SpinnerCentered />;
  }

  const settings = data?.user_notification_settings_by_pk;
  const smsSettingKeys: { label: string; key: keyof Notification_Settings_Keys_453Fragment }[] = [
    { label: "Text me when I have a new vouch", key: "sms_on_new_vouch" },
  ];

  return <NotificationSettingsSection settings={settings} settingKeys={smsSettingKeys} />;
}

export function PushNotificationSettings(): React.JSX.Element {
  const my_id = useMyId();
  if (!my_id) {
    throw new Error("missing id in push notifications settings");
  }
  const { data, loading, error } = useMyNotificationSettings453Query({
    variables: { my_id },
    fetchPolicy: "cache-and-network",
  });

  if (error) {
    return <ErrorComp caption={error.clientErrors.toString()} />;
  }
  if (loading) {
    return <SpinnerCentered />;
  }

  const settings = data?.user_notification_settings_by_pk;
  const pushSettingKeys: { label: string; key: keyof Notification_Settings_Keys_453Fragment }[] = [
    { label: "Status Reactions", key: "push_on_status_reaction" },
    { label: "Message Reactions", key: "push_on_thread_message_reaction" },
    { label: "Messages", key: "push_on_new_thread_message" },
    { label: "Friend Requests", key: "push_on_new_friend_request" },
    { label: "Likes", key: "push_on_new_like_request" },
    { label: "New Friends", key: "push_on_new_friend" },
    { label: "New Partners", key: "push_on_new_partner" },
    { label: "Accepted Likes", key: "push_on_new_like" },
    { label: "New Vouches", key: "push_on_new_vouch" },
  ];
  console.log(settings);
  return <NotificationSettingsSection settings={settings} settingKeys={pushSettingKeys} />;
}
