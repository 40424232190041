import { BasicLocations, LocationPicker, useSaveLocation } from "../../widgets/bio/location-picker";

import { L_Geo_Point_Type } from "shared/dist/__generated__/components";
import { Loc } from "shared/dist/util/loc";
import { ObScreen } from "./widgets";
import React from "react";
import clsx from "clsx";
import { useMustBeLoggedIn } from "../../util/routes";
import { Text } from "../../components/atoms/Text";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMapMarkerAlt } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "../../components/atoms/Button";
import locationMap from "../../assets/location-map.png";
export function ObLocation({
  setDisableButton,
}: {
  onNext: () => void;
  setDisableButton?: (b: boolean) => void;
}): React.JSX.Element {
  const saveLocation = useSaveLocation({ source: L_Geo_Point_Type.Manual, suppressToast: true });
  const [customLocation, setCustomLocation] = React.useState<Loc | null>(null);

  React.useEffect(() => {
    setDisableButton?.(true);
    setTimeout(() => setDisableButton?.(false), 1500);
  }, []);

  React.useEffect(() => {
    if (!customLocation) return;
    setDisableButton?.(true);
    saveLocation(customLocation).then(() => setDisableButton?.(false));
  }, [customLocation, saveLocation]);

  return (
    <ObScreen title="Find other Simmrs in your city">
      <div className="flex flex-col h-full w-full pb-4 items-stretch">
        {/* Location Input */}
        <div className="form-control mb-4">
          <LocationPicker
            onSelect={setCustomLocation}
            hideValueOnDisabled={false}
            hideIcon={false}
            placeholder="Enter city..."
            className="bg-white rounded-xl text-black px-4 py-2 placeholder-gray-400"
          />
        </div>

        {/* Map Placeholder */}
        <div className="relative flex items-center justify-center">
          <img src={locationMap} alt="Map placeholder" className="w-full rounded-xl object-cover" />
          <FontAwesomeIcon icon={faMapMarkerAlt} size="3x" className="absolute text-red-500" />
        </div>

        <div className="flex-1 basis-full"></div>
      </div>
    </ObScreen>
  );
}
