import {
  L_Relationship_Type_Enum,
  Relationship_Types_Enum,
  useBioFromSlugLazyQuery,
  useCanInitiateThreadQuery,
  useMyRelationshipsShortV410Query,
} from "shared/dist/__generated__/components";
import {
  ProfileList,
  ProfileListItem,
  profileListItemFromSummary,
} from "../../../widgets/profile-list";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
  SimmerZeroScreen,
  ZeroScreenActiveFriendsList,
  ZeroScreenMutualFriendsList,
} from "../../../widgets/zero-screens";
import { AddThreadButton, useAddThreadButtonProps } from "../../../widgets/add-thread-button";
import { MultipleTypeRelationshipControlButton } from "../../../widgets/add-relationship-button";
import { SearchBar } from "../../../widgets/search-bar";
import { allRoutes } from "../../../util/routes";
import { filterNulls } from "shared/dist/util";
import { useDebounce } from "use-debounce";
import { useMyId } from "shared/dist/auth-data";
import { useMyInfo } from "../../bio/bio-settings";
import { useNavigate } from "react-router-dom";
import { useTypedParams } from "react-router-typesafe-routes/dom";

type FriendsListProps = {
  friendType: "active" | "mutual-friends";
};

function ProfileActions({
  slug,
  isMyPage,
  isConnected,
}: {
  slug: string;
  isMyPage: boolean;
  isConnected: boolean;
}) {
  const mySlug = useMyInfo().slug;
  const { loading, data } = useCanInitiateThreadQuery({
    skip: !mySlug,
    variables: { slug: slug! },
  });
  const canInitiate = !!data?.l_can_initiate_thread;
  if (isMyPage || canInitiate) {
    return (
      <AddThreadButton variant="outline-square" size="sm" slug={slug} disabled={loading}>
        <div>Message</div>
      </AddThreadButton>
    );
  }

  return (
    <MultipleTypeRelationshipControlButton
      slug={slug}
      rel_types={[Relationship_Types_Enum.Friend, Relationship_Types_Enum.Friend]}
    />
  );
}

export function FriendsList({ friendType }: FriendsListProps): React.JSX.Element {
  const my_id = useMyId();
  const { slug: mySlug } = useMyInfo();
  const [searchTerm, setSearchTerm] = useState("");
  const { slug } = useTypedParams(allRoutes.PROFILE.FRIENDS);
  const navigate = useNavigate();

  const [runBioQuery, { data }] = useBioFromSlugLazyQuery();
  const [debouncedFilter] = useDebounce(searchTerm, 1000);
  const [cachedProfiles, setCachedProfiles] = useState<ProfileListItem[]>([]);

  useEffect(() => {
    if (slug) runBioQuery({ variables: { slug } });
  }, [slug, runBioQuery]);

  const { data: mutualData } = useMyRelationshipsShortV410Query({
    variables: { my_id: my_id ?? "", type: "friend" },
  });

  const { data: likeData } = useMyRelationshipsShortV410Query({
    skip: !my_id,
    variables: { type: "like", my_id: my_id! },
  });

  const isMyPage = data?.l_bio_public?.editable ?? false;

  const profilesFromBio = useMemo(
    () =>
      filterNulls(
        data?.l_bio_public?.relationships
          ?.filter((f) => f.type === L_Relationship_Type_Enum.Friend)
          ?.map(profileListItemFromSummary) ?? []
      ),
    [data]
  );

  const likedProfiles = useMemo(
    () =>
      filterNulls(
        likeData?.relationships_union
          ?.filter((f) => f.type === L_Relationship_Type_Enum.Like)
          ?.map(profileListItemFromSummary) ?? []
      ),
    [likeData]
  );

  const friends = useMemo(() => {
    if (friendType === "mutual-friends") {
      const mutualSlugs =
        mutualData?.relationships_union.map((r) => r.snd_user_summary?.slug) ?? [];
      return profilesFromBio.filter((profile) => mutualSlugs.includes(profile.slug));
    }
    return profilesFromBio;
  }, [friendType, profilesFromBio, mutualData]);

  const filteredProfiles = useMemo(() => {
    if (!searchTerm) return [...friends, ...likedProfiles];

    const allProfiles = [...friends, ...likedProfiles];
    const uniqueProfiles = new Map<string, ProfileListItem>();

    allProfiles.forEach((profile) => {
      if (profile.screenName.toLowerCase().startsWith(searchTerm.toLowerCase())) {
        uniqueProfiles.set(profile.slug!, profile);
      }
    });

    return Array.from(uniqueProfiles.values());
  }, [friends, likedProfiles, searchTerm]);

  // Create mkActions function that doesn't use hooks
  const mkActions = useCallback(
    (profile: ProfileListItem) => {
      if (!profile.slug) return [];

      const isConnected =
        friends.some((friend) => friend.slug === profile.slug) ||
        likedProfiles.some((liked) => liked.slug === profile.slug);

      return [
        {
          component: (
            <ProfileActions
              key={profile.slug}
              slug={profile.slug}
              isMyPage={isMyPage}
              isConnected={isConnected}
            />
          ),
        },
      ];
    },
    [isMyPage, friends, likedProfiles]
  );

  const profilesToDisplay = filteredProfiles.length > 0 ? filteredProfiles : cachedProfiles;

  return (
    <div className="bg-[#2A2C38] flex flex-col h-full w-full mb-12 text-white">
      <SearchBar
        onSearch={(term) => {
          setSearchTerm(term);
          setCachedProfiles(filteredProfiles);
        }}
        placeholder="Search connections..."
        value={searchTerm}
      />
      <div className="className py-1" />
      {friends.length === 0 && likedProfiles.length === 0 && !searchTerm ? (
        <SimmerZeroScreen
          text="No Connections found"
          btnText="Back to Profile"
          header="No Connections Found"
          clickHandler={() => navigate(allRoutes.PROFILE.buildPath({ slug: slug ?? "" }))}
        />
      ) : profilesToDisplay.length === 0 ? (
        <SimmerZeroScreen
          text="No Connections found"
          btnText="Back to Profile"
          header="No Connections Found"
          clickHandler={() => navigate(allRoutes.PROFILE.buildPath({ slug: slug ?? "" }))}
        />
      ) : (
        <ProfileList mkActions={mkActions} profiles={profilesToDisplay} />
      )}
    </div>
  );
}
