import { BioImage, ProfilePageEditFunctions } from "./bio-types";

import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { SpinnerButton } from "../spinner-button";
import { classNames } from "shared/dist/util";
import { faGear } from "@fortawesome/pro-solid-svg-icons";
import { useConfirm } from "shared-web-react/dist/widgets/confirm-provider";
import { useMkImgUrl } from "../../util/routes";

export type MediaControlsProps = {
  img: BioImage;
  prevImage?: BioImage;
  editFunctions: ProfilePageEditFunctions;
  nextImage?: BioImage;
  setLoading: (b: boolean) => void;
  onToggleModal?: (b: boolean) => void;
  pinnedImages: BioImage[];
};
export function MediaControls({
  editFunctions,
  img,
  prevImage,
  nextImage,
  setLoading,
  onToggleModal,
  pinnedImages,
}: // hideControls,
MediaControlsProps): React.JSX.Element {
  // console.log("🚀 ~ file: media-controls.tsx:29 ~ img", img);
  const [editingImage, setEditingImage] = React.useState(false);
  React.useEffect(() => {
    onToggleModal?.(editingImage);
  }, [editingImage]);
  return (
    <>
      {/* <div className="space-x-2 opacity-80 absolute left-2 top-2">
        <button
          className=" btn btn-xs btn-circle "
          disabled={!prevImage}
          onClick={async (evt) => {
            wrapLoading(
              setLoading,
              editFunctions.swapImgSeqNos(img, prevImage)
            );
            evt.stopPropagation();
          }}
        >
          <FontAwesomeIcon icon={faArrowUp} />
        </button>
        <button
          className=" btn btn-xs btn-circle "
          disabled={!nextImage}
          onClick={(evt) => {
            wrapLoading(
              setLoading,
              editFunctions.swapImgSeqNos(img, nextImage)
            );
            evt.stopPropagation();
          }}
        >
          <FontAwesomeIcon icon={faArrowDown} />
        </button>
      </div> */}
      <button
        className={classNames("block btn btn-xs opacity-80 btn-circle absolute left-2 top-2 ")}
        onClick={(evt) => {
          setEditingImage(true);
          evt.stopPropagation();
        }}
      >
        <FontAwesomeIcon icon={faGear} />
      </button>
      {editFunctions && editingImage && (
        <EditProfileMedia
          editFunctions={editFunctions}
          onDismiss={() => setEditingImage(false)}
          image={img}
          pinnedImages={pinnedImages}
        />
      )}
    </>
  );
}

type EditProfileMediaProps = {
  image: BioImage;
  onDismiss: () => void;
  editFunctions: ProfilePageEditFunctions;
  pinnedImages: BioImage[];
};
export function EditProfileMedia({
  image,
  onDismiss,
  editFunctions,
  pinnedImages,
}: EditProfileMediaProps): React.JSX.Element {
  const [loadingDel, setLoadingDel] = React.useState(false);
  const [loadingMakeNSFW, setLoadingMakeNSFW] = React.useState(false);
  const [loadingMakeProfileImage, setLoadingMakeProfileImage] = React.useState(false);
  const url = image.id ? useMkImgUrl()(image.id, "1280") : null;
  const confirm = useConfirm();

  return (
    <div className={classNames("modal  cursor-pointer modal-open !my-0")}>
      <div className="modal-box bg-[#2A2C38] text-base-content">
        <button className="btn btn-sm btn-circle absolute right-2 top-2" onClick={onDismiss}>
          x
        </button>
        <div className="flex space-x-2 p-4">
          <div className="flex-0 w-12">{url && <img src={url} />}</div>
          <div className="flex flex-1 space-y-2 flex-col justify-center">
            <SpinnerButton
              loading={loadingMakeProfileImage}
              className="flex-1 btn btn-primary"
              disabled={loadingDel || loadingMakeProfileImage}
              onClick={async () => {
                setLoadingMakeProfileImage(true);
                // console.log(
                // "🚀 ~ file: thumbnails.tsx ~ line 99 ~ onClick={ ~ image",
                // image
                // );
                if (!image.id) {
                  return;
                }
                await editFunctions.setProfileImage(image.id);
                setLoadingMakeProfileImage(false);
                onDismiss();
              }}
            >
              make profile image
            </SpinnerButton>
            <SpinnerButton
              className="flex-1 btn btn-warning"
              disabled={loadingDel || loadingMakeProfileImage}
              loading={loadingDel}
              onClick={async () => {
                setLoadingDel(true);
                // console.log(
                // "🚀 ~ file: thumbnails.tsx ~ line 123 ~ onClick={ ~ image",
                // image
                // );
                if (!image.id) {
                  return;
                }
                await editFunctions.archiveImage(image.id);
                setLoadingDel(false);
                onDismiss();
              }}
            >
              delete image
            </SpinnerButton>
            <SpinnerButton
              className="flex-1 btn btn-success"
              disabled={loadingMakeNSFW || loadingDel || loadingMakeProfileImage}
              loading={loadingMakeNSFW}
              onClick={async () => {
                setLoadingMakeNSFW(true);
                if (!image.id) {
                  return;
                }
                await editFunctions.setImgNsfw(image.id, !image.is_nsfw);
                setLoadingMakeNSFW(false);
                onDismiss();
              }}
            >
              Mark image NSFW
            </SpinnerButton>
            <SpinnerButton
              className="flex-1 btn btn-accent"
              disabled={loadingMakeNSFW || loadingDel || loadingMakeProfileImage}
              loading={loadingMakeNSFW}
              onClick={async () => {
                if (!image.id) {
                  return;
                }

                let date: string | null = DateTime.now().toISO();
                const isCurrentlyPinned = Boolean(image.pinned_at);

                if (isCurrentlyPinned) {
                  await editFunctions.togglePinnedImage(image.id, null);
                  onDismiss();
                  return;
                }

                // Handle pinning when there are already 3 pinned images
                if (pinnedImages.length === 3 && !isCurrentlyPinned) {
                  const lastPinnedImage = pinnedImages.at(-1);
                  const lastPinnedId = lastPinnedImage?.id;
                  const toPinId = image.id;
                  if (lastPinnedId && toPinId) {
                    confirm({
                      title:
                        "You can only pin 3 images at a time. Pinning this image will unpin your last pinned image. Are you sure you want to continue?",
                      content: null,
                      okButtonContent: "Confirm",
                      onOk: async () => {
                        await editFunctions.togglePinnedImage(lastPinnedId, null);
                        await editFunctions.togglePinnedImage(toPinId, date);
                        onDismiss();
                      },
                    });
                    onDismiss();
                    return;
                  }
                }

                if (!isCurrentlyPinned) {
                  await editFunctions.togglePinnedImage(image.id, date);
                  onDismiss();
                }
              }}
            >
              {image.pinned_at ? "Unpin" : "Pin"} Image
            </SpinnerButton>
          </div>
        </div>
      </div>
    </div>
  );
}
