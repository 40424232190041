import { ModalContainer } from "../../widgets/container";
import clsx from "clsx";
import { match } from "ts-pattern";
import obEventsImage from "../../assets/ob-events.png";
import obMatchImage from "../../assets/ob-match.png";
import obWelcomeImage from "../../assets/ob-welcome-image.png";
import { Text } from "../../components/atoms/Text";
import { Button } from "../../components/atoms/Button";

export type OnboardingScreen = {
  onNext?: () => void;
  screenType: "welcome" | "match-network" | "events";
  navIndexNumber?: number;
};

export function ObSplashScreen({
  onNext,
  screenType,
  navIndexNumber,
}: OnboardingScreen): React.JSX.Element {
  const { imageToDisplay, header, subheader } = match(screenType)
    .with("welcome", () => ({
      imageToDisplay: obWelcomeImage,
      header: "Simmr: Your verified ENM Community",
      subheader: "Connect with real people and enjoy safe adventures with like-minded members.",
    }))
    .with("match-network", () => ({
      imageToDisplay: obMatchImage,
      header: "Grow your network & find matches",
      subheader:
        "Find your friends on Simmr and build new connections with people within the ENM space.",
    }))
    .with("events", () => ({
      imageToDisplay: obEventsImage,
      header: "Have more fun with Events",
      subheader:
        "Want to meet more like-minded people in the wild? We create opportunities to connect before & after events popular in the ENM community.",
    }))
    .exhaustive();

  return (
    <span onClick={onNext}>
      <ModalContainer
        size="lg"
        className={clsx(
          "fixed inset-0 px-0 bg-cover bg-center flex flex-col",
          "justify-between items-center text-center z-50",
          "!bg-[#2A2C38] text-primary-content"
        )}
      >
        {/* Image Section */}
        <div className="relative w-full h-full">
          <img
            className={clsx(
              "absolute inset-0 w-full h-full object-cover mt-20", // Default styling
              screenType === "match-network" && "-top-12" // Reduce size for obMatchImage
            )}
            src={imageToDisplay}
            alt="Splash screen image"
          />
        </div>
        <div className="p-5">
          {/* Text Section */}
          <div className="flex flex-col items-center px-6">
            <Text as="h1" size="md" weight="bold" className="text-white text-left">
              {header}
            </Text>
            <Text as="p" size="xs" className="text-gray-300 text-left">
              {subheader}
            </Text>
          </div>

          {/* Navigation Dots */}
          <div className="flex justify-center space-x-2 mb-6">
            {Array.from({ length: 3 }, (_, index) => (
              <span
                key={index}
                className={clsx(
                  "h-2 w-2 rounded-full",
                  navIndexNumber === index + 1 ? "bg-red-500 w-5" : "bg-gray-500"
                )}
              />
            ))}
          </div>
        </div>
      </ModalContainer>
    </span>
  );
}
