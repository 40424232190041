import { L_Vouch_Level } from "shared/dist/__generated__/components";

import { ContentBox } from "../../../widgets/content-box";
import { Maybe } from "shared/dist/util";
import clsx from "clsx";
import highlyRecommendedBadge from "../../../assets/status-highly-recommended.png";
import { match } from "ts-pattern";
import recommendedBadge from "../../../assets/status-recommended.png";
import topUserBadge from "../../../assets/status-top-user.png";

export type VouchInfoBoxProps = { screenName: string; level: Maybe<L_Vouch_Level> };
export function VouchInfoBox({ level, screenName }: VouchInfoBoxProps): null | React.JSX.Element {
  // const enabled = useIsFeatureEnabled(Feature_Flags_Enum.VouchingV00);
  if (!level) return null;
  return (
    <ContentBox className="px-3 pb-1">
      {match(level)
        .with(L_Vouch_Level.TopUser, () => <TopUser screenName={screenName} />)
        .with(L_Vouch_Level.HighlyRecommended, () => <HighlyRecommended screenName={screenName} />)
        .with(L_Vouch_Level.Recommended, () => <Recommended screenName={screenName} />)
        .exhaustive()}
    </ContentBox>
  );
}
export function VouchSimpleInfo({
  level,
  iconOnly,
}: {
  level: Maybe<L_Vouch_Level>;
  iconOnly?: boolean | null;
}): null | React.JSX.Element {
  const getVouchInfo = () => {
    return match(level)
      .with(L_Vouch_Level.TopUser, () => ({
        badge: topUserBadge,
        text: "Top User",
      }))
      .with(L_Vouch_Level.HighlyRecommended, () => ({
        badge: highlyRecommendedBadge,
        text: "Highly Recommended",
      }))
      .with(L_Vouch_Level.Recommended, () => ({
        badge: recommendedBadge,
        text: "Recommended",
      }))
      .with(undefined, () => null)
      .with(null, () => null)
      .exhaustive();
  };
  const vouchInfo = getVouchInfo();
  if (!vouchInfo) return null;
  return (
    <div className="flex items-center">
      <div className="w-6 flex items-center justify-center">
        <img src={vouchInfo.badge} alt={`${vouchInfo.text} Badge`} className="h-6 w-6" />
      </div>
      {iconOnly ? "" : <span>{vouchInfo.text}</span>}
    </div>
  );
}

function RecommendationBox({
  title,
  body,
  badge,
}: {
  badge: string;
  title: string;
  body: string;
}): null | React.JSX.Element {
  return (
    <div className="flex flex-row justify-start items-center gap-2 p-2">
      <img src={badge} alt={`${title} Badge`} className="h-8 w-8" />
      <div className="flex-col items-start justify-center text-left gap-1">
        <p className={clsx("font-bold")}>{title}</p>
        <p className={clsx("")}>{body}</p>
      </div>
    </div>
  );
}

function HighlyRecommended({
  screenName,
}: Pick<VouchInfoBoxProps, "screenName">): null | React.JSX.Element {
  return (
    <RecommendationBox
      title="Highly Recommended"
      body={`${screenName} is vouched for by a large number of Candid users`}
      badge={highlyRecommendedBadge}
    />
  );
}

function Recommended({
  screenName,
}: Pick<VouchInfoBoxProps, "screenName">): null | React.JSX.Element {
  return (
    <RecommendationBox
      title="Recommended"
      body={`${screenName} is vouched for by Candid users`}
      badge={recommendedBadge}
    />
  );
}

function TopUser({ screenName }: Pick<VouchInfoBoxProps, "screenName">): null | React.JSX.Element {
  return (
    <RecommendationBox
      title="Top User"
      body={`${screenName} is among the most highly vouched-for users on Candid`}
      badge={topUserBadge}
    />
  );
}
