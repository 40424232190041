import { Feature_Flags_Enum, useHasVouchedForQuery } from "shared/dist/__generated__/components";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Spinner } from "shared-web-react/dist/widgets/spinner";
import { VouchMain } from "./vouch-main";
import clsx from "clsx";
import { faHeart } from "@fortawesome/pro-solid-svg-icons";
import { match } from "ts-pattern";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import { useMyId } from "shared/dist/auth-data";
import { Button } from "../../../components/atoms/Button";

function useCanVouchFor(slug: string):
  | {
      status: "not_enabled" | "already_vouched" | "no_relationship" | "can_vouch";
      screenName: string;
      loading?: false;
    }
  | { loading: false; screenName?: undefined; status: "not_enabled" }
  | { loading: true; screenName?: undefined; status?: undefined } {
  const my_id = useMyId()!;
  const checkVouched = useHasVouchedForQuery({
    skip: !my_id,
    variables: { my_id, subject_slug: slug },
    fetchPolicy: "cache-and-network",
  });
  const enabled = true // useIsFeatureEnabled(Feature_Flags_Enum.VouchingV00);
  if (!enabled) return { loading: false, status: "not_enabled" };
  if (checkVouched.loading && !checkVouched.data?.vouches) return { loading: true };
  const screenName = checkVouched.data?.user_summaries?.[0]?.screen_name ?? slug;
  if (checkVouched.data?.accepted_relationships?.length === 0)
    return { screenName, status: "no_relationship" };
  if (!!checkVouched.data?.vouches?.length) return { status: "already_vouched", screenName };
  return { status: "can_vouch", screenName };
}

export type VouchControlProps = {
  size: "full" | "navbar";
  btnClassName?: string;
  slug: string;
  label?: React.ReactNode;
  labelVouched?: React.ReactNode;
  disabledBtnColor?: string;
  iconOverride?: React.ReactNode;
  outlined?: boolean;
};
export function VouchControl({
  label,
  labelVouched,
  btnClassName,
  size,
  slug,
  iconOverride,
  disabledBtnColor,
  outlined,
}: VouchControlProps): null | React.JSX.Element {
  const my_id = useMyId()!;
  const { loading, screenName, status } = useCanVouchFor(slug);
  const [showVouchScreen, setShowVouchScreen] = React.useState(false);
  // console.log("🚀 ~ file: vouch-controls.tsx:33 ~ ", {
  // loading,
  // screenName,
  // status,
  // });
  if (status === "not_enabled" || status === "no_relationship") return <></>;
  if (loading && !status) return <Spinner />;

  const isDisabled = status === "already_vouched" || loading;

  if (status === "already_vouched") return <></>;

  return (
    <React.Fragment>
      {/* <button
        disabled={isDisabled}
        className={clsx(
          "btn whitespace-nowrap overflow-hidden max-w-full",
          match(size)
            .with("full", () => "w-full flex-1 btn-success")
            .with("navbar", () => "text-xs btn-success btn-sm px-1")
            .exhaustive(),
          btnClassName
        )}
        onClick={() => setShowVouchScreen(!showVouchScreen)}
        style={isDisabled ? { backgroundColor: disabledBtnColor } : {}}
      >
        {match([status, size])
          .with(["can_vouch", "full"], () => (
            <span>
              {iconOverride || <FontAwesomeIcon icon={faHeart} className="mr-1" />}
              {label ?? "Vouch for " + screenName}
            </span>
          ))
          .with(["can_vouch", "navbar"], () => "Vouch")
          .with(
            ["already_vouched", "full"],
            () => labelVouched ?? "Already vouched for " + screenName
          )
          .with(["already_vouched", "navbar"], () => "Vouched")
          .exhaustive()}
      </button> */}
      <Button
        disabled={isDisabled}
        onClick={() => setShowVouchScreen(!showVouchScreen)}
        style={isDisabled ? { backgroundColor: disabledBtnColor } : {}}
        // className={clsx(
        //   match(size)
        //     .with("full", () => "w-full flex-1")
        //     .with("navbar", () => "text-xs px-1")
        //     .exhaustive(),
        //   btnClassName
        // )}
        size="sm"
        variant={outlined ? "outline" : "standard"}
      >
        {match([status, size])
          .with(["can_vouch", "full"], () => (
            <span>
              {iconOverride || <FontAwesomeIcon icon={faHeart} className="mr-1" />}
              {label ?? "Vouch for " + screenName}
            </span>
          ))
          .with(["can_vouch", "navbar"], () => "Vouch")
          // .with(
          //   ["already_vouched", "full"],
          //   () => labelVouched ?? "Already vouched for " + screenName
          // )
          // .with(["already_vouched", "navbar"], () => "Vouched")
          .exhaustive()}
      </Button>
      {showVouchScreen && (
        <VouchMain
          {...{
            slug,
            onDismiss: () => {
              setShowVouchScreen(false);
            },
          }}
        />
      )}
    </React.Fragment>
  );
}
