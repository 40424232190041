import { Capacitor } from "@capacitor/core";
import { LandingFooter } from "../landing/footer";
import { Section } from "../terms/terms";
import { TermsNavButtons } from "../terms/widgets";
import { Text } from "../../components/atoms/Text";

export function Support(): React.JSX.Element {
  return (
    <div className="w-screen p-4 h-screen flex flex-col justify-between items-center bg-[#2A2C38]">
      {!Capacitor.isNativePlatform() && <TermsNavButtons />}

      <Section>
        <Text as="h2" size="xs">
          Need Help?
        </Text>

        <Text as="p" size="xs">
          If you have feedback on the app, you can{" "}
          <a
            href="https://simmr.typeform.com/contact?typeform-source=simmr.co"
            className="!text-white"
          >
            submit that here
          </a>
          . App feedback goes directly to our engineering team <br />
          <br />
          For any other support questions, email us at team@simmr.co. We do our best to respond to
          all support requests within 24 hours.
        </Text>
      </Section>
      <LandingFooter />
    </div>
  );
}
