import { GqlOps, useSetStatusMutation } from "shared/dist/__generated__/components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { faGlobe, faXmark } from "@fortawesome/pro-solid-svg-icons";
import { Button } from "../../../components/atoms/Button";
import { UploadProgress } from "../../../widgets/images";
import mixpanel from "mixpanel-browser";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "../../../util/routes";

export function StatusUpdate({
  setCurrentStatus,
}: {
  setCurrentStatus: (s: string) => void;
}): React.JSX.Element {
  const navigate = useNavigate();
  const [setStatusMutation, { loading, error }] = useSetStatusMutation();
  const [status, setStatus] = React.useState("");
  const updateStatus = React.useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      e.stopPropagation();
      if (status.trim().length === 0) {
        return;
      }
      mixpanel.track("Status update posted");
      await setStatusMutation({
        refetchQueries: [
          GqlOps.Query.NewsFeed440,
          GqlOps.Query.BioV2FromSlugFeed403,
          GqlOps.Query.BioV2FromSlugBasics,
          GqlOps.Query.BioFromSlug,
          GqlOps.Query.BioSummaryAbbreviated,
        ],
        variables: { content: status.trim() },
      });
      setCurrentStatus(status);
      setStatus("");
      navigate(allRoutes.me.buildPath({}));
    },
    [setStatusMutation, setStatus, status, setCurrentStatus]
  );
  return (
    <div className="fixed inset-0 flex items-center justify-center bg-black/50 z-50">
      <div className="bg-[#464752] rounded-2xl shadow-xl w-[500px] mx-4">
        <div className="flex justify-between items-center px-4 py-4">
          <h3 className="text-xl text-base-100">Add a post to your timeline</h3>
          <button
            className="btn btn-sm bg-[#2A2C38] btn-circle text-white border-none"
            onClick={() => setCurrentStatus("")}
          >
            <FontAwesomeIcon icon={faXmark} size="lg" />
          </button>
        </div>

        <div className="px-4 pb-4">
          <textarea
            className="w-full bg-white rounded-lg text-base-content p-3 text-lg resize-none focus:outline-none"
            placeholder="What's happening?"
            rows={3}
            value={status}
            onChange={(e) => setStatus(e.target.value)}
          />

          <div className="flex justify-between items-center mt-4">
            <button className="flex items-center gap-2 text-base-100 bg-base-300/30 px-3 py-1.5 rounded-full">
              <FontAwesomeIcon icon={faGlobe} size="sm" />
              <span>Public</span>
            </button>

            <Button
              variant="primary"
              className="px-6 py-1.5 bg-[] text-primary-content rounded-full font-medium !w-1/3"
              onClick={updateStatus}
              disabled={loading}
            >
              Post
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}
