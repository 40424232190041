import React from "react";

import { EventTags } from "../screens/other-pages/events/common";

type ChipContainerProps = {
  tagCodes: Array<keyof typeof EventTags>;
};

const ChipContainer: React.FC<ChipContainerProps> = ({ tagCodes }) => {
  return (
    <div className="flex flex-wrap gap-2 text-black">
      {tagCodes.map((tagCode, i) => (
        <p key={i} className="text-sm p-2 rounded-full bg-[#D1D0E7]">
          {EventTags[tagCode]}
        </p>
      ))}
    </div>
  );
};

export default ChipContainer;
