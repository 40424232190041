import {
  Bio_Basics_On_Success554Fragment,
  Bio_Basics_On_SuccessFragment,
  L_Bio_Access_Denied_Reason_Enum,
  L_Bio_Error_Enum,
  L_Relationship_Type_Enum,
  L_Vouch_Level,
  useBioV2FromSlugBasics554Query,
  useBioV2FromSlugRelationshipsQuery,
} from "shared/dist/__generated__/components";
import { Link, Outlet, useLocation, useMatch, useNavigate } from "react-router-dom";
import { P, match } from "ts-pattern";
import { Spinner, SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { UserPageTab, UserPageTabLabel } from "../../../widgets/tabs";
import {
  faBars,
  faBook,
  faCamera,
  faEyeSlash,
  faGrid,
  faHamburger,
  faNewspaper,
  faUser,
} from "@fortawesome/pro-solid-svg-icons";

import { BioBasicsV2 } from "../bio-basics";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H4 } from "shared-web-react/dist/widgets/text";
import React from "react";
import { allRoutes } from "../../../util/routes";
// import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useNavigateToLogin } from "../../../util/redirects";
import { useSetPageTitle } from "shared-web-react/dist/widgets/nav-stack-view";
import { useTypedParams } from "react-router-typesafe-routes/dom";
import { useIsMyPage } from "../../bio/bio-settings";
import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { Text } from "../../../components/atoms/Text";
// import { VerificationBox } from "../../../widgets/verification-box";
import { VouchSimpleInfo } from "../vouching/vouch-info-box";
import { Button } from "../../../components/atoms/Button";
import clsx from "clsx";
import Chip from "../../../components/molecules/Chip";
import mixpanel from "mixpanel-browser";

function BioAccessDenied({
  reason,
}: {
  reason: L_Bio_Access_Denied_Reason_Enum;
}): React.JSX.Element {
  const navigateToLogin = useNavigateToLogin();
  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 w-full h-full flex justify-center items-center p-4 ">
      <div className="my-[10vh] text-center p-6 card bg-accent max-w-[25rem] flex flex-col gap-2 rounded-xl">
        <H4 className="">
          <FontAwesomeIcon icon={faEyeSlash} />
        </H4>
        <H4>Access denied:</H4>
        {reason === L_Bio_Access_Denied_Reason_Enum.LoginAndVerificationRequired && (
          <>
            <p>You must login and then verify your account to view this profile</p>
            <a className="btn btn-primary" onClick={navigateToLogin}>
              Login
            </a>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.LoginRequired && (
          <>
            <p>You must login to view this profile</p>
            <a className="btn btn-primary" onClick={navigateToLogin}>
              Login
            </a>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.VerificationRequired && (
          <>
            <p>You must verify your account to view this profile</p>
            <Link className="btn btn-primary" to={allRoutes.SETTINGS.PRIVACY.buildPath({})}>
              Go to verification
            </Link>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.RequesterBlockedThisUser && (
          <>
            <p>You must unblock this user to view their profile.</p>
            <Link className="btn btn-primary" to={allRoutes.SETTINGS.ACCOUNT.buildPath({})}>
              Go to blocked users
            </Link>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.RequesterIsBlockedByThisUser && (
          <>
            <p>This user has blocked your account</p>
            <Link className="btn btn-primary" to={allRoutes.HOME.buildPath({})}>
              Ok
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

export function ProfilePageV2(): React.JSX.Element {
  const slug = useTypedParams(allRoutes.PROFILE).slug!;
  const { data, loading } = useBioV2FromSlugBasics554Query({
    skip: !slug,
    variables: { slug },
    fetchPolicy: "cache-and-network",
  });
  const { isMyPage } = useIsMyPage();
  const navigate = useNavigate();
  const { data: relsData, loading: relsLoading } = useBioV2FromSlugRelationshipsQuery({
    skip: !slug,
    variables: { slug },
    fetchPolicy: "cache-first",
  });

  const partners = match(relsData?.l_bio_v2)
    .with({ __typename: "L_Bio_V2_Success" }, (bio) => bio.relationships)
    .otherwise(() => [])
    .filter((f) => f.type === L_Relationship_Type_Enum.Partner)
    .map((p) =>
      p?.slug && p?.screen_name
        ? {
            slug: p.slug,
            screenName: p.screen_name,
          }
        : null
    );
  const partnered = partners.length > 0;
  // const addToast = useAddToast();
  React.useEffect(() => {
    if (!slug) navigate(allRoutes.HOME.buildPath({}));
  }, []);
  if (loading && !data?.l_bio_v2) return <SpinnerCentered />;
  return match(data?.l_bio_v2)
    .with(P.nullish, () => <SpinnerCentered />)
    .with({ __typename: "L_Bio_Error", bio_error: L_Bio_Error_Enum.NotFound }, (_) => (
      <ErrorComp caption={"No such user: " + slug} />
    ))
    .with({ __typename: "L_Bio_Error" }, () => (
      <ErrorComp caption={"Could not load this page, please try again later"} />
    ))
    .with({ access_denied_reason: P.select() }, (reason) => <BioAccessDenied reason={reason} />)
    .with({ __typename: "L_Bio_V2_Success" }, (bioData) =>
      bioData?.id === slug ? (
        isMyPage ? (
          <MyProfilePage {...{ slug, bioData, partnered }} />
        ) : (
          <ProfilePageOnSuccessV2 {...{ slug, bioData, partnered }} />
        )
      ) : (
        <SpinnerCentered />
      )
    )
    .exhaustive();
}

function ProfilePageOnSuccessV2({
  slug,
  bioData,
  partnered,
}: {
  slug: string;
  partnered: boolean;
  bioData: Bio_Basics_On_Success554Fragment;
}): React.JSX.Element {
  const editable = bioData.editable ?? false;
  useSetPageTitle(bioData.user.screen_name);
  return (
    <div className="bg-[#2A2C38] h-full max-lg:pb-20">
      {/* <div className="absolute w-screen top-0 bottom-0"></div> */}
      <BioBasicsV2
        age={bioData.details.age}
        gender={bioData.details.gender}
        orientation={bioData.details.orientation}
        bio={bioData.details.bio}
        screen_name={bioData.user.screen_name}
        friend_count={bioData.details.friend_count}
        tagline={bioData.details.tagline}
        profile_image_id={bioData.user.profile_image_id ?? undefined}
        editable={editable}
        status={bioData.current_status}
        verified={bioData.details.identity_verified ?? false}
        slug={slug}
        vouchLevel={bioData.user.user_vouch_level}
        vouchCount={bioData.details.vouches_for_count ?? 0}
        mediaCount={bioData.media?.media_count ?? 0}
        partnered={partnered}
      />

      <div className="pt-2 flex justify-center text-white space-x-4 px-6">
        <Link
          to={allRoutes.PROFILE.buildPath({ slug })}
          className={clsx(
            "flex items-center space-x-2 font-bold",
            useMatch(allRoutes.PROFILE) ? "text-white" : "text-gray-400"
          )}
        >
          <FontAwesomeIcon icon={faBars} />
          <UserPageTabLabel>Feed</UserPageTabLabel>
        </Link>
        <Link
          to={allRoutes.PROFILE.GALLERY.buildPath({ slug })}
          className={clsx(
            "flex items-center space-x-2",
            useMatch(allRoutes.PROFILE.GALLERY) ? "text-white" : "text-gray-400"
          )}
        >
          <FontAwesomeIcon icon={faGrid} />
          <UserPageTabLabel>Gallery</UserPageTabLabel>
        </Link>
        <Link
          to={allRoutes.PROFILE.ABOUT.buildPath({ slug })}
          className={clsx(
            "flex items-center space-x-2",
            useMatch(allRoutes.PROFILE.ABOUT) ? "text-white" : "text-gray-400"
          )}
        >
          <FontAwesomeIcon icon={faUser} />
          <UserPageTabLabel>Bio</UserPageTabLabel>
        </Link>
      </div>

      {/* {editable && (
            <UserPageTab route={allRoutes.PROFILE.EVENTS.buildPath({ slug })}>
              <FontAwesomeIcon icon={faCakeCandles} />
              <UserPageTabLabel>my events</UserPageTabLabel>
            </UserPageTab>
          )} */}
      {/* <UserPageTab route={allRoutes.PROFILE.ABOUT.buildPath({ slug })}>
            <FontAwesomeIcon icon={faBook} />
            <UserPageTabLabel>About Me</UserPageTabLabel>
          </UserPageTab> */}
      {/* </div> */}
      <div className="pt-2 pb-8">
        <Outlet />
      </div>
    </div>
  );
}

function MyProfilePage({
  slug,
  bioData,
  partnered,
}: {
  slug: string;
  bioData: Bio_Basics_On_Success554Fragment;
  partnered: boolean;
}): React.JSX.Element {
  const editable = bioData.editable ?? false;
  useSetPageTitle(bioData.user.screen_name);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const mediaCount = bioData.media?.media_count ?? 0;

  const activeLinkClass = "text-white font-bold";
  const inactiveLinkClass = "text-gray-400 hover:text-white";

  return (
    <div className="bg-[#2A2C38] h-full max-lg:pb-20">
      <div className="flex px-6 py-4 items-center">
        <Avatar slug={slug!} className="border-2 rounded-full inline-block" tailwindSize="24" />
        <div className="flex flex-col flex-grow justify-stretch pl-4">
          <div className="flex flex-col items-start text-white mb-1">
            <Text as="h1" size="sm" className="leading-none">
              {bioData.user.screen_name}
            </Text>
            {bioData.user?.user_vouch_level ? (
              <div className="pt-2">
                <VouchSimpleInfo level={L_Vouch_Level.HighlyRecommended} />{" "}
              </div>
            ) : (
              ""
            )}
          </div>
          <div className="flex text-center w-full justify-between">
            <div className="flex flex-col items-center">
              <Text as="p" size="xxs" className="py-1">
                {mediaCount}
              </Text>
              <Text as="p" size="xxs" className="text-gray-400">
                Posts
              </Text>
            </div>
            <Link
              to={allRoutes.PROFILE.FRIENDS.buildPath({ slug })}
              className="flex flex-col items-center"
            >
              <Text as="p" size="xxs" className="py-1">
                {bioData.details.friend_count}
              </Text>
              <Text as="p" size="xxs" className="text-gray-400">
                Connections
              </Text>
            </Link>
            <Link
              to={allRoutes.PROFILE.VOUCHES.buildPath({ slug })}
              className="flex flex-col items-center"
            >
              <Text as="p" size="xxs" className="py-1">
                {bioData.details.vouches_for_count}
              </Text>
              <Text as="p" size="xxs" className="text-gray-400">
                Vouches
              </Text>
            </Link>
          </div>
        </div>
      </div>

      <div className="flex px-6">
        <Button
          variant="standard"
          className="mr-2 !text-lg"
          onClick={async () => {
            navigate(allRoutes.SETTINGS.buildPath({}));
          }}
          size="sm"
        >
          Edit Profile
        </Button>
        <Button
          variant="outline"
          className="ml-2 !text-[#FF625F] !border-[#FF625F] !text-lg"
          onClick={() => {
            mixpanel.track("Profile shared");
            if (navigator.share) {
              navigator.share?.({
                url: window.location.href,
                title: `Share my profile on Candid`,
              });
            } else {
              navigator.clipboard?.writeText(window.location.href);
              alert("Copied to Clipboard!");
            }
          }}
          size="sm"
        >
          Share Profile
        </Button>
      </div>
      {/* Bio Section */}
      <Text as="h1" size="xs" className="py-4 px-6">
        {bioData.details.tagline ||
          "Your tagline goes here – let the world know what makes you unforgettable ✨"}
      </Text>
      {/* </div> */}
      {/* <div className="tabs-xs md:tabs-sm lg:tabs-md tabs-bordered tabs flex w-full mt-2 flex-nowrap justify-center pb-1 "> */}
      <div className="flex gap-2 px-6 pb-4">
        <Chip label={bioData.details.gender!} />
        {bioData.details.orientation && (
          <Chip
            label={
              bioData.details.orientation
                ? bioData.details.orientation.charAt(0).toUpperCase() +
                  bioData.details.orientation.slice(1)
                : ""
            }
          />
        )}
        {partnered && <Chip label="Partnered" />}
        {bioData.details.pronouns && bioData.details.pronouns.trim().length > 0 && (
          <Chip label={bioData.details.pronouns} />
        )}
      </div>
      <div className="flex justify-center text-white space-x-4">
        <Link
          to={allRoutes.PROFILE.buildPath({ slug })}
          className={clsx(
            "flex items-center space-x-2",
            useMatch(allRoutes.PROFILE) ? activeLinkClass : inactiveLinkClass
          )}
        >
          <FontAwesomeIcon icon={faBars} />
          <UserPageTabLabel>Feed</UserPageTabLabel>
        </Link>
        <Link
          to={allRoutes.PROFILE.GALLERY.buildPath({ slug })}
          className={clsx(
            "flex items-center space-x-2",
            useMatch(allRoutes.PROFILE.GALLERY) ? activeLinkClass : inactiveLinkClass
          )}
        >
          <FontAwesomeIcon icon={faGrid} />
          <UserPageTabLabel>Grid</UserPageTabLabel>
        </Link>
        <Link
          to={allRoutes.PROFILE.ABOUT.buildPath({ slug })}
          className={clsx(
            "flex items-center space-x-2",
            useMatch(allRoutes.PROFILE.ABOUT) ? activeLinkClass : inactiveLinkClass
          )}
        >
          <FontAwesomeIcon icon={faUser} />
          <UserPageTabLabel>Bio</UserPageTabLabel>
        </Link>
      </div>

      {/* {editable && (
            <UserPageTab route={allRoutes.PROFILE.EVENTS.buildPath({ slug })}>
              <FontAwesomeIcon icon={faCakeCandles} />
              <UserPageTabLabel>my events</UserPageTabLabel>
            </UserPageTab>
          )} */}
      {/* <UserPageTab route={allRoutes.PROFILE.ABOUT.buildPath({ slug })}>
            <FontAwesomeIcon icon={faBook} />
            <UserPageTabLabel>About Me</UserPageTabLabel>
          </UserPageTab> */}
      {/* </div> */}
      <Outlet />
      {/* </div> */}
    </div>
  );
}
