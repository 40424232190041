/* cSpell:disable */
import { AddReaction, ReactionPills } from "../../../widgets/reactions";
import {
  News_Feed_Item_Event_440Fragment,
  News_Feed_Item_Media_440Fragment,
  News_Feed_Item_Media_Reaction_440Fragment,
  News_Feed_Item_Mention_440Fragment,
  News_Feed_Item_Message_Reaction_440Fragment,
  News_Feed_Item_Status_440Fragment,
  News_Feed_Item_Status_Reaction_440Fragment,
  News_Feed_Public_Event_440Fragment,
  useCanInitiateThreadQuery,
} from "shared/dist/__generated__/components";
import { P, match } from "ts-pattern";
import { ReportType, userReportConfirm } from "../settings/safety";
import {
  faHeart,
  faLocationDot,
  faQuoteLeft,
  faQuoteRight,
} from "@fortawesome/pro-solid-svg-icons";
import { faEnvelope, faMessage } from "@fortawesome/pro-regular-svg-icons";

import { AvatarWithScreenName } from "shared-web-react/dist/widgets/avatar";
import { DateTime } from "luxon";
import { DisableableLink } from "../../../widgets/disableable-link";
import { EllipsisActions } from "../../../widgets/ellipsis-actions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { LazyMediaItem } from "../../../widgets/lazy-media-item";
import { Link } from "react-router-dom";
import { Nf_Disc_Data_440Fragment } from "shared/dist/__generated__/components";
import React from "react";
import { allRoutes } from "../../../util/routes";
import clsx from "clsx";
import { distToLocale } from "shared-web-react/dist/util/units";
import { useGenderLookup } from "shared-web-react/dist/helpers/gender";
import { useLoadImageSync } from "shared-web-react/dist/widgets/lazy-image";
import { useMkUrl } from "shared/dist/util/env";
import { AddThreadButton } from "../../../widgets/add-thread-button";
import { createPortal } from "react-dom";
import mixpanel from "mixpanel-browser";

/* cSpell:enable */

type NewsFeedItem =
  | News_Feed_Item_Event_440Fragment
  | News_Feed_Item_Media_440Fragment
  | News_Feed_Item_Status_440Fragment
  | News_Feed_Item_Mention_440Fragment
  | News_Feed_Item_Media_Reaction_440Fragment
  | News_Feed_Item_Message_Reaction_440Fragment
  | News_Feed_Item_Status_Reaction_440Fragment
  | News_Feed_Public_Event_440Fragment;

const DisableDeepLinksContext = React.createContext(false);

function DiscoveryItemSummary({
  item,
  className,
  children,
}: React.PropsWithChildren<{
  className?: string;
  item: NewsFeedItem;
}>): React.JSX.Element | null {
  const genders = useGenderLookup();
  if (!("discovery_data" in item) || !item.discovery_data) return null;
  const { user, partner } = item.discovery_data;
  return (
    <span className={clsx("text-[0.5rem]")}>
      <FontAwesomeIcon icon={faHeart} /> {distToLocale({ dist: user.distance_m, units: "m" })} /{" "}
      {genders?.data?.[user.gender_id]?.name?.en_US ?? ""}{" "}
      {partner && <span>{" + " + (genders?.data?.[partner.gender_id]?.name?.en_US ?? "")}</span>}
    </span>
  );
}

function DiscoveryItemWrapper({
  item,
  className,
  children,
}: React.PropsWithChildren<{
  className?: string;
  item: { discovery_data?: null | Nf_Disc_Data_440Fragment } | {};
}>): React.JSX.Element {
  const isDiscovery = "discovery_data" in item && item.discovery_data?.user;
  return (
    <div className={clsx("relative", className)}>
      {/* <div className={clsx("relative", isDiscovery && "border-2  border-candid-red-300", className)}> */}

      {children}
    </div>
  );
}

function NewsFeedItemMention({
  item,
}: {
  item: News_Feed_Item_Mention_440Fragment;
}): React.JSX.Element {
  const disabled = React.useContext(DisableDeepLinksContext);
  return (
    <ItemTextBox
      content={
        <span>
          Mentioned you in{" "}
          <DisableableLink
            disabled={disabled}
            onDisabledClick={() => alert("Deep linking disabled with dummy data")}
            className="link"
            to={allRoutes.MESSAGING.LIST.THREAD.buildPath({
              thread_id: item.thread_id,
            })}
          >
            this thread
          </DisableableLink>
          .
        </span>
      }
    />
  );
}

function NewsFeedItemMediaReaction({
  item,
}: {
  item: News_Feed_Item_Media_Reaction_440Fragment;
}): React.JSX.Element {
  return (
    <ItemTextBox
      content={
        <div className="flex flex-row justify-between items-center">
          <span>Reacted with {item.reaction} to your image:</span>
          <img src={item.presigned_url} className="h-16 shadow-md" />
        </div>
      }
    />
  );
}

function NewsFeedItemMessageReaction({
  item,
}: {
  item: News_Feed_Item_Message_Reaction_440Fragment;
}): React.JSX.Element {
  const disabled = React.useContext(DisableDeepLinksContext);
  return (
    <ItemTextBox
      showQuotes
      headline={
        <span>
          Reacted with {item.reaction} to your{" "}
          <DisableableLink
            disabled={disabled}
            onDisabledClick={() => alert("Deep linking disabled with dummy data")}
            className="link"
            to={allRoutes.MESSAGING.LIST.THREAD.buildPath({
              thread_id: item.thread_id,
            })}
          >
            message in this thread
          </DisableableLink>
          .
        </span>
      }
      content={
        <Link
          to={allRoutes.MESSAGING.LIST.THREAD.buildPath({
            thread_id: item.thread_id,
          })}
        >
          {item.msg_content}
        </Link>
      }
    />
  );
}

function NewsFeedItemPublicEvent({
  item,
}: {
  item: News_Feed_Public_Event_440Fragment;
}): React.JSX.Element {
  const slug = item.event_slug;
  const imageMediaUploadId = item.media_upload_id;
  if (!slug) return <></>;
  const path = allRoutes.EVENT_FROM_SLUG.COVER_IMAGE.buildPath({ slug });
  const mkUrl = useMkUrl();

  // const eventImgUrl = mkUrl(path) + (imageMediaUploadId ? `?mid=${imageMediaUploadId}` : "");
  const imageData = useLoadImageSync(mkUrl(path));
  return (
    <Link
      to={allRoutes.EVENT_FROM_SLUG.buildPath({ slug })}
      className={clsx("flex h-full flex-row justify-stretch items-center gap-2 py-1")}
    >
      <div
        className={clsx(
          "card w-full h-full bg-base-100 shadow-sm shadow-black my-2",
          "w-full aspect-square bg-no-repeat bg-cover bg-center overflow-hidden"
        )}
        style={imageData.imageData ? { backgroundImage: `url(${imageData.imageData})` } : {}}
      >
        <p className="bg-gray-100 bg-opacity-80 text-primary py-1 px-2 rounded-lg font-bold shadow drop-shadow-md text-sm text-center absolute m-4">
          {DateTime.fromISO(item.start).toFormat("d")} <br />
          {DateTime.fromISO(item.start).toFormat("LLL")}
        </p>
        <div
          className={clsx(
            "flex flex-col items-start justify-center",
            "w-full bg-gradient-to-b from-transparent via-60%  to-80% to-[rgba(0,0,0,0.8)]",
            // "w-full bg-gradient-to-b from-transparent  to-25% to-[oklch(var(--p)/.9)]",
            "absolute top-[66%] bottom-0 left-0 right-0 py-2 px-4 ",
            "text-primary-content"
          )}
        >
          <h2 className="drop-shadow card-title">{item.name}</h2>
          {location && (
            <p className={clsx("drop-shadow text-sm mb-2")}>
              <FontAwesomeIcon icon={faLocationDot} fixedWidth />
              {item.location}
            </p>
          )}
        </div>
      </div>
    </Link>
  );
}

function NewsFeedItemStatusReaction({
  item,
}: {
  item: News_Feed_Item_Status_Reaction_440Fragment;
}): React.JSX.Element {
  return (
    <ItemTextBox
      showQuotes
      headline={`Reacted with ${item.reaction} to your status update`}
      content={item.content}
    />
  );
}

function NewsFeedItemMedia({
  item,
}: {
  item: News_Feed_Item_Media_440Fragment;
}): React.JSX.Element {
  const imgElement = React.useRef<HTMLImageElement | null>(null);
  const [aspectRatio, setAspectRatio] = React.useState(
    item.width && item.height ? item.width / item.height : 1
  );

  return (
    <div className="NewsFeedItemMedia flex flex-col gap-1 w-full items-start">
      {item.caption && <p className="text-sm">{item.caption}</p>}
      {/* Add a max-height constraint to this wrapper */}
      <div className="w-full">
        {" "}
        {/* Added this wrapper */}
        <div
          className="relative w-full rounded-xl overflow-hidden"
          style={{
            paddingBottom: `${100 / aspectRatio}%`,
            // Remove maxHeight from here as it conflicts with padding-bottom
          }}
        >
          <LazyMediaItem
            className="absolute top-0 left-0 w-full h-full object-cover"
            imgRef={imgElement}
            size="1280"
            thumbHash={item.thumb_hash}
            onLoad={(e) => {
              if (!item.width || !item.height) {
                const img = e.currentTarget;
                setAspectRatio(img.naturalWidth / img.naturalHeight);
              }
            }}
            mediaUploadId={item.id}
          />
        </div>
      </div>
      {/* <span className="text-[12px] font-normal leading-[19.2px] tracking-[0.2px] text-left">
        {DateTime.fromISO(item.created_at).toRelative()}
      </span> */}
    </div>
  );
}

function NewsFeedItemEvent({
  item,
}: {
  item: News_Feed_Item_Event_440Fragment;
}): React.JSX.Element {
  return (
    <div>
      <p>event</p>
      <p>{item.name}</p>
    </div>
  );
}

function NewsFeedItemStatus({
  item,
}: {
  item: News_Feed_Item_Status_440Fragment;
}): React.JSX.Element {
  return (
    <div className="w-full">
      <div className="my-2 mb-0 rounded-xl flex flex-col">
        <p className="font-semibold">{item.content}</p>
        <span className="font-sf-pro-display my-2 text-[12px] font-normal leading-[19.2px] tracking-[0.2px] text-left">
          {DateTime.fromISO(item.created_at).toRelative()}
        </span>
      </div>
    </div>
  );
}

export function NewsFeedItem({
  item,
  disableDeepLinks,
}: {
  item: NewsFeedItem;
  disableDeepLinks?: boolean;
}): React.JSX.Element {
  const reportConfirm = userReportConfirm();
  const [zoomedImage, setZoomedImage] = React.useState<ZoomedImageState>(null);

  const slug = match(item)
    .with({ owner_slug: P.select() }, (p) => p)
    .with({ reactor_slug: P.select() }, (p) => p)
    .otherwise(() => null);
  const screenName = match(item)
    .with({ owner_screen_name: P.select() }, (p) => p)
    .with({ reactor_screen_name: P.select() }, (p) => p)
    .otherwise(() => null);
  if (!slug || !screenName) return <></>;

  const initiateThreadQuery = useCanInitiateThreadQuery({
    variables: { slug: slug },
  });
  const connected = !!initiateThreadQuery?.data?.l_can_initiate_thread;

  const reportObj = match(item)
    .returnType<{ reportType: ReportType; reportItemId: string } | null>()
    .with({ __typename: "L_News_Feed_Item_Event" }, () => null)
    .with({ __typename: "L_News_Feed_Item_Public_Event" }, () => null)
    .with({ __typename: "L_News_Feed_Item_Media" }, () => ({
      reportType: "image",
      reportItemId: item.id,
    }))
    .with({ __typename: "L_News_Feed_Item_Media_Reaction" }, () => null)
    .with({ __typename: "L_News_Feed_Item_Mention" }, () => null)
    .with({ __typename: "L_News_Feed_Item_Message_Reaction" }, () => null)
    .with({ __typename: "L_News_Feed_Item_Status" }, () => ({
      reportType: "status",
      reportItemId: item.id,
    }))
    .with({ __typename: "L_News_Feed_Item_Status_Reaction" }, () => null)
    .exhaustive();

  return (
    <DisableDeepLinksContext.Provider value={!!disableDeepLinks}>
      <div className="flex flex-col w-full relative">
        <DiscoveryItemWrapper
          item={item}
          className="w-full bg-[#2A2C38] py-2 gap-2 flex flex-col justify-start text-left px-6"
        >
          <Link
            onClick={() => {
              mixpanel.track("Profile Clicked From News Feed Item", {
                screenName,
              });
            }}
            to={allRoutes.PROFILE.buildPath({ slug })}
            className="flex flex-row items-center justify-between gap-2"
          >
            <div className="flex flex-row items-center gap-2">
              <AvatarWithScreenName
                tailwindSize="10"
                slug={slug}
                screenName={screenName}
                caption={`@${slug}`}
              />
            </div>
          </Link>
          <div className="flex flex-col gap-2 w-full">
            {match(item)
              .with({ __typename: "L_News_Feed_Item_Event" }, (item) => (
                <NewsFeedItemEvent item={item} />
              ))
              .with({ __typename: "L_News_Feed_Item_Public_Event" }, (item) => (
                <NewsFeedItemPublicEvent item={item} />
              ))
              .with({ __typename: "L_News_Feed_Item_Media" }, (item) => (
                <NewsFeedItemMedia item={item} />
              ))
              .with({ __typename: "L_News_Feed_Item_Media_Reaction" }, (item) => (
                <NewsFeedItemMediaReaction item={item} />
              ))
              .with({ __typename: "L_News_Feed_Item_Mention" }, (item) => (
                <NewsFeedItemMention item={item} />
              ))
              .with({ __typename: "L_News_Feed_Item_Message_Reaction" }, (item) => (
                <NewsFeedItemMessageReaction item={item} />
              ))
              .with({ __typename: "L_News_Feed_Item_Status" }, (item) => (
                <NewsFeedItemStatus item={item} />
              ))
              .with({ __typename: "L_News_Feed_Item_Status_Reaction" }, (item) => (
                <NewsFeedItemStatusReaction item={item} />
              ))
              .exhaustive()}
          </div>
          {!item.__typename.match(/reaction/i) &&
            item.__typename !== "L_News_Feed_Item_Public_Event" && (
              <div className="flex flex-row justify-start items-center gap-3 leading-none h-8">
                <div className="flex flex-row items-center">
                  <AddReaction objectId={item.id} />
                  <span className="text-sm text-gray-300">
                    <ReactionPills objectId={item.id} />
                  </span>
                </div>
                <div className="w-24 flex items-center">
                  {connected && (
                    <AddThreadButton slug={slug} variant="ghost">
                      <div className="flex items-center gap-1.5 py-0.5">
                        <FontAwesomeIcon icon={faEnvelope} />
                        <span className="text-xs leading-none">Message</span>
                      </div>
                    </AddThreadButton>
                  )}
                </div>
              </div>
            )}
        </DiscoveryItemWrapper>
        <div className="w-full h-px bg-white/50 absolute bottom-0" />
      </div>
      {zoomedImage &&
        createPortal(<ZoomedImage {...{ setZoomedImage, zoomedImage }} />, document.body)}
    </DisableDeepLinksContext.Provider>
  );
}

function ItemTextBox({
  headline,
  content,
  showQuotes,
}: {
  showQuotes?: boolean;
  content: React.ReactNode;
  headline?: React.ReactNode;
}): React.JSX.Element {
  return (
    <div className="w-full">
      <div
        className="m-2   border-primary rounded-xl shadow-md p-5 flex flex-col"
        style={{ borderWidth: "1px" }}
      >
        {headline && <p className="text-xs">{headline}</p>}
        {showQuotes && (
          <p>
            <FontAwesomeIcon icon={faQuoteLeft} fixedWidth />
          </p>
        )}
        <p className="font-semibold">{content}</p>
        {showQuotes && (
          <p className="self-end">
            {" "}
            <FontAwesomeIcon icon={faQuoteRight} fixedWidth />
          </p>
        )}
      </div>
    </div>
  );
}

type ZoomedImageState = null | [string, string];

type ZoomedImageProps = {
  zoomedImage: NonNullable<ZoomedImageState>;
  setZoomedImage: (_: ZoomedImageState) => void;
};

const ZOOM_SETTINGS = {
  MIN_SCALE: 1,
  MAX_SCALE: 5,
  ZOOM_SPEED: 0.001,
};

export function ZoomedImage({ setZoomedImage, zoomedImage }: ZoomedImageProps): React.JSX.Element {
  const containerRef = React.useRef<HTMLDivElement>(null);
  const figureRef = React.useRef<HTMLElement>(null);
  const imageRef = React.useRef<HTMLImageElement>(null);
  const lastTouchRef = React.useRef<{ clientX: number; clientY: number }[]>([]);
  const lastPinchDistanceRef = React.useRef<number>(0);

  const [transform, setTransform] = React.useState({
    scale: 1,
    x: 0,
    y: 0,
  });

  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (figureRef.current?.contains(e.target as Node)) {
      return;
    }
    setZoomedImage(null);
  };

  // Calculate distance between two touch points
  const getPinchDistance = (touches: { clientX: number; clientY: number }[]): number => {
    if (touches.length !== 2) return 0;
    const dx = touches[0].clientX - touches[1].clientX;
    const dy = touches[0].clientY - touches[1].clientY;
    return Math.sqrt(dx * dx + dy * dy);
  };

  // Calculate center point of pinch gesture
  const getPinchCenter = (
    touches: { clientX: number; clientY: number }[]
  ): { x: number; y: number } => {
    const rect = containerRef.current?.getBoundingClientRect();
    if (!rect || touches.length !== 2) return { x: 0, y: 0 };

    const x = (touches[0].clientX + touches[1].clientX) / 2 - rect.left;
    const y = (touches[0].clientY + touches[1].clientY) / 2 - rect.top;
    return { x, y };
  };

  // Handle zoom with proper scaling and centering
  const handleZoom = (scale: number, centerX: number, centerY: number) => {
    setTransform((prev) => {
      const newScale = Math.max(ZOOM_SETTINGS.MIN_SCALE, Math.min(scale, ZOOM_SETTINGS.MAX_SCALE));

      if (newScale === prev.scale) return prev;

      const rect = containerRef.current?.getBoundingClientRect();
      if (!rect) return prev;

      const pointX = centerX;
      const pointY = centerY;
      const scaleRatio = newScale / prev.scale;

      return {
        scale: newScale,
        x: pointX - (pointX - prev.x) * scaleRatio,
        y: pointY - (pointY - prev.y) * scaleRatio,
      };
    });
  };

  React.useEffect(() => {
    const element = containerRef.current;
    if (!element) return;

    const handleTouchStart = (e: TouchEvent) => {
      const touches = Array.from(e.touches).map((t) => ({
        clientX: t.clientX,
        clientY: t.clientY,
      }));

      lastTouchRef.current = touches;

      if (touches.length === 2) {
        e.preventDefault();
        lastPinchDistanceRef.current = getPinchDistance(touches);
      }
    };

    const handleTouchMove = (e: TouchEvent) => {
      const touches = Array.from(e.touches).map((t) => ({
        clientX: t.clientX,
        clientY: t.clientY,
      }));

      // Handle pinch zoom
      if (touches.length === 2 && lastTouchRef.current.length === 2) {
        e.preventDefault();
        const currentDistance = getPinchDistance(touches);
        const initialDistance = lastPinchDistanceRef.current;

        if (initialDistance > 0) {
          const scale = transform.scale * (currentDistance / initialDistance);
          const center = getPinchCenter(touches);
          handleZoom(scale, center.x, center.y);
        }

        lastPinchDistanceRef.current = currentDistance;
      }
      // Handle pan
      else if (touches.length === 1 && lastTouchRef.current.length === 1) {
        const deltaX = touches[0].clientX - lastTouchRef.current[0].clientX;
        const deltaY = touches[0].clientY - lastTouchRef.current[0].clientY;

        setTransform((prev) => ({
          ...prev,
          x: prev.x + deltaX,
          y: prev.y + deltaY,
        }));
      }

      lastTouchRef.current = touches;
    };

    const handleTouchEnd = () => {
      lastPinchDistanceRef.current = 0;
      lastTouchRef.current = [];
    };

    element.addEventListener("touchstart", handleTouchStart, { passive: false });
    element.addEventListener("touchmove", handleTouchMove, { passive: false });
    element.addEventListener("touchend", handleTouchEnd);
    element.addEventListener("touchcancel", handleTouchEnd);

    return () => {
      element.removeEventListener("touchstart", handleTouchStart);
      element.removeEventListener("touchmove", handleTouchMove);
      element.removeEventListener("touchend", handleTouchEnd);
      element.removeEventListener("touchcancel", handleTouchEnd);
    };
  }, [transform.scale]);

  return (
    <div className="fixed inset-0 z-50 overflow-hidden" onClick={handleBackgroundClick}>
      <div className="absolute inset-0 bg-[#000000]/75" />

      <div ref={containerRef} className="absolute inset-0 flex items-center justify-center">
        <figure
          ref={figureRef}
          className="relative max-w-[95vw] will-change-transform"
          style={{
            transform: `translate3d(${transform.x}px, ${transform.y}px, 0) scale(${transform.scale})`,
            transition: transform.scale === 1 ? "transform 0.2s ease-out" : "none",
          }}
        >
          <LazyMediaItem
            className="w-auto h-auto max-w-full max-h-full object-contain"
            imgRef={imageRef}
            size="1280"
            thumbHash={zoomedImage[0]}
            mediaUploadId={zoomedImage[1]}
          />
        </figure>
      </div>
    </div>
  );
}
