import React from "react";
import { classNames } from "shared/dist/util";

export function ContentBox({
  children,
  className,
}: React.PropsWithChildren<{
  className?: React.HTMLAttributes<HTMLDivElement>["className"];
}>): React.JSX.Element {
  return (
    <div
      className={classNames(
        "content-box max-md:text-xs md:text-sm rounded-md shadow-sm m-1 p-1 lg:m-2 lg:p-2",
        className,
        !className?.includes("bg-") && "bg-base-100"
      )}
    >
      {children}
    </div>
  );
}

export function ContentBoxInfoLine({
  // topic,
  hideTopDivider,
  children,
}: {
  hideTopDivider?: boolean;
  // topic: string;
  children: React.ReactNode | [React.ReactNode, React.ReactNode];
}): React.JSX.Element {
  return (
    <>
      {!hideTopDivider && <div className=" my-0 divider" />}
      <div className="w-full max-md:text-xs items-center md:text-sm gap-4 flex flex-row flex-nowrap justify-between ">
        <dt className="flex-0 min-w-fit whitespace-nowrap capitalize text-right text-ellipsis overflow-hidden">
          {Array.isArray(children) ? children[0] : children}
        </dt>
        <dd className="flex-0 whitespace-nowrap text-right text-ellipsis overflow-hidden">
          {Array.isArray(children) ? children[1] : null}
        </dd>
      </div>
    </>
  );
}
