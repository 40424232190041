import { GqlOps, L_Bio_Field } from "shared/dist/__generated__/components";
import { ValidatedInput, ValidatedInputProps } from "../../widgets/inputs/validated-input";
import { allRoutes, useMustBeLoggedIn } from "../../util/routes";

import React from "react";
import mixpanel from "mixpanel-browser";
import { useEnv } from "shared/dist/util/env";
import { useMySlug } from "./bio-settings";
import { useSetUrlMutation } from "shared/dist/__generated__/components";
import { z } from "zod";

export function useCopyMyUrl(): [true, null] | [false, () => void] {
  const { slug, loading } = useMySlug() ?? {};
  const baseUri = useEnv("REACT_APP_BASE_URI").replace(/\/$/, "");
  if (!slug || loading) {
    return [true, null];
  }
  return [
    false,
    () => {
      const urlForClipboard = `${baseUri}${allRoutes.PROFILE.buildPath({
        slug,
      })}`;
      navigator.clipboard.writeText(urlForClipboard);
    },
  ];
}

export type UrlSlugFormProps = {
  dontLoadDbValue?: boolean;
} & Pick<
  ValidatedInputProps,
  | "onValid"
  | "onLoading"
  | "onChange"
  | "hideHost"
  | "hideReset"
  | "labelOverride"
  | "validationField"
  | "saveComponentOverride"
  | "defaultValueTreatAsValid"
  | "hideSubmitInstructions"
>;

export function UrlSlugForm({
  hideHost,
  dontLoadDbValue,
  saveComponentOverride,
  ...validatedInputProps
}: UrlSlugFormProps): React.JSX.Element {
  const user_id = useMustBeLoggedIn();
  const baseUri = useEnv("REACT_APP_BASE_URI").replace(/\/$/, "");
  const [setUrl] = useSetUrlMutation();
  // const navigate = useNavigate();
  const { slug, loading: loadingMySlug } = useMySlug() ?? {};
  console.log("🚀 ~ file: url-slug-form.tsx:59 ~ slug:", slug);
  const submitHandler = React.useCallback(
    async (value: string) => {
      if (!user_id) {
        return;
      }
      try {
        const resultSlug = (
          await setUrl({
            refetchQueries: [GqlOps.Query.UserUrlSlug],
            variables: {
              id: user_id,
              slug: value,
            },
          })
        )?.data?.update_user_url_slugs?.returning?.[0]?.slug;
        console.log("🚀 ~ file: url-slug-form.tsx:72 ~ resultSlug:", resultSlug);
        // if (!resultSlug) {
        //   return "Unable to save URL";
        // } else {
        //   // navigate(allRoutes.PROFILE.buildPath({ slug: resultSlug }), {
        //   //   replace: true,
        //   // });
        // }
        mixpanel.track("User set a custom URL slug");
      } finally {
      }
    },
    [setUrl]
  );
  return (
    <ValidatedInput
      {...{
        inputPrefix: "@",
        inputOpts: { autoComplete: "off" },
        inputPrefixLg: hideHost ? "@" : baseUri + "/@",
        defaultValue: dontLoadDbValue ? undefined : slug,
        defaultValueLoading: loadingMySlug,
        saveComponentOverride,
        submitHandler,
        validationField: L_Bio_Field.UrlSlug,
        validationMinLength: 6,
        fieldSchema: z
          .string()
          .min(6, { message: "Please use at least 6 characters." })
          .max(64, { message: "Please use less than 64 characters" })
          .regex(/^[a-z0-9][-A-Za-z0-9_]*[a-z0-9]$/i, {
            message: "Please use only letters, numbers, underscores, or dashes.",
          }),
        ...validatedInputProps,
      }}
    />
  );
}
