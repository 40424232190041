import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import { UserPageTab, UserPageTabLabel } from "../../../widgets/tabs";
import { faChain, faPeople } from "@fortawesome/pro-solid-svg-icons";
import { faUserGroupSimple, faUserPlus } from "@fortawesome/pro-light-svg-icons";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H3 } from "shared-web-react/dist/widgets/text";
import { ProfileList } from "../../../widgets/profile-list";
import { SignUpTriggerLarge } from "../signup-triggers";
import { SpinnerFullScreen } from "shared-web-react/dist/widgets/spinner";
import { UserPageContainer } from "../common";
import { allRoutes } from "../../../util/routes";
import { useBioFromSlugQuery } from "shared/dist/__generated__/components";
import { useMyId } from "shared/dist/auth-data";

function OtherPeoplesFriends(): React.JSX.Element {
  return (
    <div>
      <div>
        <ProfileList profiles={[]} />
      </div>
    </div>
  );
}

function MyFriends(): React.JSX.Element {
  return (
    <div>
      <div>
        <ProfileList profiles={[]} />
      </div>
    </div>
  );
}

export function FriendsMain(): React.JSX.Element {
  const slug = useParams().slug ?? "";
  const navigate = useNavigate();
  // useMustBeLoggedIn();
  const loggedIn = !!useMyId();
  const { data, loading } = useBioFromSlugQuery({
    variables: { slug: slug! },
    fetchPolicy: "cache-and-network",
  });
  const myPage = data?.l_bio_public?.editable;
  // const friends = React.useMemo(() => {
  //   return filterNulls(
  //     data?.l_bio_public?.relationships
  //       ?.filter((f) => f.type === L_Relationship_Type_Enum.Friend)
  //       ?.map((f) => ({
  //         slug: f.slug,
  //         screenName: f.screen_name,
  //       })) ?? []
  //   );
  // }, [data]);
  if (loading) {
    return (
      <UserPageContainer>
        <SpinnerFullScreen />
      </UserPageContainer>
    );
  }
  if (slug.length === 0) {
    navigate("/me");
  }

  return (
    <div className="bg-[#2A2C38] h-full pt-5 px-6">
      <div className={"flex flex-row gap-4"}>
        {myPage ? (
          <>
            <UserPageTab
              hideIndicator
              route={allRoutes.PROFILE.FRIENDS.buildPath({
                slug,
              })}
            >
              <UserPageTabLabel>My Connections</UserPageTabLabel>
            </UserPageTab>
            <UserPageTab
              route={allRoutes.PROFILE.FRIENDS.INBOUND_REQUESTS.buildPath({
                slug,
              })}
            >
              <UserPageTabLabel>Requests</UserPageTabLabel>
            </UserPageTab>
            <UserPageTab
              route={allRoutes.PROFILE.FRIENDS.OUTBOUND_REQUESTS.buildPath({
                slug,
              })}
            >
              <UserPageTabLabel>Sent</UserPageTabLabel>
            </UserPageTab>
          </>
        ) : (
          <div className={"flex flex-row gap-4"}>
            <UserPageTab
              hideIndicator
              route={allRoutes.PROFILE.FRIENDS.buildPath({
                slug,
              })}
            >
              <UserPageTabLabel>All Connections</UserPageTabLabel>
            </UserPageTab>
            <UserPageTab
              route={allRoutes.PROFILE.FRIENDS.MUTUAL.buildPath({
                slug,
              })}
            >
              <UserPageTabLabel>Mutual Connections</UserPageTabLabel>
            </UserPageTab>
          </div>
        )}
      </div>
      <div className="pt-2 flex-1 overflow-auto">
        <Outlet />
      </div>{" "}
    </div>
  );
  return (
    <UserPageContainer className="flex flex-col h-full !px-0 bg-[#2A2C38] ">
      {/* <div className="text-center">
        <Link to={allRoutes.PROFILE.buildPath({ slug })}>
          <H3 className="my-4 text-primary">{data?.l_bio_public?.user?.screen_name ?? "\u00A0"}</H3>
        </Link>
      </div> */}
      {loggedIn ? (
        <>
          <div className={"flex flex-row gap-4"}>
            {myPage ? (
              <>
                <UserPageTab
                  hideIndicator
                  route={allRoutes.PROFILE.FRIENDS.buildPath({
                    slug,
                  })}
                >
                  <FontAwesomeIcon icon={faUserGroupSimple} />
                  <UserPageTabLabel>My Friends</UserPageTabLabel>
                </UserPageTab>
                <UserPageTab
                  route={allRoutes.PROFILE.FRIENDS.INBOUND_REQUESTS.buildPath({
                    slug,
                  })}
                >
                  <FontAwesomeIcon icon={faUserPlus} />
                  <UserPageTabLabel>Requests</UserPageTabLabel>
                </UserPageTab>
                <UserPageTab
                  route={allRoutes.PROFILE.FRIENDS.OUTBOUND_REQUESTS.buildPath({
                    slug,
                  })}
                >
                  <FontAwesomeIcon icon={faUserPlus} />
                  <UserPageTabLabel>Requests Sent</UserPageTabLabel>
                </UserPageTab>
              </>
            ) : (
              <div className={"flex flex-row gap-4"}>
                <UserPageTab
                  hideIndicator
                  route={allRoutes.PROFILE.FRIENDS.buildPath({
                    slug,
                  })}
                >
                  <FontAwesomeIcon icon={faPeople} />
                  <UserPageTabLabel>All Friends</UserPageTabLabel>
                </UserPageTab>
                <UserPageTab
                  route={allRoutes.PROFILE.FRIENDS.MUTUAL.buildPath({
                    slug,
                  })}
                >
                  <FontAwesomeIcon icon={faChain} />
                  <UserPageTabLabel>Mutual Friends</UserPageTabLabel>
                </UserPageTab>
              </div>
            )}
          </div>
          <div className="pt-2 flex-1 overflow-auto">
            <Outlet />
          </div>{" "}
        </>
      ) : (
        <SignUpTriggerLarge />
      )}
    </UserPageContainer>
  );
}
