import clsx from "clsx";
import { createPortal } from "react-dom";
import { ReactNode } from "react";

const Modal = ({
  isOpen,
  onDismiss,
  children,
}: {
  isOpen: boolean; // Controlled visibility
  onDismiss: () => void;
  children: ReactNode;
}) => {
  if (!isOpen) return null; // Don't render the modal if not open

  return createPortal(
    <div className={clsx("fixed inset-0 flex items-center justify-center z-50")}>
      <div className="modal-backdrop fixed inset-0 bg-black bg-opacity-50" onClick={onDismiss} />
      <div className="modal-box w-96 relative bg-[#464752] text-white rounded-xl p-9 shadow-lg">
        {children}
        <button
          className="bg-white rounded-full absolute top-4 right-4 text-black hover:text-gray-300 w-6 h-6 flex items-center justify-center leading-none text-[16px]"
          onClick={onDismiss}
        >
          &times;
        </button>
      </div>
    </div>,
    document.body
  );
};

export default Modal;
