import { BioFromSlugContext, useBioAndSlug } from "../bio-context";
import {
  Feature_Flags_Enum,
  L_Bio_Access_Denied_Reason_Enum,
  useBioFromSlugQuery,
} from "shared/dist/__generated__/components";
import { Link, Outlet, useParams } from "react-router-dom";
import { UserPageTab, UserPageTabLabel } from "../../../widgets/tabs";
import { faBook, faCamera, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";

import { BioBasicsV1 } from "../bio-basics";
import { ErrorComp } from "shared-web-react/dist/widgets/errors";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { H4 } from "shared-web-react/dist/widgets/text";
import { ProfilePageV2 } from "./profile-page-v2";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
// import { useMyId } from "shared/dist/auth-data";
import { allRoutes } from "../../../util/routes";
import clsx from "clsx";
import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import { useNavigateToLogin } from "../../../util/redirects";
import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { useTypedParams } from "react-router-typesafe-routes/dom";

function BioAccessDenied({
  reason,
}: {
  reason: L_Bio_Access_Denied_Reason_Enum;
}): React.JSX.Element {
  const navigateToLogin = useNavigateToLogin();
  return (
    <div className="fixed top-0 left-0 bottom-0 right-0 w-full h-full flex justify-center items-center p-4 ">
      <div className="my-[10vh] text-center p-6 card bg-accent max-w-[25rem] flex flex-col gap-2 rounded-xl">
        <H4 className="">
          <FontAwesomeIcon icon={faEyeSlash} />
        </H4>
        <H4>Access denied:</H4>
        {reason === L_Bio_Access_Denied_Reason_Enum.LoginAndVerificationRequired && (
          <>
            <p>You must login and then verify your account to view this profile</p>
            <a className="btn btn-primary" onClick={navigateToLogin}>
              Login
            </a>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.LoginRequired && (
          <>
            <p>You must login to view this profile</p>
            <a className="btn btn-primary" onClick={navigateToLogin}>
              Login
            </a>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.VerificationRequired && (
          <>
            <p>You must verify your account to view this profile</p>
            <Link className="btn btn-primary" to={allRoutes.SETTINGS.PRIVACY.buildPath({})}>
              Go to verifcation
            </Link>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.RequesterBlockedThisUser && (
          <>
            <p>You must unblock this user to view their profile.</p>
            <Link className="btn btn-primary" to={allRoutes.SETTINGS.ACCOUNT.buildPath({})}>
              Go to blocked users
            </Link>
          </>
        )}
        {reason === L_Bio_Access_Denied_Reason_Enum.RequesterIsBlockedByThisUser && (
          <>
            <p>This user has blocked your account</p>
            <Link className="btn btn-primary" to={allRoutes.HOME.buildPath({})}>
              Ok
            </Link>
          </>
        )}
      </div>
    </div>
  );
}

export function ProfilePage(): React.JSX.Element {
  return <ProfilePageV2 />;
}
