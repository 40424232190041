export const EventTags = {
  v01_closed: "Private Event",
  v01_public: "Public Event",
  v01_partner: "Partner Event",
  v01_festival: "Festival",
  v01_concert: "Concert",
  v01_mixer: "Mixer",
  v01_takeover: "Takeover",
  v01_ambassador_event: "Ambassador Event",
  v01_first_timer: "Good First Event",
};

export const EventTagsForSelectPills = Object.entries(EventTags).map(([k, v]) => ({
  value: k as keyof typeof EventTags,
  label: v,
}));
