import {
  Feature_Flags_Enum,
  useMyRelationshipsShortV410Query,
} from "shared/dist/__generated__/components";
import { ProfileList, ProfileListItem, ProfileListProps } from "../../../widgets/profile-list";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { classNames, filterNulls } from "shared/dist/util";

import { AddThreadButton } from "../../../widgets/add-thread-button";
import { SearchBar } from "../../../widgets/search-bar";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { SimmerZeroScreen, ZeroScreen } from "../../../widgets/zero-screens";
import { allRoutes } from "../../../util/routes";
import { useMyId } from "shared/dist/auth-data";
import { useMyInfo } from "../../bio/bio-settings";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faMessage } from "@fortawesome/pro-regular-svg-icons";
import { useNavigate } from "react-router-dom";

export function DiscoveryConnections(): React.JSX.Element {
  const my_id = useMyId();
  const { slug: mySlug } = useMyInfo();
  const [searchTerm, setSearchTerm] = useState<string>("");
  const navigate = useNavigate();
  const { data, loading, refetch } = useMyRelationshipsShortV410Query({
    skip: !my_id,
    variables: { type: "like", my_id: my_id! },
  });
  const {
    data: friendsData,
    loading: friendsLoading,
    refetch: refetchFriends,
  } = useMyRelationshipsShortV410Query({
    skip: !my_id,
    variables: { type: "friend", my_id: my_id! },
  });

  const connections: Array<ProfileListItem> = useMemo(
    () =>
      filterNulls(
        data?.relationships_union?.map((e) =>
          e.snd_user_summary?.slug && e.snd_user_summary?.screen_name
            ? {
                slug: e.snd_user_summary.slug,
                screenName: e.snd_user_summary.screen_name,
              }
            : null
        )
      ),
    [data]
  );

  const friends: Array<ProfileListItem> = useMemo(
    () =>
      filterNulls(
        friendsData?.relationships_union?.map((e) =>
          e.snd_user_summary?.slug && e.snd_user_summary?.screen_name
            ? {
                slug: e.snd_user_summary.slug,
                screenName: e.snd_user_summary.screen_name,
              }
            : null
        )
      ),
    [friendsData]
  );

  const filteredItems = useMemo(() => {
    if (searchTerm === "") {
      return [...connections, ...friends];
    } else {
      return [...connections, ...friends].filter((item) =>
        item.screenName.toLowerCase().startsWith(searchTerm.toLowerCase())
      );
    }
  }, [connections, friends, searchTerm]);

  const mkRoute: ProfileListProps["mkRoute"] = React.useCallback(
    ({ slug, ...other }: ProfileListItem) =>
      slug
        ? {
            to: allRoutes.PROFILE.buildPath({ slug }),
          }
        : null,
    []
  );

  const mkActions: ProfileListProps["mkActions"] = React.useCallback(
    ({ slug }: ProfileListItem) => [
      slug
        ? {
            component: (
              <AddThreadButton slug={slug} variant="outline-square" size="sm">
                <span className="mr-2">
                  <FontAwesomeIcon icon={faEnvelope} size="1x" />
                </span>
                Message
              </AddThreadButton>
            ),
          }
        : null,
    ],
    []
  );

  if (loading || friendsLoading) {
    return <SpinnerCentered />;
  }

  if (filteredItems.length === 0 && !searchTerm) {
    return (
      <div className="bg-[#2A2C38] flex flex-col h-full w-full overflow-hidden">
        <div className="flex my-4">
          <SearchBar
            onSearch={(term: string) => setSearchTerm(term)}
            placeholder="Search connections..."
            value={searchTerm}
          />
        </div>
        <SimmerZeroScreen
          header="Discover Connections"
          text="Home of your future connections. Keep sending likes. We'll let you know when you have a new connection!"
          clickHandler={() => navigate(allRoutes.DISCOVERY.buildPath({}))}
          btnText="Find Connections"
        />
      </div>
    );
  }

  return (
    <div
      className={classNames(
        "flex justify-start items-stretch flex-col join join-vertical gap-4 h-full relative"
      )}
    >
      <div className="flex my-2 px-2">
        <SearchBar
          onSearch={(term: string) => setSearchTerm(term)}
          placeholder="Search connections..."
          value={searchTerm}
        />
      </div>
      <div className={classNames("py-4 pb-16 max-h-full overflow-y-auto")}>
        {filteredItems.length === 0 ? (
          <div>No results found</div>
        ) : (
          <ProfileList
            profiles={filteredItems}
            mkActions={mkActions}
            mkRoute={mkRoute}
            columnCountOverride={1}
          />
        )}
      </div>
    </div>
  );
}
