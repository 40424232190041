import { PortalDialog } from "shared-web-react/dist/widgets/confirm-provider";
import React from "react";
import { Relationship_Types_Enum } from "shared/dist/__generated__/components";
import { classNames } from "shared/dist/util";
import clsx from "clsx";
import { useAddToast } from "shared-web-react/dist/widgets/toast-provider";
import { useRelationshipFunctions } from "shared/dist/connections/relationships";
import { Button } from "../../components/atoms/Button";
import Modal from "../../components/molecules/Modal";
import { Text } from "../../components/atoms/Text";

export type MatchNotificationPopupProps = {
  className?: string;
  slug: string;
  showPopup?: string | null;
  refetch: () => void;
};

export function MatchNotificationPopup({
  slug,
  showPopup,
  refetch,
}: React.PropsWithChildren<MatchNotificationPopupProps>): React.JSX.Element {
  const [showDialog, setShowDialog] = React.useState(showPopup);
  const ref = React.createRef<HTMLDialogElement>();
  ref?.current?.showModal;
  React.useEffect(
    () => (showPopup ? ref?.current?.showModal?.() : ref?.current?.close?.()),
    [showPopup]
  );
  const [loading, setLoading] = React.useState(false);

  const addToast = useAddToast();
  const { acceptRequest, disconnect } = useRelationshipFunctions({
    setLoading,
    onError: (content) => addToast({ content, color: "error" }),
  });

  return (
    <Modal
      isOpen={!!showPopup}
      onDismiss={() => {
        refetch();
        setShowDialog(null);
      }}
    >
      <Text as="h3" size="sm" weight="semibold">
        Do you want to connect with {slug}?
      </Text>
      <Button
        onClick={() => {
          acceptRequest(slug, Relationship_Types_Enum.Like).then(() => {
            refetch();
          });
        }}
        variant="standard"
        className="my-2"
      >
        Connect
      </Button>
      <Button
        onClick={() => {
          disconnect(slug, Relationship_Types_Enum.Like).then(() => {
            refetch();
          });
        }}
        variant="outline"
        className="my-2"
      >
        Pass
      </Button>
    </Modal>
  );
}
