import { Maybe, filterNulls, notNullish, sortBy, typedFromEntries, uniqBy } from "shared/dist/util";
import { ProfileList, ProfileListItem } from "../../widgets/profile-list";
import {
  Thread_List_Details_554Fragment,
  useUserThreads554Query,
} from "shared/dist/__generated__/components";
import { faFaceParty, faImage } from "@fortawesome/pro-solid-svg-icons";

import { DateTime } from "luxon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { SpinnerCentered } from "shared-web-react/dist/widgets/spinner";
import { allRoutes } from "../../util/routes";
import clsx from "clsx";
import { useMyId } from "shared/dist/auth-data";
import { SimmerZeroScreen } from "../../widgets/zero-screens";
import { Link, useNavigate } from "react-router-dom";
import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { LazyImage } from "shared-web-react/dist/widgets/lazy-image";
import { useMkUrl } from "shared/dist/util/env";
import { Text } from "../../components/atoms/Text";
import simmerHeart from "../../assets/Feed-Active.png";
type ThreadData = Thread_List_Details_554Fragment;
function threadToListItem(myId: string, mkUrl: (path: string) => string) {
  return (
    thread: Maybe<ThreadData>
  ): null | { thread_id: string; last_msg_dt?: DateTime; item: ProfileListItem } => {
    if (!thread) return null;
    const unblockedMembers = thread.members?.filter(
      (member) =>
        !(member.member_id === myId) &&
        !member.user_public?.this_user_blocked_by_users?.length &&
        !member.user_public?.users_blocked_by_this_user?.length
    ).length;
    if (unblockedMembers < 1 && !thread.event?.id) return null;

    const msg = thread?.messages?.[0];
    const caption = (
      <span>
        {msg?.message_media_uploads?.[0] && (
          <FontAwesomeIcon icon={faImage} className={clsx("mr-2")} />
        )}
        {msg?.content?.slice(0, 200)}
      </span>
    );
    const path = allRoutes.EVENT_FROM_SLUG.COVER_IMAGE.buildPath({
      slug: thread.event?.url_slug ?? "",
    });

    const eventImgUrl =
      mkUrl(path) + (thread.event?.media_upload_id ? `?mid=${thread.event?.media_upload_id}` : "");

    return {
      thread_id: thread.id,
      last_msg_dt: thread.messages?.[0]?.created_at
        ? DateTime.fromISO(thread.messages?.[0]?.created_at)
        : undefined,
      item: {
        avatarOverride: thread.event?.id ? (
          <div className="w-full flex h-full justify-center items-center">
            <div
              className={clsx(
                ` avatar w-${12} h-${12} flex-col-center-center aspect-square rounded-full`,
                ` bg-cover bg-no-repeat bg-center shadow-md `
              )}
            >
              <LazyImage
                src={eventImgUrl}
                alt="avatar"
                className="w-full h-full rounded-full aspect-square bg-white"
                imgClassName="aspect-square"
              />
            </div>
          </div>
        ) : undefined,
        caption,
        subCaption: thread?.messages?.[0]?.created_at
          ? DateTime.fromISO(thread?.messages?.[0]?.created_at).toLocaleString(
              DateTime.DATETIME_SHORT
            )
          : undefined,
        slug: thread?.members?.map((m) => m.user_public?.slug)?.filter(Boolean)?.[0],
        screenName:
          thread.event?.name ??
          (thread?.members?.map((m) => m.user_public?.screen_name)?.filter(Boolean) ?? []).join(
            ", "
          ),
      },
    };
  };
}

export function ThreadList(): React.JSX.Element {
  const myId = useMyId()!;
  const mkUrl = useMkUrl();
  const { data, loading } = useUserThreads554Query({
    skip: !myId,
    variables: { limit: 100, my_id: myId },
    fetchPolicy: "cache-and-network",
    pollInterval: 1000 * 60,
  });
  const navigate = useNavigate();
  const threads = filterNulls([
    ...(data?.event_attendees?.flatMap((ea) => ea.event?.threads) ?? []),
    ...(data?.thread_members?.map((tm) => tm.thread) ?? []),
    ...(data?.events?.flatMap((tm) => tm.threads) ?? []),
  ]).filter((t) => t.event?.id || !!t.messages?.length);
  const eventCreationDates = typedFromEntries([
    ...filterNulls(
      data?.event_attendees?.map((ea) =>
        ea.event
          ? ([ea.event.threads?.[0].id, DateTime.fromISO(ea.event.created_at)] as [
              string,
              DateTime,
            ])
          : null
      )
    ),
    ...filterNulls(
      data?.events?.map(
        (ea) => [ea.threads[0]?.id, DateTime.fromISO(ea.created_at)] as [string, DateTime]
      )
    ),
  ]);

  const threadListItems: Array<{
    item: ProfileListItem;
    thread_id: string;
  }> = React.useMemo(
    () =>
      sortBy(
        uniqBy(filterNulls(threads.map(threadToListItem(myId, mkUrl))), (t) => t.thread_id),
        (t) => -1 * (t.last_msg_dt?.toMillis() ?? eventCreationDates[t.thread_id]?.toMillis() ?? 0)
      ),
    [data]
  );

  const unreadSet = React.useMemo(
    () =>
      new Set(
        threads
          ?.filter(
            (thread) =>
              thread.messages?.[0]?.sender_id !== myId &&
              (!thread.last_viewed_at?.[0]?.last_viewed_at ||
                thread.messages?.[0]?.created_at > thread.last_viewed_at?.[0]?.last_viewed_at)
          )
          .map((t) => t.id)
      ),
    [data]
  );
  return (
    <div className="overflow-y-auto px-6 pb-10 max-h-full bg-[#2A2C38]">
      {loading && !threadListItems?.length ? (
        <SpinnerCentered />
      ) : threadListItems.length > 0 ? (
        // <ProfileList
        //   columnCountOverride={1}
        //   className="py-4"
        //   hasBadge={(_profile, idx) => {
        //     return unreadSet.has(threadListItems[idx]?.thread_id);
        //   }}
        //   mkRoute={({}, idx) => ({
        //     to: allRoutes.MESSAGING.LIST.THREAD.buildPath({
        //       thread_id: threadListItems[idx]?.thread_id ?? "foo",
        //     }),
        //   })}
        //   profiles={threadListItems.map(({ item }) => item)}
        // />
        threadListItems.map(({ item, thread_id }) => (
          <React.Fragment>
            <div className="bg-candid-gray-500 w-full my-4 h-[1px] first:hidden"></div>
            <Link
              to={allRoutes.MESSAGING.LIST.THREAD.buildPath({
                thread_id: thread_id ?? "foo",
              })}
              className="grid gap-2 m-2"
              style={{
                gridTemplateColumns: "15% 55% 25% 5%",
              }}
            >
              <Avatar slug={item.slug!} tailwindSize="14" />
              <div className="px-4">
                <Text as="h2" size="xs">
                  {item.screenName}
                </Text>
                <span className="flex flex-col gap-2 w-full self-start">
                  <Text
                    as="p"
                    size="xxs"
                    className="overflow-ellipsis overflow-hidden whitespace-nowrap"
                  >
                    {typeof item.caption === "string" && item.caption.length > 15
                      ? `${item.caption.slice(0, 15)}...`
                      : item.caption}
                  </Text>
                </span>
              </div>
              <Text as="p" size="tiny" weight="light" className="place-self-center">
                {item.subCaption
                  ? DateTime.fromFormat(item.subCaption, "M/d/yyyy, h:mm a").toRelative({
                      style: "short",
                    })
                  : ""}{" "}
                {/* {threadListItems[0]?.item.subCaption} */}
              </Text>
              <div
                className={clsx(
                  "z-10 left-2 rounded-full place-self-center h-[9px] w-[9px]",
                  unreadSet.has(thread_id) ? "bg-red-500" : "bg-[#2A2C38]"
                )}
              ></div>
            </Link>
          </React.Fragment>
        ))
      ) : (
        <div className="text-white py-2 px-4 text-lg font-semibold text-center">
          <SimmerZeroScreen
            btnText="Message Connections"
            text="You're up to date! Go ahead and connect with like-minded people."
            header="No Current Messages"
            clickHandler={() => navigate(allRoutes.DISCOVERY.MATCHES.buildPath({}))}
          />{" "}
        </div>
      )}
    </div>
  );
}

// return (
//   <FullScreenSubMenuSideBar>
//     <div className="tabs flex w-full flex-nowrap mb-4 justify-center">
//       <a
//         onClick={() => setTab("in_network")}
//         className={classNames(
//           "tab tab-bordered text-white ",
//           tab === "in_network"
//             ? "border-white" //  text-white"
//             : "border-candid-gray-700" // text-candid-gray-700"
//         )}
//       >
//         Main
//       </a>
//       <a
//         onClick={() => setTab("external")}
//         className={classNames(
//           "tab tab-bordered text-white ",
//           tab === "external"
//             ? "border-white " //  text-white"
//             : "border-candid-gray-700" // text-candid-gray-700"
//         )}
//       >
//         Other
//       </a>
//     </div>
//     <div className="overflow-y-auto">
//       {loading ? (
//         <SpinnerCentered />
//       ) : profiles.length > 0 ? (
//         <ProfileList
//           columnCountOverride={1}
//           className="p-4"
//           hasBadge={(_profile, idx) => {
//             return unreadSet.has(profiles[idx]?.thread_id);
//           }}
//           mkRoute={({ screenName, slug }, idx) => ({
//             state: { title: screenName, slug },
//             to: allRoutes.MESSAGING.THREAD.buildPath({
//               thread_id: profiles[idx]?.thread_id ?? "foo",
//             }),
//           })}
//           // profiles={[...profiles, ...profiles, ...profiles, ...profiles].map(
//           profiles={profiles.map(({ item, thread_id }) => item)}
//         />
//       ) : (
//         <div className="text-white py-2 text-lg font-semibold text-center">
//           No messages yet..
//         </div>
//       )}
//     </div>
//   </FullScreenSubMenuSideBar>
// );
