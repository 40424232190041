import clsx from "clsx";
import React from "react";
import { createPortal } from "react-dom";
import matchSuccessBg from "../../assets/match-success-bg.png";
import { Text } from "../../components/atoms/Text";
import { Button } from "../../components/atoms/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/pro-regular-svg-icons";
import { AvatarFromUrl } from "shared-web-react/dist/widgets/avatar";
import { useAddThreadButtonProps } from "../../widgets/add-thread-button";
import { useProfileImageUrl } from "shared-web-react/dist/util/url-helpers";
import { useMyInfo } from "../bio/bio-settings";

export type SuccessTakeoverProps = React.PropsWithChildren<{
  onDismiss?: () => void;
  className?: string;
  slug: string;
  refetch: () => Promise<void>;
}>;

export function SuccessTakeover({
  onDismiss,
  children,
  className,
  slug,
  refetch,
}: SuccessTakeoverProps): React.JSX.Element {
  const { disabled, loading, ...props } = useAddThreadButtonProps(slug);
  const { slug: mySlug } = useMyInfo();
  const { url: imgUrl, thumbHash } = useProfileImageUrl(slug);
  const { url: myImage } = useProfileImageUrl(mySlug!);

  return createPortal(
    <div className="fixed inset-0 flex justify-center items-center z-1000-navbar">
      <div
        onClick={onDismiss}
        className={clsx(
          "fixed flex flex-col items-center justify-between inset-0 bg-cover bg-center",
          className
        )}
        style={{ backgroundImage: `url(${matchSuccessBg})` }}
      >
        <div className="flex flex-col items-center justify-between h-full w-full max-w-md px-4">
          {/* Title section - increased text size for larger screens */}
          <Text
            as="h1"
            weight="bold"
            size="xl"
            variant="display"
            className="!text-gray-900 leading-tight text-center mt-[8vh] text-4xl sm:text-6xl"
          >
            You've <span className="block">Connected!</span>
          </Text>
          {/* <AvatarFromUrl url={profileImage1} size="lg" className="hidden sm:block" /> */}
          {/* Images section - increased image sizes for larger screens */}
          <div className="flex-1 px-5 w-full max-w-sm flex items-center justify-center mt-12 sm:mt-0">
            <div className="relative w-full h-[280px] sm:h-[420px] flex items-center justify-center">
              {/* First (top) image */}
              <div className="absolute z-20 w-[55vw] h-[55vw] -left-1 -top-[10vh] sm:-top-12 rounded-3xl overflow-hidden shadow-xl bg-white -rotate-[12deg]">
                <div className="absolute inset-0 rounded-3xl border-[7px] border-gray-800"></div>
                <img src={myImage} alt="First showcase" className="w-full h-full object-cover" />
              </div>

              {/* Second (bottom) image */}
              <div className="absolute z-1000-navbar w-[55vw]  h-[55vw] sm:w-64 sm:h-64 right-0 top-[12vh] sm:top-28 rounded-3xl overflow-hidden shadow-xl bg-white rotate-[12deg]">
                <div className="absolute inset-0 rounded-3xl border-[7px] border-gray-800"></div>
                <img src={imgUrl} alt="Second showcase" className="w-full h-full object-cover" />
              </div>
            </div>
          </div>

          {/* Bottom section */}
          <div className="w-full mb-8 sm:mb-12">
            <Button
              {...props}
              variant="secondary"
              onClick={(event) => {
                if (props.onClick) {
                  props.onClick(event);
                }
                if (onDismiss) {
                  onDismiss();
                }
              }}
              className="!bg-[#2A2C38] w-full rounded-full py-3 sm:py-5 text-base sm:text-xl mb-4"
            >
              Say Hello <FontAwesomeIcon icon={faEnvelope} className="ml-2" />
            </Button>
            <button
              className="w-full text-center !text-white sm:text-lg font-medium"
              onClick={() => {
                refetch().then(() => {
                  onDismiss?.();
                });
              }}
            >
              Later
            </button>
          </div>
        </div>
      </div>
    </div>,
    document.body
  );
}
