import {
  // Feature_Flags_Enum,
  GqlOps,
  useAddVouchMutation,
  useHasVouchedForQuery,
  useVouchInteractionTypesQuery,
} from "shared/dist/__generated__/components";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import React from "react";
import { SpinnerButton } from "shared-web-react/dist/widgets/spinner-button";
import { SpinnerCheckbox } from "shared-web-react/dist/widgets/spinner-checkbox";
import clsx from "clsx";
import { createPortal } from "react-dom";
import { match } from "ts-pattern";
import { uniqWith } from "shared/dist/util";
// import { useIsFeatureEnabled } from "shared/dist/util/feature-flags";
import { useMyId } from "shared/dist/auth-data";
import { userReportConfirm } from "../settings/safety";
import mixpanel from "mixpanel-browser";
import { Button } from "../../../components/atoms/Button";

const VOUCH_MODAL_ID = "vouch_modal";

type CurrentVouchPhase =
  | "HadRealExperience"
  | "Affirmations"
  | "LetUsKnowWhenChanges"
  | "Interaction"
  | "WouldMeetAgain"
  | "WouldRefer"
  | "AnythingElse"
  | "FinalScreen";

export type VouchMainProps = {
  slug: string;
  onDismiss: () => void;
};

type VouchData = {
  wouldRefer?: boolean;
  wouldMeet?: boolean;
  interactions?: Array<string>;
};

export function VouchMain({ slug, onDismiss }: VouchMainProps): React.JSX.Element {
  const [phase, setPhase] = React.useState<CurrentVouchPhase>("HadRealExperience");
  const [mutate, { loading: mutationLoading }] = useAddVouchMutation();
  const [vouchData, setVouchData] = React.useState<VouchData>({});
  const ref = React.useRef<HTMLDialogElement>(null);
  const my_id = useMyId()!;
  const checkVouched = useHasVouchedForQuery({
    skip: !my_id,
    variables: { my_id, subject_slug: slug },
    fetchPolicy: "cache-and-network",
  });
  const subject_user_id = checkVouched.data?.user_summaries?.[0].id;
  const screenName = checkVouched.data?.user_summaries?.[0].screen_name ?? slug;
  React.useEffect(() => {
    ref?.current?.id && (document.getElementById(ref.current.id) as any)?.showModal?.();
  }, [ref]);
  const ops = React.useMemo(
    () => ({
      onInteractionType: (interactionTypes: Array<string>) => {
        setVouchData((d) => ({ ...d, interactions: interactionTypes }));
        mixpanel.track("New vouch modal interaction types selected", {
          interaction_types: interactionTypes,
        });

        setPhase("WouldMeetAgain");
      },
      onAffirmed: () => {
        setPhase("Interaction");
        mixpanel.track("New vouch modal affirmed");
      },
      onHadExperience: (b: "yes" | "no") => {
        const newPhase = b === "yes" ? "Interaction" : "LetUsKnowWhenChanges";
        setPhase(newPhase);
        mixpanel.track("New vouch modal had experience", {
          had_experience: b,
        });
      },
      async next() {
        console.log("hi");
      },
    }),
    [vouchData, setVouchData, phase, setPhase, confirm]
  );
  React.useEffect(() => {
    console.log("🚀 ~ file: vouch-main.tsx:42 ~ VouchMain ~ vouchData:", vouchData);
  }, [vouchData]);
  const onComplete = React.useCallback(async () => {
    console.log(
      "🚀 ~ file: vouch-main.tsx:96 ~ onComplete ~ vouchData:",
      vouchData,
      subject_user_id
    );
    if (!subject_user_id) throw new Error("Could not load user to vouch for");
    if (!vouchData.interactions) return;
    await mutate({
      refetchQueries: [GqlOps.Query.HasVouchedFor],
      variables: {
        my_id,
        subject_user_id,
        would_refer: vouchData.wouldRefer,
        would_meet: vouchData.wouldMeet,
        interactions: vouchData.interactions.map((interaction_type_id) => ({
          interaction_type_id,
        })),
      },
    });
    try {
      mixpanel.track("New vouch modal submitted");
    } catch (e) {
      console.error("MP Error: ", e);
    }
    onDismiss();
  }, [mutate, onDismiss, vouchData]);

  React.useEffect(() => {
    try {
      mixpanel.track("New vouch modal opened");
    } catch (e) {
      console.error("MP Error: ", e);
    }
  }, []);
  const enabled = true // useIsFeatureEnabled(Feature_Flags_Enum.VouchingV00);
  if (!enabled) return <></>;
  return createPortal(
    <dialog ref={ref} id={VOUCH_MODAL_ID} className={clsx("modal modal-middle")}>
      <div className="modal-box shadow-lg bg-[#464752] text-white flex-col-center-center gap-3 max-h-[80vh] overflow-y-scroll ">
        <Avatar tailwindSize="14" slug={slug} />
        {match(phase)
          .with("HadRealExperience", () => (
            <AskRealExperience
              {...{ onChoose: ops.onHadExperience, slug, screen_name: screenName }}
            />
          ))
          .with("Affirmations", () => (
            <VouchAffirmations onNext={ops.onAffirmed} {...{ slug, screen_name: screenName }} />
          ))
          .with("LetUsKnowWhenChanges", () => (
            <LetUsKnowWhenChanges {...{ onDismiss, slug, screen_name: screenName }} />
          ))
          .with("Interaction", () => (
            <Interaction
              allowMultiple
              {...{ onInteraction: ops.onInteractionType, slug, screen_name: screenName }}
            />
          ))
          .with("WouldMeetAgain", () => (
            <WouldMeetAgain
              {...{ slug, screen_name: screenName }}
              onChoose={(val) => {
                setVouchData((d) => ({ ...d, wouldMeet: val === "yes" }));
                setPhase(
                  match(val)
                    .returnType<CurrentVouchPhase>()
                    .with("no", () => "WouldRefer")
                    .with("yes", () => "FinalScreen")
                    .exhaustive()
                );
                try {
                  mixpanel.track("New vouch modal would meet again", {
                    would_meet: val,
                  });
                } catch (e) {
                  console.error("MP Error: ", e);
                }
              }}
            />
          ))
          .with("WouldRefer", () => (
            <WouldRefer
              {...{ slug, screen_name: screenName }}
              onChoose={(val) => {
                setVouchData((d) => ({ ...d, wouldRefer: val === "yes" }));
                setPhase(
                  match(val)
                    .returnType<CurrentVouchPhase>()
                    .with("yes", () => "FinalScreen")
                    .with("no", () => "AnythingElse")
                    .exhaustive()
                );
                try {
                  mixpanel.track("New vouch modal would refer", {
                    would_refer: val,
                  });
                } catch (e) {
                  console.error("MP Error: ", e);
                }
              }}
            />
          ))
          .with("AnythingElse", () => (
            <AnythingElse onNext={onDismiss} {...{ slug, screen_name: screenName }} />
          ))
          .with("FinalScreen", () => (
            <FinalScreen
              status={vouchData.wouldMeet ? "wouldMeet" : "wouldRefer"}
              onNext={onComplete}
              {...{ slug, screen_name: screenName }}
            />
          ))
          .exhaustive()}
      </div>
      <button
        className="modal-backdrop"
        onClick={(e) => {
          onDismiss();
          e.preventDefault();
          e.stopPropagation();
        }}
      />
    </dialog>,
    document.body
  );
}

function VouchStepHeading({ children }: { children: React.ReactNode }): React.JSX.Element {
  return <div className="text-lg font-mibold text-center">{children}</div>;
}
function StepButton({
  className,
  children,
  isPrimary,
  ...props
}: { isPrimary?: boolean } & React.ButtonHTMLAttributes<HTMLButtonElement>): React.JSX.Element {
  // React.DetailedHTMLProps<
  return (
    // <button
    //   className={clsx(
    //     "btn whitespace-nowrap grow shrink-0 w-min basis-full px-1 focus:outline-none",
    //     isPrimary ? "btn-primary" : "btn-outline",
    //     className
    //   )}
    //   {...props}
    // >
    //   {children}
    // </button>
    <Button className="w-full" variant={isPrimary ? "primary" : "outline"} {...props}>
      {children}
    </Button>
  );
}

type VouchStepProps = { slug: string; screen_name: string };

type VouchStepOnChooseProps = { onChoose: (b: "yes" | "no") => void } & VouchStepProps;
type VouchStepOnNextProps = { onNext: () => void } & VouchStepProps;

function AskRealExperience({ screen_name, onChoose }: VouchStepOnChooseProps): React.JSX.Element {
  return (
    <>
      <VouchStepHeading>Have you had an in-person experience with {screen_name}?</VouchStepHeading>
      <div className={clsx("flex flex-col items-center gap-4 w-full")}>
        <StepButton onClick={() => onChoose("yes")} isPrimary>
          Yes
        </StepButton>
        <StepButton onClick={() => onChoose("no")}>Not Yet</StepButton>
      </div>
    </>
  );
}

function NextOrReport({
  onNext,
  children,
}: React.PropsWithChildren<{ onNext: () => void }>): React.JSX.Element {
  const report = userReportConfirm();
  return (
    <>
      <button onClick={onNext} className={clsx("btn btn-primary")}>
        {children}
      </button>
      <button
        onClick={() => {
          onNext();
          report("message", "", "slug"); // TODO fix reports
        }}
        className={clsx("text-primary link")}
      >
        Report an issue
      </button>
    </>
  );
}

function LetUsKnowWhenChanges({
  onDismiss,
}: VouchStepProps & { onDismiss: () => void }): React.JSX.Element {
  const report = userReportConfirm();
  return (
    <>
      <VouchStepHeading>Thank you. Let us know when that changes.</VouchStepHeading>
      <NextOrReport onNext={onDismiss}>Sounds Good</NextOrReport>
    </>
  );
}

function Interaction({
  allowMultiple,
  onInteraction,
}: VouchStepProps & {
  allowMultiple?: boolean;
  onInteraction: (interactionTypes: Array<string>) => void;
}): React.JSX.Element {
  const { data, loading } = useVouchInteractionTypesQuery({ fetchPolicy: "cache-first" });
  const options: Array<readonly [string, string]> =
    data?.vouch_interaction_types?.map((item) => [item.id, item.localized_string.en_US] as const) ??
    [];
  const [selected, setSelected] = React.useState<Array<string>>([]);
  return (
    <>
      <VouchStepHeading>Just a few questions:</VouchStepHeading>
      <div className="flex-col-center-center items-stretch w-full gap-1">
        <p className="text-sm">How did you interact with them?</p>
        {/* {allowMultiple ? ( */}
        <ul className={clsx("py-2")}>
          {options.map((option, idx) => (
            <li
              key={option[0]}
              className={clsx(
                "border-r border-l px-2 py-1",
                idx ? "border-b" : "border-t border-b"
              )}
            >
              <div className="form-control">
                <label className="cursor-pointer  flex flex-row justify-start items-center my-1 text-sm gap-2">
                  <input
                    type={allowMultiple ? "checkbox" : "radio"}
                    checked={selected.includes(option[0])}
                    onChange={(e) => {
                      console.log("🚀 ~ file: vouch-main.tsx:203 ~ e:", e.target.value, selected);
                      selected.includes(option[0])
                        ? setSelected(selected.filter((s) => s !== option[0]))
                        : setSelected(
                            allowMultiple ? uniqWith([...selected, option[0]]) : [option[0]]
                          );
                    }}
                    className={allowMultiple ? "checkbox" : "radio"}
                    name={allowMultiple ? undefined : "interaction-radio"}
                  />
                  <span className="">{option[1]}</span>
                </label>
              </div>
            </li>
          ))}
        </ul>
        {/* <button
          disabled={loading || selected.length === 0}
          className={clsx("btn btn-primary w-full")}
          onClick={() => selected.length && onInteraction(selected)}
        >
          Next
        </button> */}
        <Button
          className="w-full"
          onClick={() => selected.length && onInteraction(selected)}
          disabled={loading || selected.length === 0}
        >
          Next
        </Button>
      </div>
    </>
  );
}

function WouldMeetAgain({
  slug,
  screen_name,
  onChoose,
}: VouchStepOnChooseProps): React.JSX.Element {
  return (
    <>
      <VouchStepHeading>Would you hang out with {screen_name} again?</VouchStepHeading>
      <div className={clsx("flex flex-col justify-center w-full gap-4")}>
        <StepButton onClick={() => onChoose("yes")} isPrimary>
          Yes
        </StepButton>
        <StepButton onClick={() => onChoose("no")}>No</StepButton>
      </div>
    </>
  );
}

function WouldRefer({ slug, screen_name, onChoose }: VouchStepOnChooseProps): React.JSX.Element {
  return (
    <>
      <VouchStepHeading>Thank you for being honest.</VouchStepHeading>
      <VouchStepHeading>Would you refer other people hang out with {screen_name}?</VouchStepHeading>
      <div className={clsx("flex-row-center-center gap-2 w-min")}>
        <StepButton onClick={() => onChoose("no")}>No</StepButton>
        <StepButton onClick={() => onChoose("yes")} isPrimary>
          Yes
        </StepButton>
      </div>
    </>
  );
}

function VouchAffirmations({ onNext, screen_name }: VouchStepOnNextProps): React.JSX.Element {
  const delay = 3000;
  const [understand, setUnderstand] = React.useState<"pending" | "yes" | "no">("pending");
  const [knowInPerson, setKnowInPerson] = React.useState<"pending" | "yes" | "no">("pending");
  const [allowPrivate, setAllowPrivate] = React.useState<"pending" | "yes" | "no">("pending");
  React.useEffect(() => {
    if (knowInPerson !== "yes") return;
    const c = setTimeout(() => setAllowPrivate("no"), delay);
    return () => clearTimeout(c);
  }, [knowInPerson]);
  React.useEffect(() => {
    if (understand !== "yes") return;
    const c = setTimeout(() => setKnowInPerson("no"), delay);
    return () => clearTimeout(c);
  }, [understand]);
  React.useEffect(() => {
    const c = setTimeout(() => setUnderstand("no"), delay);
    return () => clearTimeout(c);
  }, []);

  return (
    <>
      <VouchStepHeading>Vouching on Candid is a serious process</VouchStepHeading>
      <div className="flex flex-col items-stretch justify-center gap-2 py-4">
        <div>
          <SpinnerCheckbox
            inputClassName="mr-2"
            checked={understand === "yes"}
            onChange={(e) => setUnderstand("yes")}
            disabled={understand !== "yes"}
            loading={understand === "pending"}
          >
            <span className={clsx(understand === "pending" && "opacity-25")}>
              Yes, I understand that if {screen_name} violates our community guidelines, my account
              may be affected.
            </span>
          </SpinnerCheckbox>
        </div>
        <div className="divider py-0 my-0" />
        <div>
          <SpinnerCheckbox
            inputClassName="mr-2"
            checked={knowInPerson === "yes"}
            onChange={(e) => setKnowInPerson("yes")}
            disabled={knowInPerson !== "yes"}
            loading={knowInPerson === "pending"}
          >
            <span className={clsx(knowInPerson === "pending" && "opacity-25")}>
              Yes, I understand that this means I know them in person
            </span>
          </SpinnerCheckbox>
        </div>
        <div className="divider py-0 my-0" />
        <div>
          <SpinnerCheckbox
            inputClassName="mr-2"
            checked={allowPrivate === "yes"}
            onChange={(e) => setAllowPrivate("yes")}
            disabled={allowPrivate !== "yes"}
            loading={allowPrivate === "pending"}
          >
            <span className={clsx(allowPrivate === "pending" && "opacity-25")}>
              Yes, I understand that this will let them see my friends list and private photos
            </span>
          </SpinnerCheckbox>
        </div>
      </div>
      {/* <button className={clsx("btn btn-primary w-full")} onClick={onNext}>
        Next
      </button> */}
      <Button
        className="w-full"
        onClick={onNext}
        disabled={understand !== "yes" || knowInPerson !== "yes" || allowPrivate !== "yes"}
      >
        Next
      </Button>
    </>
  );
}

function AnythingElse({ onNext }: VouchStepOnNextProps): React.JSX.Element {
  return (
    <>
      <VouchStepHeading>Is there anything else we should be aware of here?</VouchStepHeading>
      <NextOrReport onNext={onNext}>Sounds Good</NextOrReport>
    </>
  );
}

function FinalScreen({
  status,
  onNext,
}: {
  status: "wouldMeet" | "wouldRefer";
  onNext: () => Promise<void>;
}): React.JSX.Element {
  return (
    <>
      <VouchStepHeading>
        {match(status)
          .with(
            "wouldMeet",
            () => "Excellent. Thanks for letting us know. This feedback will help both of you out."
          )
          .with(
            "wouldRefer",
            () =>
              "Alright. Sounds like they just weren't a good fit for you. Thanks for letting us know. "
          )
          .exhaustive()}
      </VouchStepHeading>
      <SpinnerButton onClickWrapped={onNext}>Next</SpinnerButton>
    </>
  );
}
