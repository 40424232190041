import {
  GqlOps,
  useAddDiscoveryGenderPreferenceMutation,
  useUnsetDiscoveryGenderPreferenceMutation,
} from "shared/dist/__generated__/components";

import { Avatar } from "shared-web-react/dist/widgets/avatar";
import { ContentBox } from "../../../../widgets/content-box";
import { DiscoveryCommonProps } from "./dating-settings-utilities";
import { GenderPills } from "../../../../widgets/pickers/gender-picker";
import { H5 } from "shared-web-react/dist/widgets/text";
import React from "react";
import { classNames } from "shared/dist/util";

// function GenderPreferencePicker({
//   partner,
//   hasPartners,
// }: {
//   partner?: PartnerData;
//   hasPartners?: boolean;
// }): React.JSX.Element {
//   return (
//     <div className={classNames("my-2")}>
//       <GenderPills
//         showCouples
//         bottomLabel={
//           hasPartners && !partner
//             ? "Leave blank to only match with your partners"
//             : undefined
//         }
//         topLabel={
//           !(hasPartners || partner) ? undefined : partner ? (
//             <div className={classNames("-mb-2 mt-2")}>
//               For matching with{" "}
//               <Avatar slug={partner.slug} tailwindSize="4" className="mx-1" />
//               <span className={classNames("font-bold")}>{partner.slug}</span>
//             </div>
//           ) : (
//             <div className={classNames("-mb-2 mt-2")}>For me</div>
//           )
//         }
//       />
//     </div>
//   );
// }

export function GenderPreferences({
  prefs,
  hasPartners,
  partner,
  disabled,
}: {
  hasPartners?: boolean;
} & DiscoveryCommonProps): React.JSX.Element {
  const [addGenderPref] = useAddDiscoveryGenderPreferenceMutation();
  const [removeGenderPref] = useUnsetDiscoveryGenderPreferenceMutation();

  // Local state for gender preferences
  const dbGenders = prefs?.genders?.map((g) => g.gender_id) ?? [];
  const [localGenders, setLocalGenders] = React.useState(dbGenders);

  // Sync local state with backend data if they differ
  React.useEffect(() => {
    if (JSON.stringify(localGenders) !== JSON.stringify(dbGenders)) {
      setLocalGenders(dbGenders);
    }
  }, [dbGenders, localGenders]);

  const toggleGender = React.useCallback(
    async (choice: string, newState: boolean) => {
      const id = prefs?.id;
      if (!id || disabled) return;

      const variables = { id, gender_id: choice };
      const mutation = newState ? addGenderPref : removeGenderPref;

      // Optimistic update
      setLocalGenders((prev) => (newState ? [...prev, choice] : prev.filter((g) => g !== choice)));

      await mutation({
        variables,
        refetchQueries: [
          GqlOps.Query.GetAllDiscoveryPreferences300,
          GqlOps.Query.GetDiscoveryPreferences300,
        ],
      });
    },
    [prefs, addGenderPref, removeGenderPref, disabled]
  );

  return (
    <GenderPills
      showCouples
      disabled={disabled}
      defaultValue={localGenders}
      onChange={async (active, selection, newStatus) => {
        console.log("🚀 ~ onChange ~ selection:", selection, newStatus);
        toggleGender(selection.value, newStatus);
      }}
    />
  );
}
