import { BlockedUsers } from "./safety";

import { Container } from "../../../widgets/container";
import { DatingSettings } from "./dating/dating-main";
import clsx from "clsx";
import { useNavigate } from "react-router-dom";
import { allRoutes } from "../../../util/routes";
import { faAngleLeft } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Text } from "../../../components/atoms/Text";

export function DatingSettingsPage(): React.JSX.Element {
  const navigate = useNavigate();

  return (
    <Container size="sm" className={clsx("p-2")}>
      <div className="w-full flex -ml-3 mb-5 items-center text-white" onClick={() => navigate(-1)}>
        <FontAwesomeIcon icon={faAngleLeft} fixedWidth size="2x" color="white" />
        <Text as="h3" size="xs" weight="semibold" className="pt-0">
          Dating Preferences
        </Text>
      </div>
      <DatingSettings />
      <div className="divider" />
    </Container>
  );
}
